import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import moment from "moment";

const UserDataPDF = (props: any) => {
  const { user, pan, address, bank, demat, itr } = props;

  const userData = useMemo(
    () => [
      {
        label: "Executed Document Mail Delivery Time",
        value: moment().format("DD MMM, YYYY (HH:mm:ss)"),
      },
      { label: "SMEST ID", value: user.smestID },
      { label: "UUID", value: user.uuid },
      { label: "PAN Number", value: pan?.pan_status_response?.pan_number },
      {
        label: "KYC Holder Name",
        value: pan?.pan_status_response?.kyc_holder_name,
      },
      {
        label: "KYC Status",
        value: pan?.pan_status_response?.kyc_status_in_codes
          ?.map((item: string) => item)
          ?.join(", "),
      },
      {
        label: "KYC Creation Date",
        value: pan?.pan_status_response?.kyc_creation_date,
      },
      {
        label: "CVL KRA Response Record Time",
        value: pan?.pan_status_response?.response_date_time,
      },
      { label: "PAN Compliant", value: itr?.data?.compliant ? "Yes" : "No" },
      {
        label: "PAN Allotment Date",
        value: itr?.data?.pan_allotment_date
          ? moment(itr?.data?.pan_allotment_date, "YYYY-MM-DD").format(
              "DD MMM, YYYY"
            )
          : "",
      },
      { label: "Pan Status", value: itr?.data?.pan_status },
      { label: "Valid PAN", value: itr?.data?.valid_pan ? "Yes" : "No" },
      { label: "Aadhar Linked to Pan", value: itr?.data?.pan_aadhaar_linked },
      {
        label: "Bank Account Name",
        value: bank?.find((item: any) => item?.is_default)?.beneficiary_name,
      },
      {
        label: "Bank Account Number",
        value: bank?.find((item: any) => item?.is_default)?.account_number,
      },
      { label: "Penny Drop Verification", value: "Verified" },
    ],
    [user.uuid]
  );

  return (
    <>
      <Box component="table" id="kyc-verification-heading">
        <thead>
          <tr>
            <th>
              KYC Verification for {user.firstName} {user.lastName}
            </th>
          </tr>
        </thead>
      </Box>
      <Box component="table" id="kyc-verification-info">
        <tbody>
          {userData.slice(0, 3).map((user, index) => (
            <tr key={index}>
              <td>{user.label}</td>
              <td>{user.value}</td>
            </tr>
          ))}
        </tbody>
      </Box>
      <Box component="table" id="cvl-kra-heading">
        <thead>
          <tr>
            <th>CVL KRA INFORMATION</th>
          </tr>
        </thead>
      </Box>
      <Box component="table" id="cvl-kra-info">
        <tbody>
          {userData.slice(3, 8).map((user, index) => (
            <tr key={index}>
              <td>{user.label}</td>
              <td>{user.value}</td>
            </tr>
          ))}
        </tbody>
      </Box>
      <Box component="table" id="income-tax-database-verification-heading">
        <thead>
          <tr>
            <th>Income Tax Database Verification</th>
          </tr>
        </thead>
      </Box>
      <Box component="table" id="income-tax-database-verification-info">
        <tbody>
          {userData.slice(8, 13).map((user, index) => (
            <tr key={index}>
              <td>{user.label}</td>
              <td>{user.value}</td>
            </tr>
          ))}
        </tbody>
      </Box>
      <Box component="table" id="bank-penny-drop-verification-heading">
        <thead>
          <tr>
            <th>Bank Penny Drop Verification</th>
          </tr>
        </thead>
      </Box>
      <Box component="table" id="bank-penny-drop-verification-info">
        <tbody>
          {userData.slice(13).map((user, index) => (
            <tr key={index}>
              <td>{user.label}</td>
              <td>{user.value}</td>
            </tr>
          ))}
        </tbody>
      </Box>
    </>
  );
};

export default UserDataPDF;
