import React, { HTMLAttributes, forwardRef } from "react";
import { alpha } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const Listbox = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;

    return (
      <Grid container ref={ref}>
        <Grid
          {...other}
          item
          xs={11.9}
          sx={{
            overflowY: "overlay",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              marginTop: "20px",
              marginBottom: "50px",
              bgcolor: `${alpha("#EC4F56", 0.1)}`,
              borderRadius: 50,
              border: `0.8px solid #FFFFFF`,
              boxShadow: `inset 0 0 6px ${alpha("#EC4F56", 0.1)}`,
            },
            "&::-webkit-scrollbar-thumb": {
              bgcolor: "#EC4F56",
              borderRadius: 50,
            },
          }}
        >
          {children}
        </Grid>
        <Grid item xs={0.1}></Grid>
      </Grid>
    );
  }
);

export default Listbox;
