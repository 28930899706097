import React from "react";
import toastr from "toastr";
import {
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import ImageUpload from "../ImageUpload";
import GenericDialog from "../Dialog/GenericDialog";
import { makeDELETERequestOnUserServiceWithQueryParameter } from "../../Api/api";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  paper: {
    width: "500px",
    margin: "103px auto",
    justifyContent: "center",
    alignItems: "center",
  },
  aadharPaper: {
    width: "500px",
    margin: "3px auto 100px", // Adjust the margin value as needed
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    height: 355,
    maxWidth: 600,
    overflow: "hidden",
    display: "block",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];

const ProofOfAddressInformationGrid = (props) => {
  const classes = useStyles();

  function getTypeValue(val) {
    if (val) {
      if (val === "passport") return "Passport";
      else if (val === "aadhar") return "Aadhar";
      else if (val === "driver_license") return "Driver License";
    } else return "Aadhar";
  }

  function getCreateCondValue(props) {
    if (props.aadharCardDetails) {
      const keys = Object.keys(props.aadharCardDetails);

      if (props.aadharCardDetails) {
        return (
          (Object.keys(props.aadharCardDetails).length === 0 &&
            props.aadharCardDetails.constructor === Object) ||
          (keys.length === 1 && keys[0] === "failed_count")
        );
      }

      return true;
    } else {
      return true;
    }
  }

  const initialState = {
    uuid: props?.aadharCardDetails?.uuid || "",
    address: props?.aadharCardDetails?.address || "",
    expiryDate: props?.aadharCardDetails?.expiry_date || new Date(),
    state: props?.aadharCardDetails?.state || "",
    districtOrCity: props?.aadharCardDetails?.district_or_city || "",
    pincode: props?.aadharCardDetails?.pincode || "",
    country: props?.userDetails?.birthCountry || "",
    password: props?.aadharCardDetails?.is_password_protected || "",
  };
  const typeValue = getTypeValue(props?.aadharCardDetails?.type);
  const [currentState, setCurrentState] = React.useState(initialState);
  const [docType, setDocType] = React.useState(typeValue);
  const proofOfAddressOptions = ["Aadhar", "Passport", "Driver License"];
  const [createCond, setCreateCond] = React.useState(getCreateCondValue(props));
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const saveAadharCardDetails = (event) => {
    event.preventDefault();
    props.saveAadharCardDetails(event, currentState, docType);
  };
  const createProofOfAddress = (event) => {
    event.preventDefault();
    currentState.selectedFile = selectedFile;
    props.createProofOfAddress(event, currentState, docType);
  };

  const handleDocTypeChange = (event) => {
    event.preventDefault();
    setDocType(event.target.value);
  };

  const setFile = (event) => {
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
  };

  function getValidURLBasedOnType() {
    switch (props.aadharCardDetails.type) {
      case "aadhar": {
        return "aadhar_card";
      }
      case "passport": {
        return "passport";
      }

      case "driver_license":
        return "driver_license";
    }
  }

  function deleteProofOfAddress(event) {
    event.preventDefault();
    makeDELETERequestOnUserServiceWithQueryParameter(
      `/kyc/${getValidURLBasedOnType()}/delete/${props.currentUser.uuid}/`
    )
      .then((response) => {
        if (response?.status === 204) {
          toastr.success("Deleted Proof of Address successfully!");
          setCurrentState({
            uuid: "",
            address: "",
          });
          setShowDeleteModal(!showDeleteModal);
          setCreateCond(true);
          setSelectedFile(null);
          props.nullifyProofOfAddress(event);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  function downloadFile(e, b64) {
    e.preventDefault();
    // data should be your response data in base64 format
    const blob = dataURItoBlob(b64);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }

  function viewBackSide(event) {
    event.preventDefault();
    let base64 = props.aadharCardDetails.back_image;
    if (props.aadharCardDetails.back_part_image_extension === "pdf") {
      const blob = dataURItoBlob(base64);
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } else {
      var image = new Image();
      image.src = "data:image/jpg;base64," + base64;
      var w = window.open("");
      w.document.write(image.outerHTML);
    }
  }

  function renderImageOrPDFButton() {
    if (props.aadharCardDetails?.extension === "pdf") {
      return (
        <Button
          className="btnClass"
          variant="outlined"
          onClick={(event) => {
            downloadFile(event, props?.aadharCardDetails?.image);
          }}
          color="primary"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          View PDF
        </Button>
      );
    } else {
      const imageUrl = `data:image/png;base64,${props?.aadharCardDetails?.front_image}`;

      return (
        <>
          <div style={{ position: "relative", textAlign: "center" }}>
            <img className={classes.img} src={imageUrl} alt={imageUrl} />
            {props?.aadharCardDetails?.backSideImage !== "" && (
              <Button
                onClick={(e) => viewBackSide(e)}
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                View Back Side
              </Button>
            )}
          </div>
        </>
      );
    }
  }

  return (
    <div
      style={{
        position: "absolute",
        left: "70%",
        top: "55%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Box
        sx={{ width: 410 }}
        style={{
          position: "absolute",
          left: "-43%",
          top: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {createCond ? (
          <>
            <Skeleton variant="rectangular" width={410} height={418} />
            <ImageUpload setFile={setFile} />
          </>
        ) : (
          renderImageOrPDFButton()
        )}
      </Box>
      <Paper
        className={classes.paper}
        style={{ padding: "2%", "margin-top": "120%" }}
      >
        <div style={{ fontSize: "36px", float: "left" }}>Proof of Address</div>

        {createCond ? (
          <Grid item xs={10} style={{ "margin-top": "10px" }}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "100%" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Doc Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="docType"
                value={docType}
                onChange={handleDocTypeChange}
                label="Document Type"
              >
                {proofOfAddressOptions &&
                  proofOfAddressOptions.length > 0 &&
                  proofOfAddressOptions.map((proofOfAddressOption, index) => {
                    return (
                      <MenuItem value={proofOfAddressOption} key={index}>
                        {proofOfAddressOption}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={10} style={{ "margin-top": "10px" }}>
          <TextField
            type="text"
            name="uuid"
            variant="outlined"
            fullWidth
            helperText="ID Proof Number"
            onChange={handleChange}
            value={currentState.uuid}
          />
        </Grid>

        {["Passport", "Driver License"].includes(docType) && (
          <Grid item xs={10} style={{ marginTop: "5px" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item justify="space-around" xs={8}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Expiry Date"
                  format="dd/MM/yyyy"
                  value={currentState.expiryDate}
                  onChange={(date) => {
                    setCurrentState((prev) => ({
                      ...prev,
                      expiryDate: date,
                    }));
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        )}

        {(docType.toLocaleLowerCase() === "aadhar" ||
          docType.toLocaleLowerCase() === "driver license" ||
          docType.toLocaleLowerCase() === "passport") && (
          <>
            <Grid item xs={10} style={{ "margin-top": "10px" }}>
              <TextField
                type="text"
                name="districtOrCity"
                variant="outlined"
                fullWidth
                helperText="City"
                onChange={handleChange}
                value={currentState.districtOrCity}
              />
            </Grid>

            <Grid item xs={10} style={{ "margin-top": "10px" }}>
              <TextField
                type="text"
                name="pincode"
                variant="outlined"
                fullWidth
                helperText="Pincode"
                onChange={handleChange}
                value={currentState.pincode}
              />
            </Grid>

            <Grid item xs={10} style={{ "margin-top": "10px" }}>
              <TextField
                type="text"
                name="country"
                variant="outlined"
                fullWidth
                helperText="Country"
                onChange={handleChange}
                value={currentState.country}
              />
            </Grid>
          </>
        )}

        <Grid item xs={10} style={{ marginTop: "10px" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>State</InputLabel>
            <Select
              name="state"
              value={currentState.state}
              defaultValue={currentState.state}
              onChange={handleChange}
              label="State"
            >
              {indianStates.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={10} style={{ marginTop: "20px" }}>
          <TextField
            type="text"
            name="address"
            variant="outlined"
            fullWidth
            multiline
            helperText="Address"
            onChange={handleChange}
            value={currentState.address}
          />
        </Grid>

        <Grid item xs={10} style={{ marginTop: "20px" }}>
          <TextField
            type="text"
            name="password"
            variant="outlined"
            fullWidth
            helperText="Password - If Blank, then no password is set by client"
            onChange={handleChange}
            value={currentState.password}
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={7} style={{ marginTop: "20px", marginBottom: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "100%" }}
              onClick={
                createCond ? createProofOfAddress : saveAadharCardDetails
              }
            >
              {createCond ? "Create" : "Update"}
            </Button>
          </Grid>
          {!createCond ? (
            <Grid item xs={5}>
              <Button
                className="btnClass"
                variant="outlined"
                onClick={(event) => {
                  event.preventDefault();
                  setShowDeleteModal(!showDeleteModal);
                }}
                color="primary"
                style={{
                  float: "right",
                  margin: "25px 10px 40px",
                  cursor: "pointer",
                }}
              >
                Delete
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {showDeleteModal ? (
          <GenericDialog
            show={showDeleteModal}
            heading="Delete Proof Of Address"
            inputProps={
              "Are you sure you want to delete this proof of address ?"
            }
            onClose={(event) => {
              event.preventDefault();
              setShowDeleteModal(!showDeleteModal);
            }}
            handleSubmit={(event) => {
              event.preventDefault();
              deleteProofOfAddress(event);
            }}
          />
        ) : (
          ""
        )}
      </Paper>
    </div>
  );
};

export default ProofOfAddressInformationGrid;
