import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import { OrderDetailsInterface } from "./interfaces";
import { StyledChip } from "./NewRFQOrderDetails";
import FormHelperText from "@mui/material/FormHelperText";

const SettlementInformation = ({
  orderDetails,
}: {
  orderDetails: OrderDetailsInterface;
}) => {
  const [expandSettlementInfo, setExpandSettlementInfo] = useState(true);
  const settlementInformation = useMemo(
    () => [
      {
        label: "ISIN",
        value: orderDetails?.isin,
        helperText: orderDetails?.security_name,
      },
      {
        label: "Number of Bonds",
        value: orderDetails?.no_of_bonds,
      },
      {
        label: "Value",
        value: orderDetails?.value,
      },
      {
        label: "Payment Process",
        value: orderDetails?.payment_process,
      },
      {
        label: "Settlement Type",
        value: orderDetails?.settlement_type,
      },
      {
        label: "Settlement Date",
        value: orderDetails?.settlement_date,
      },
      {
        label: "Our Buy/ Our Sell",
        value: orderDetails?.quotetype === "OFFER" ? "Sell" : "Buy",
        helperText: orderDetails?.quotetype,
      },
      {
        label: "Settled Webhook Time (Time Settled webhook is hit from BSE)",
        value: "",
      },
      {
        label: "Yield Type",
        value: orderDetails?.type_of_yield,
        helperText: orderDetails?.quotetype,
      },
      {
        label: "Yield",
        value: `${orderDetails?.sell_yield}%`,
        helperText: orderDetails?.quotetype,
      },
      {
        label: "Price",
        value: orderDetails.sell_price,
        helperText: orderDetails?.quotetype,
      },
      {
        label: "Call Date",
        value: orderDetails.last_call_date,
      },
      {
        label: "Maturity Date",
        value: orderDetails.last_maturity_date,
      },
      {
        label: "Principal Amount",
        value: orderDetails?.principal_amount,
      },
      {
        label: "Modified Accrued Interest",
        value: orderDetails?.modified_accrued_interest,
        helperText: `Accured Interest Calculation as on: ${orderDetails?.settlement_date}`,
      },
      {
        label: "Stamp Duty",
        value: orderDetails?.stamp_duty,
      },
      {
        label: "Total Consideration without Stamp Duty",
        value: orderDetails?.total_consideration,
        helperText: `Total Consideration with Stamp Duty - ${
          orderDetails?.total_consideration! + orderDetails?.stamp_duty!
        }`,
      },
    ],
    [orderDetails]
  );

  return (
    <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5, mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Settlement Information</Typography>
        <StyledChip
          label={expandSettlementInfo ? "Collapse" : "Expand"}
          onClick={() => {
            setExpandSettlementInfo(!expandSettlementInfo);
          }}
          expandOrCollapse={expandSettlementInfo}
        />
      </Box>
      <Box
        component="hr"
        sx={{ borderColor: "#7659EE", opacity: 0.5, mb: 4, mt: 2 }}
      />
      <Collapse in={expandSettlementInfo}>
        <Grid container spacing={2}>
          {settlementInformation.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <StyledTextInput
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  disabled
                  value={item.value}
                />
                {item.helperText && (
                  <FormHelperText
                    sx={{
                      color: "#45E142",
                      fontSize: 12,
                      ml: 1,
                    }}
                  >
                    {item.helperText}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default SettlementInformation;
