import React from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import { Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  makeDELETERequest,
  makeGETRequest,
  makePOSTRequest,
  makePUTRequest,
} from "../Api/api";
import Box from "@mui/material/Box";
import { StyledTextInput } from "./StyledComponents/StyledInputs";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

const StyledButton = styled(Button)({
  borderRadius: 10,
  flex: 1,
});

function getVideoId(url) {
  let regex =
    /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
  return regex.exec(url)[3];
}

const AddIssuerMasterYoutubeLinks = (props) => {
  const [videos, setVideos] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [payload, setPayload] = React.useState({
    link: "",
    title: "",
    description: "",
  });
  const [updateID, setUpdateID] = React.useState("");

  React.useEffect(() => {
    props.openModal && setLoading(true);
  }, [props.openModal]);

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequest(`/admin/videolinkall/${props.issuerId}/`)
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (active) {
            setVideos(data);
          }
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  const handleChange = (event) => {
    event.preventDefault();
    setPayload((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAdd = (uuid) => {
    makePOSTRequest(`/admin/videolinkupload/`, {
      youtube_link: payload.link,
      news_header: payload.title,
      data: payload.description,
      issuer_master_uuid: uuid,
    })
      .then((response) => {
        if (response && response.status === 201) {
          toastr.success(
            "Link Added Successfully. Pls refresh bond-details page and check."
          );
          setLoading(true);
          setPayload({ link: "", title: "", description: "" });
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleUpdate = () => {
    makePUTRequest("/admin/videolinkupdate/", {
      uuid: updateID,
      youtube_link: payload.link,
      news_header: payload.title,
      data: payload.description,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success(
            "Link Updated Successfully. Pls refresh bond-details page and check."
          );
          setLoading(true);
          setPayload({ link: "", title: "", description: "" });
          setUpdateID("");
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleDelete = (uuid) => {
    makeDELETERequest(`/admin/videolink/delete/${uuid}/`)
      .then((response) => {
        if (response && response.status === 204) {
          toastr.success(
            "Link Deleted Successfully. Pls refresh bond-details page and check."
          );
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <div>
      <Dialog
        open={props.openModal}
        onClose={props.onCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Youtube Links</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            {loading ? (
              <CircularProgress color="error" />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box sx={{ border: "2px solid grey", borderRadius: 3, p: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel shrink htmlFor="youtube-link">
                          Link
                        </InputLabel>
                        <StyledTextInput
                          value={payload.link}
                          onChange={handleChange}
                          name="link"
                          id="youtube-link"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel shrink htmlFor="title">
                          Title
                        </InputLabel>
                        <StyledTextInput
                          value={payload.title}
                          onChange={handleChange}
                          name="title"
                          id="title"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel shrink htmlFor="description">
                          Description
                        </InputLabel>
                        <StyledTextInput
                          value={payload.description}
                          onChange={handleChange}
                          name="description"
                          id="description"
                          multiline
                          minRows={4}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {updateID ? (
                        <StyledButton
                          variant="outlined"
                          startIcon={<AppRegistrationIcon />}
                          color="warning"
                          onClick={handleUpdate}
                        >
                          Update
                        </StyledButton>
                      ) : (
                        <StyledButton
                          variant="outlined"
                          startIcon={<AddAPhotoIcon />}
                          color="info"
                          onClick={() => handleAdd(props.issuerId)}
                        >
                          Add
                        </StyledButton>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                {videos.length ? (
                  videos.map((item, index) => {
                    let videoId = getVideoId(item.youtube_link);
                    return (
                      <Box
                        key={index}
                        sx={{
                          bgcolor: "#ffffff63",
                          boxShadow: "rgba(149, 157, 165, 0.4) 0px 8px 24px",
                          p: 2,
                          borderRadius: 5,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <StyledButton
                              sx={{
                                bgcolor: "#77a3ef18",
                                color: "#77a3efff",
                                fontSize: 10,
                                textTransform: "lowercase",
                                display: "flex",
                                alignItems: "flex-start",
                                textAlign: "left",
                              }}
                              target="_blank"
                              href={item.youtube_link}
                              startIcon={<LinkIcon />}
                            >
                              {item.youtube_link}
                            </StyledButton>
                            <Box sx={{ display: "flex", gap: 2, my: 2 }}>
                              <StyledButton
                                variant="outlined"
                                startIcon={<AppRegistrationIcon />}
                                color="warning"
                                onClick={() => {
                                  setPayload({
                                    link: item.youtube_link,
                                    title: item.news_header,
                                    description: item.data,
                                  });
                                  setUpdateID(item.uuid);
                                }}
                              >
                                Update
                              </StyledButton>
                              <StyledButton
                                variant="outlined"
                                startIcon={<DeleteIcon />}
                                color="error"
                                onClick={() => handleDelete(item.uuid)}
                              >
                                Delete
                              </StyledButton>
                            </Box>
                            <Typography
                              sx={{
                                bgcolor: "#45e1421c",
                                border: "2px solid #45E142",
                                p: 1,
                                color: "#45E142",
                                borderRadius: 2,
                                fontSize: 12,
                                width: "max-content",
                              }}
                            >
                              {item.news_header}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Box
                              component="img"
                              src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                              width="100%"
                              height="100%"
                              sx={{ objectFit: "contain" }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                bgcolor: "#7759ee24",
                                border: "2px solid #7659EE",
                                p: 1,
                                color: "#7659EE",
                                borderRadius: 2,
                                fontSize: 13,
                              }}
                            >
                              {item.data}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      bgcolor: "#7759ee24",
                      border: "2px solid #7659EE",
                      p: 1,
                      color: "#7659EE",
                      borderRadius: 2,
                      fontSize: 13,
                    }}
                  >
                    No Videos Added Yet. Please Add Videos.
                  </Typography>
                )}
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withRouter(AddIssuerMasterYoutubeLinks);
