import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "200%",
      margin: "30px",
      height: "100%",
      padding: theme.spacing(2),
      border: "2px solid black",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    uploadContainer: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    form: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    inputField: {
      width: "70%",
    },
    radioGroup: {
      flexDirection: "row",
    },
    radioLabel: {
      marginBottom: theme.spacing(1),
    },
    imagePreview: {
      width: "100px",
      height: "100px",
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: "auto",
      width: "30%",
    },
  })
);

const incomeRanges = [
  "Less than 1 lac",
  "1 lac to 5 lacs",
  "5 lacs to 10 lacs",
  "10 lacs to 25 lacs",
  "More than 25 lacs",
];

const PersonalDetails: React.FC = (props: any) => {
  const classes = useStyles();
  const { formData, setFormData } = props;

  const handleInputChange =
    (key: keyof typeof formData) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormData({ ...formData, [key]: event.target.value });
    };

  const handleRadioChange =
    (key: keyof typeof formData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [key]: event.target.value });
    };

  const handleImageUpload =
    (key: keyof typeof formData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        setFormData({ ...formData, [key]: event.target.files[0] });
      }
    };

  const handleSave = (event: any) => {
    props.addPersonalDetails(event);
  };

  return (
    <Paper className={classes.paper}>
      <form className={classes.form}>
        <TextField
          className={classes.inputField}
          label="Marital Status"
          select
          value={formData.maritalStatus}
          onChange={handleInputChange("maritalStatus")}
        >
          <MenuItem value="Single">Single</MenuItem>
          <MenuItem value="Married">Married</MenuItem>
        </TextField>
        <div className={classes.radioLabel}>Are you Politically Exposed?</div>
        <RadioGroup
          aria-label="Politically Exposed"
          name="politicallyExposed"
          value={formData.politicallyExposed ? "Yes" : "No"}
          onChange={handleRadioChange("politicallyExposed")}
          className={classes.radioGroup}
        >
          <FormControlLabel
            value="Yes"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            value="No"
            control={<Radio color="primary" />}
            label="No"
          />
        </RadioGroup>
        <TextField
          className={classes.inputField}
          label="Maiden Name"
          value={formData.maidenName}
          onChange={handleInputChange("maidenName")}
        />
        <TextField
          className={classes.inputField}
          label="Mother's Name"
          value={formData.mothersName}
          onChange={handleInputChange("mothersName")}
        />
        <TextField
          className={classes.inputField}
          label="Annual Income"
          select
          value={formData.annualIncome}
          onChange={handleInputChange("annualIncome")}
        >
          {incomeRanges.map((item, index) => (
            <MenuItem value={item} key={index}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.inputField}
          label="Gender"
          select
          value={formData.gender}
          onChange={handleInputChange("gender")}
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
        </TextField>
        <TextField
          className={classes.inputField}
          label="Occupation"
          value={formData.occupation}
          onChange={handleInputChange("occupation")}
        />
        <div className={classes.uploadContainer}>
          <label htmlFor="wetSign">Wet Signature</label>
          <input
            name="wetSign"
            type="file"
            accept="image/*"
            onChange={handleImageUpload("wetSignImage")}
          />
          {formData.wetSignImage && (
            <img
              className={classes.imagePreview}
              src={
                formData.wetSignImage instanceof File
                  ? URL.createObjectURL(formData.wetSignImage)
                  : `data:image/png;base64,${formData.wetSignImage}`
              }
              alt="Wet Sign Preview"
            />
          )}
        </div>
        <div className={classes.uploadContainer}>
          <label htmlFor="passportPhoto">Passport Photo</label>
          <input
            name="passportPhoto"
            type="file"
            accept="image/*"
            onChange={handleImageUpload("passportPhoto")}
          />
          {formData.passportPhoto && (
            <img
              className={classes.imagePreview}
              src={
                formData.passportPhoto instanceof File
                  ? URL.createObjectURL(formData.passportPhoto)
                  : `data:image/png;base64,${formData.passportPhoto}`
              }
              alt="Passport Photo Preview"
            />
          )}
        </div>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </form>
    </Paper>
  );
};

export default PersonalDetails;
