import React, { useEffect } from "react";
import { IconButton, Button, TextField } from "@material-ui/core";
import {
  makeDELETERequest,
  makeGETRequest,
  makePOSTRequest,
  makePUTRequest,
} from "../../Api/api";
import GenericDialog from "../../components/Dialog/GenericDialog";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import EditIcon from "@material-ui/icons/Edit";
import { StyledTextInput } from "../../components/StyledComponents/StyledInputs";
import moment from "moment";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import Collapse from "@mui/material/Collapse";
import Chip from "@mui/material/Chip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { alpha } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {
  IPOPlaceOrder,
  ModifyInternallyState,
  OrderDetailsInterface,
} from "./Interfaces";
import Typography from "@mui/material/Typography";

const toastr = require("toastr");

export default function IPOOrderDetailsDialogBody({ uuid }: { uuid: string }) {
  const [showCancelIPOOrderDialog, setShowCancelIPOOrderDialog] =
    React.useState<boolean>(false);
  const [showModifyIPOOrderDialog, setShowModifyIPOOrderDialog] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  const [orderDetails, setOrderDetails] =
    React.useState<OrderDetailsInterface | null>(null);
  const [expanded, setExpanded] = React.useState(false);
  const initialBidValues = React.useMemo(
    () => ({
      bidid: orderDetails?.bids[0].bidid,
      quantity: orderDetails?.bids[0].quantity,
      rate: orderDetails?.bids[0].rate,
      cuttoffflag: orderDetails?.bids[0].cuttoffflag,
      series: orderDetails?.bids[0].series,
      orderno: orderDetails?.bids[0].orderno,
    }),
    [orderDetails]
  );
  const initialOrderValues = React.useMemo(
    () => ({
      dpid: orderDetails?.dpid,
      clientbenfid: orderDetails?.clientbenfid,
      panno: orderDetails?.panno,
    }),
    [orderDetails]
  );
  const [modifyOrderFields, setModifyOrderFields] =
    React.useState(initialOrderValues);
  const [disabledFields, setDisabledFields] = React.useState({
    dpid: true,
    clientbenfid: true,
    panno: false,
  });
  const [renderFinalUnitsInput, setRenderFinalUnitsInput] =
    React.useState<boolean>(false);
  const [finalInput, setFinalInput] = React.useState<string>("");
  const [bid, setBid] = React.useState(initialBidValues);
  const ALLOTMENT_STATUS_OPTIONS = [
    "Pending Allotment",
    "Successful Allotment",
    "Partial Allotment",
    "Unsuccessful Allotment",
  ];
  const [selectedAllotmentStatus, setSelectedAllotmentStatus] =
    React.useState("");
  const [
    showAllotmentStatusConfirmationDialog,
    setShowAllotmentStatusConfirmationDialog,
  ] = React.useState<boolean>(false);
  const [showUPIEnquiryDialog, setShowUPIEnquiryDialog] =
    React.useState<boolean>(false);
  const [showModifyInternallyDialog, setShowModifyInternallyDialog] =
    React.useState<boolean>(false);
  const initialModifyInternallyState = React.useMemo(
    () => ({
      uuid: orderDetails?.uuid,
      ipo_quote_tenure: orderDetails?.ipo_quote_tenure,
      totalqty: orderDetails?.totalqty,
      chequeamount: orderDetails?.chequeamount,
      category: orderDetails?.category,
      applicantname: orderDetails?.applicantname,
      panno: orderDetails?.panno,
      depository: orderDetails?.depository,
      dpid: orderDetails?.dpid,
      clientbenfid: orderDetails?.clientbenfid,
      accountnumber_upiid: orderDetails?.accountnumber_upiid,
      series: orderDetails?.bids[0].series,
      scripid: orderDetails?.scripid,
    }),
    [orderDetails]
  );
  const [modifyInternallystate, setModifyInternallyState] = React.useState(
    initialModifyInternallyState
  );

  function cancelIPOOrder(event: any) {
    event.preventDefault();
    let requestPayload = getRequestPayload("D");
    makePOSTRequest("/ipo/dbtipoorder_ipo/", requestPayload)
      .then((resp) => {
        if (resp.data.statuscode !== "0") {
          const errorMessage =
            resp.data.statusmessage ||
            (resp.data.bids.length > 0
              ? resp.data.bids[0].message
              : "Unknown error");
          toastr.error(errorMessage);
        } else {
          toastr.success("Cancelled IPO order successfully!");
          setShowCancelIPOOrderDialog(false);
          setLoading(true);
        }
        if (resp.data.bids[0].message) {
          toastr.info(resp.data.bids[0].message);
        }
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage =
            error.response.data.statusmessage || "Server error occurred.";
          toastr.error(errorMessage);
          if (error.response.data.bids[0].message) {
            toastr.info(error.response.data.bids[0].message);
          }
        } else {
          toastr.error(error);
        }
      });
  }

  function handleOrderChange(event: any) {
    event.persist();
    setModifyOrderFields((previousValues: any) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  function handleChange(event: any) {
    event.persist();
    setBid((previousValues: any) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  function getRequestPayload(actionCode: string) {
    return {
      scripid: orderDetails?.scripid,
      applicationno: orderDetails?.applicationno,
      category: orderDetails?.category,
      applicantname: orderDetails?.applicantname,
      depository: orderDetails?.depository,
      dpid: modifyOrderFields?.dpid ?? orderDetails?.dpid,
      clientbenfid:
        modifyOrderFields?.clientbenfid ?? orderDetails?.clientbenfid,
      chequereceivedflag: orderDetails?.chequereceivedflag,
      chequeamount: orderDetails?.chequeamount,
      totalqty: orderDetails?.bids[0].quantity,
      panno: modifyOrderFields?.panno ?? orderDetails?.panno,
      bankname: orderDetails?.bankname,
      bankreferencechequeno: "0",
      location: orderDetails?.location,
      accountnumber_upiid: orderDetails?.accountnumber_upiid,
      ifsccode: orderDetails?.ifsccode,
      bidtype: orderDetails?.bidtype,
      paytype: "1",
      actioncode: actionCode,
      bids: [
        {
          bidid: bid.bidid ?? orderDetails?.bids[0].bidid,
          quantity: bid.quantity ?? orderDetails?.bids[0].quantity,
          rate: bid.rate ?? orderDetails?.bids[0].rate,
          cuttoffflag: bid.cuttoffflag ?? orderDetails?.bids[0].cuttoffflag,
          series: bid.series ?? orderDetails?.bids[0].series,
          orderno: bid.orderno ?? orderDetails?.bids[0].orderno,
        },
      ],
      user_id: orderDetails?.user_id,
      smest_Id: orderDetails?.smest_Id,
      mobile: orderDetails?.mobile,
      email: orderDetails?.email,
      uuid: orderDetails?.uuid,
    };
  }

  function changeAllotmentStatus(event: any) {
    event.preventDefault();
    makePUTRequest("/ipo/order/allotment_status/update/", {
      allotment_status: selectedAllotmentStatus,
      application_no: orderDetails?.applicationno,
      issuerName: orderDetails?.issuer_of_security.issuerName.name,
      category: orderDetails?.category,
      totalinvestmentamount: orderDetails?.chequeamount,
      customername: orderDetails?.applicantname,
      email: orderDetails?.email,
      final_units: finalInput || orderDetails?.final_units,
    })
      .then((resp: any) => {
        if (resp.status === 200) {
          toastr.success("Updated IPO Order's allotment status successfully!");
        } else {
          toastr.error("Something went wrong. Please try again later.");
        }
        setShowAllotmentStatusConfirmationDialog(false);
        setRenderFinalUnitsInput(false);
        setFinalInput("");
      })
      .catch((error: any) => {
        console.log(error);
        toastr.error(error);
      });
  }

  function modifyIPOOrder(event: any) {
    event.preventDefault();
    let requestPayload = getRequestPayload("M");

    makePOSTRequest("/ipo/dbtipoorder_ipo/", requestPayload)
      .then((resp: any) => {
        if (resp.data.statuscode !== "0") {
          toastr.error(resp.data.statusmessage);
        } else {
          toastr.success("Modified IPO order successfully!");
          setShowModifyIPOOrderDialog(false);
          setLoading(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
        toastr.error(error);
      });
  }

  const modifyIPOOrderInternally = () => {
    makePUTRequest(`/ipo/ipo_order/update/`, {
      uuid: modifyInternallystate.uuid,
      ipo_quote_tenure: modifyInternallystate.ipo_quote_tenure,
      totalqty: modifyInternallystate.totalqty,
      chequeamount: modifyInternallystate.chequeamount,
      category: modifyInternallystate.category,
      applicantname: modifyInternallystate.applicantname,
      panno: modifyInternallystate.panno,
      depository: modifyInternallystate.depository,
      dpid: modifyInternallystate.dpid,
      clientbenfid: modifyInternallystate.clientbenfid,
      accountnumber_upiid: modifyInternallystate.accountnumber_upiid,
      bids: [
        {
          ...orderDetails?.bids[0],
          series: modifyInternallystate.series,
          quantity: modifyInternallystate.totalqty,
        },
      ],
      scripid: modifyInternallystate.scripid,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("IPO Modified Internally");
          setLoading(true);
          setShowModifyInternallyDialog(false);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const getPaymentStatus = (code: number) => {
    switch (code) {
      case 1:
        return "Bank Response Pending";
      case 10:
        return "Request accepted by Bank";
      case 11:
        return "Request rejected by Bank due to invalid UPI handle";
      case 12:
        return "Request rejected by Bank due to other reasons";
      case 21:
        return "Request rejected by Client Bank";
      case 31:
        return "Request rejected by Client";
      case 100:
        return "Request accepted by Client. Payment successful.";
      case 110:
        return "Request processed successfully";
      default:
        return "Unknown Request";
    }
  };

  const enquiryInfo = React.useMemo(
    () => [
      {
        label: "DP Status",
        value: orderDetails?.dpflag,
      },
      {
        label: "DP Remark",
        value: orderDetails?.dpremark,
      },
      {
        label: "Sponsor Bank pay status",
        value: getPaymentStatus(Number(orderDetails?.sponsorbankpaystatus)),
      },
      {
        label: "Sponsor Bank Action Time",
        value: orderDetails?.sponsorbankactiontime,
      },
      {
        label: "Sponsor Bank Amount Blocked",
        value: orderDetails?.sponsorbankamtblocked,
      },
      {
        label: "Payment Reject Reason",
        value: orderDetails?.rejectreason,
      },
      {
        label: "API Error CODE",
        value: orderDetails?.errorcode === "0" ? "Successful" : "Failure",
      },
    ],
    [orderDetails]
  );

  function renderDialogBodyForDPUPIEnquiry() {
    return (
      <Grid container spacing={1}>
        {enquiryInfo.map((item, index) => (
          <Grid key={index} item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel
                shrink
                htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
              >
                {item.label}
              </InputLabel>
              <StyledTextInput
                id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                disabled
                value={item.value}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
    );
  }

  function renderModifyInternallyFields() {
    return (
      <Grid container spacing={1}>
        {Object.keys(initialModifyInternallyState).map((item, index) => (
          <Grid item xs={6} key={index}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink htmlFor={item}>
                {item
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </InputLabel>
              <StyledTextInput
                id={`${item}_${index}`}
                name={item}
                value={
                  modifyInternallystate[item as keyof ModifyInternallyState]
                }
                onChange={(event) => {
                  const value = event.target.value;
                  setModifyInternallyState((prev) => ({
                    ...prev,
                    [item as keyof ModifyInternallyState]: value,
                  }));
                }}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
    );
  }

  function renderDialogBody() {
    if (renderFinalUnitsInput) {
      return (
        <Grid item xs={12}>
          <TextField
            type="text"
            name="finalUnits"
            variant="outlined"
            fullWidth
            label="Final Units"
            onChange={(event: any) => {
              event.persist();
              setFinalInput(event.target.value);
            }}
            defaultValue={finalInput}
          />
        </Grid>
      );
    } else {
      return "Are you sure you want to update the Allotment Status for this order?";
    }
  }

  const handleEditClick = (fieldName: string) => {
    const updatedDisabledFields = {
      dpid: true,
      clientbenfid: true,
      panno: true,
    };

    if (fieldName === "dpid") {
      updatedDisabledFields.dpid = false;
      updatedDisabledFields.clientbenfid = false;
    } else {
      updatedDisabledFields.panno = false;
    }
    setDisabledFields(updatedDisabledFields);
  };

  function generateInputPropsForModifyOrder() {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <TextField
            type="text"
            name="bidid"
            variant="outlined"
            fullWidth
            label="BidId"
            disabled={true}
            defaultValue={initialBidValues.bidid}
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              type="text"
              name="dpid"
              variant="outlined"
              fullWidth
              label="DP ID"
              defaultValue={initialOrderValues.dpid}
              onChange={handleOrderChange}
              disabled={disabledFields.dpid}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => handleEditClick("dpid")}>
              <EditIcon />
            </IconButton>
          </Grid>

          <Grid item xs={10}>
            <TextField
              type="text"
              name="clientbenfid"
              variant="outlined"
              fullWidth
              label="Client Benf ID"
              onChange={handleOrderChange}
              defaultValue={initialOrderValues.clientbenfid}
              disabled={disabledFields.clientbenfid}
            />
          </Grid>

          <Grid item xs={10}>
            <TextField
              type="text"
              name="panno"
              variant="outlined"
              fullWidth
              label="Pan No"
              onChange={handleOrderChange}
              disabled={disabledFields.panno}
              defaultValue={initialOrderValues.panno}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => handleEditClick("panno")}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            type="text"
            name="series"
            onChange={handleChange}
            variant="outlined"
            fullWidth
            label="Series"
            defaultValue={initialBidValues.series}
          />
        </Grid>
      </Grid>
    );
  }

  const getStatusColor = () => {
    return orderDetails?.allotmentstatus === "Cancelled" ? "red" : "green";
  };

  const mainInfo = React.useMemo(
    () => [
      {
        label: "Date",
        icon: "none",
        value: moment(orderDetails?.insertedAt).format(
          "(HH[H]:mm[M]) MMM DD, YYYY"
        ),
      },
      {
        label: "Scrip Id",
        icon: "none",
        value: orderDetails?.scripid,
        helperText: orderDetails?.issuer_of_security.issuerName.name,
      },
      {
        label: "Series",
        icon: "none",
        value: orderDetails?.bids[0].series,
      },
      {
        label: "Tenure",
        icon: "none",
        value: orderDetails?.ipo_quote_tenure,
      },
      {
        label: "Total Quantity",
        icon: "none",
        value: orderDetails?.totalqty,
      },
      {
        label: "Rate",
        icon: "none",
        value: orderDetails?.bids[0].rate,
      },
      {
        label: "Cheque Amount",
        icon: "none",
        value: orderDetails?.chequeamount,
      },
      {
        label: "Category",
        icon: "none",
        value: orderDetails?.category,
        helperText: orderDetails?.category,
      },
      {
        label: "Application No",
        icon: "none",
        value: orderDetails?.applicationno,
      },
      {
        label: "Bid Id",
        icon: "none",
        value: orderDetails?.bids[0].bidid,
      },
      {
        label: "Message",
        icon: "none",
        value: orderDetails?.message,
      },
      {
        label: "Applicant Name",
        icon: "none",
        value: orderDetails?.applicantname,
      },
      {
        label: "Pan No",
        icon: "none",
        value: orderDetails?.panno,
      },
      {
        label: "Depository",
        icon: "none",
        value: orderDetails?.depository,
      },
      {
        label: "DP Id",
        icon: "none",
        value: orderDetails?.dpid,
      },
      {
        label: "Client benf Id",
        icon: "none",
        value: orderDetails?.clientbenfid,
      },
      {
        label: "UPI Id",
        icon: "none",
        value: orderDetails?.accountnumber_upiid,
      },
      {
        label: "Allotment Status",
        icon: "none",
        value: orderDetails?.allotmentstatus,
      },
      {
        label: "Final Units",
        icon: "none",
        value: orderDetails?.final_units,
      },
    ],
    [orderDetails]
  );

  const additionalInfo = React.useMemo(
    () => [
      { label: "UUID", icon: "none", value: orderDetails?.uuid },
      {
        label: "User_Id",
        icon: "none",
        value: orderDetails?.user_id,
      },
      {
        label: "Cheque Received Flag",
        icon: "none",
        value: orderDetails?.chequereceivedflag,
      },
      { label: "Bank Name", icon: "none", value: orderDetails?.bankname },
      {
        label: "Location",
        icon: "none",
        value: orderDetails?.location,
      },
      {
        label: "IFSC Code",
        icon: "none",
        value: orderDetails?.ifsccode,
      },
      { label: "Cheque No", icon: "none", value: orderDetails?.chequeno },
      {
        label: "Bank Reference No",
        icon: "none",
        value: orderDetails?.bankreferenceno,
      },
      { label: "Bid Type", icon: "none", value: orderDetails?.bidtype },
      {
        label: "Scrip Code",
        icon: "none",
        value: orderDetails?.scripcode,
      },
      {
        label: "DP Flag",
        icon: "none",
        value: orderDetails?.dpflag,
      },
      {
        label: "DP Remark",
        icon: "none",
        value: orderDetails?.dpremark,
      },
      {
        label: "Mode of Payment",
        icon: "none",
        value: orderDetails?.modeofpayment,
      },
      {
        label: "Member Type",
        icon: "none",
        value: orderDetails?.membertype,
      },
      {
        label: "Error Code",
        icon: "none",
        value: orderDetails?.errorcode,
      },
      {
        label: "Sponsor Bankpay Status",
        icon: "none",
        value: orderDetails?.sponsorbankpaystatus,
        helperText: getPaymentStatus(
          Number(orderDetails?.sponsorbankpaystatus)
        ),
      },
      {
        label: "Sponsor Bank Actiontime",
        icon: "none",
        value: orderDetails?.sponsorbankactiontime,
      },
      {
        label: "Sponsor BankAMT Blocked",
        icon: "none",
        value: orderDetails?.sponsorbankamtblocked,
      },
      {
        label: "Reason",
        icon: "none",
        value: orderDetails?.rejectreason,
      },
      {
        label: "Bank Reference Chequeno",
        icon: "none",
        value: orderDetails?.bankreferencechequeno,
      },
      {
        label: "Quantity",
        icon: "none",
        value: orderDetails?.bids[0].quantity,
      },
      {
        label: "Order No",
        icon: "none",
        value: orderDetails?.bids[0].orderno,
      },
      {
        label: "Error Code",
        icon: "none",
        value: orderDetails?.bids[0].errorcode,
      },
      {
        label: "UpdatedAt",
        icon: "none",
        value: orderDetails?.updatedAt,
      },
    ],
    [orderDetails]
  );

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequest(`/ipo/ipoorders/by-uuid/${uuid}/`)
      .then((resp) => {
        if (resp.status == 200) {
          setOrderDetails(resp.data);
          setSelectedAllotmentStatus(resp.data.allotmentstatus);
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading, uuid]);

  return (
    <Grid className="pageTitle">
      {orderDetails?.allotmentstatus === "Cancelled" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Order Status:{" "}
          <span style={{ color: getStatusColor() }}>
            {orderDetails?.allotmentstatus}
          </span>
        </div>
      ) : (
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              onClick={(evt: any) => {
                setShowModifyIPOOrderDialog(true);
              }}
            >
              Modify Order
            </Button>
          </Grid>

          <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setModifyInternallyState(initialModifyInternallyState);
                setShowModifyInternallyDialog(true);
              }}
            >
              Modify Internally
            </Button>
          </Grid>

          <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              onClick={(event: any) => {
                setShowCancelIPOOrderDialog(true);
              }}
            >
              Cancel Order
            </Button>
          </Grid>

          <Grid item xs="auto">
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Allotment Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="isCurrentDayPurchaseDate"
                value={selectedAllotmentStatus}
                onChange={(event: any) => {
                  if (event.target.value === "Partial Allotment") {
                    setRenderFinalUnitsInput(true);
                  } else {
                    setRenderFinalUnitsInput(false);
                  }
                  setSelectedAllotmentStatus(event.target.value);
                  if (selectedAllotmentStatus !== event.target.value) {
                    setShowAllotmentStatusConfirmationDialog(true);
                  }
                }}
                label="T+0/T+1"
              >
                {ALLOTMENT_STATUS_OPTIONS &&
                  ALLOTMENT_STATUS_OPTIONS.length > 0 &&
                  ALLOTMENT_STATUS_OPTIONS.map((allotmentStatus, index) => (
                    <MenuItem value={allotmentStatus} key={index}>
                      {allotmentStatus}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowUPIEnquiryDialog(true)}
            >
              Enquiry Response
            </Button>
          </Grid>

          <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                makePOSTRequest("/ipo/dbtipoordernew_ipo/", {
                  uuid: orderDetails?.uuid,
                  paytype: "1",
                  bidtype: "1",
                  actioncode: "N",
                })
                  .then((response) => {
                    if (response && response.status === 201) {
                      setLoading(true);
                      let { data }: { data: IPOPlaceOrder } = response;
                      toastr.success(data.bids[0].message);
                    }
                  })
                  .catch((error) => toastr.error(error));
              }}
              disabled={orderDetails?.order_placed}
            >
              Place IPO Order
            </Button>
            {orderDetails?.order_placed && (
              <Typography sx={{ color: "#45E142", fontSize: 10, ml: 1 }}>
                Order Placed Successfully
              </Typography>
            )}
          </Grid>

          {JSON.parse(localStorage.getItem("loginUser")!)?.isSuperuser && (
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  makeDELETERequest(
                    `/ipo/ipo_order/delete/${orderDetails?.uuid}/`
                  )
                    .then((response) => {
                      if (response && response.status === 204) {
                        toastr.success("IPO Order deleted successfully");
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      }
                    })
                    .catch((error) => toastr.error(error));
                }}
              >
                Delete IPO Order
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      <Box className="profileshowConntan" sx={{ mt: 2 }}>
        {loading ? (
          <CircularProgress color="error" />
        ) : (
          <>
            <Grid container spacing={2}>
              {mainInfo.map((item, index) => (
                <Grid key={index} item xs={12} sm={4} md={3}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor={`${item.label
                        .toLowerCase()
                        .replace(/\s+/g, "-")}`}
                    >
                      {item.label}
                    </InputLabel>
                    <StyledTextInput
                      id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                      disabled
                      value={item.value}
                    />
                    {item.helperText && (
                      <FormHelperText
                        sx={{
                          color: "#45E142",
                          fontSize: 12,
                          ml: 1,
                        }}
                      >
                        {item.helperText}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            <Box
              component="hr"
              sx={{ borderColor: "#7659EE", opacity: 0.5, my: 4 }}
            />
            <Chip
              label="Additional Information"
              icon={<ExpandMoreIcon />}
              clickable
              variant="outlined"
              onClick={() => setExpanded(!expanded)}
              sx={{
                fontWeight: 600,
                color: "#7659EE",
                borderColor: "#7659EE",
                bgcolor: alpha("#7659EE", 0.1),
                ":hover": {
                  bgcolor: alpha("#EC4F56", 0.1),
                },
                "& .MuiChip-icon": {
                  rotate: expanded ? "180deg" : "0deg",
                  transition: "linear 0.3s",
                  color: "#EC4F56",
                },
              }}
            />
            <Collapse in={expanded}>
              <Grid container spacing={2} sx={{ mt: 3 }}>
                {additionalInfo.map((item, index) => (
                  <Grid key={index} item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel
                        shrink
                        htmlFor={`${item.label
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                      >
                        {item.label}
                      </InputLabel>
                      <StyledTextInput
                        id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                        disabled
                        value={item.value}
                      />
                      {item.helperText && (
                        <FormHelperText
                          sx={{
                            color: "#45E142",
                            fontSize: 12,
                            ml: 1,
                          }}
                        >
                          {item.helperText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </Collapse>
          </>
        )}
      </Box>

      {showCancelIPOOrderDialog && (
        <GenericDialog
          show={showCancelIPOOrderDialog}
          heading="Cancel IPO Order"
          inputProps="Are you sure you want to cancel this order?"
          onClose={(event: any) => {
            event.preventDefault();
            setShowCancelIPOOrderDialog(false);
          }}
          handleSubmit={cancelIPOOrder}
        />
      )}

      {showModifyIPOOrderDialog && (
        <GenericDialog
          show={showModifyIPOOrderDialog}
          heading="Modify IPO Order"
          inputProps={generateInputPropsForModifyOrder()}
          onClose={(event: any) => {
            event.preventDefault();
            setShowModifyIPOOrderDialog(false);
            setBid(initialBidValues);
            setModifyOrderFields(initialOrderValues);
          }}
          handleSubmit={modifyIPOOrder}
        />
      )}

      {showAllotmentStatusConfirmationDialog && (
        <GenericDialog
          show={showAllotmentStatusConfirmationDialog}
          heading="Change Allotment Status"
          inputProps={renderDialogBody()}
          onClose={(event: any) => {
            event.preventDefault();
            setShowAllotmentStatusConfirmationDialog(false);
          }}
          handleSubmit={changeAllotmentStatus}
        />
      )}

      {showUPIEnquiryDialog && (
        <GenericDialog
          show={showUPIEnquiryDialog}
          heading="DP UPI Enquiry"
          inputProps={renderDialogBodyForDPUPIEnquiry()}
          onClose={(event: any) => {
            event.preventDefault();
            setShowUPIEnquiryDialog(false);
          }}
          showCancelButton
          showSubmitButton={false}
        />
      )}

      {showModifyInternallyDialog && (
        <GenericDialog
          show={showModifyInternallyDialog}
          heading="Modify Internally"
          inputProps={renderModifyInternallyFields()}
          onClose={(event: any) => {
            event.preventDefault();
            setShowModifyInternallyDialog(false);
          }}
          handleSubmit={modifyIPOOrderInternally}
          showCancelButton
        />
      )}
    </Grid>
  );
}
