import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import IconButton from "@mui/material/IconButton";
import { makePOSTRequest } from "../Api/api";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  StyledSelectInput,
  StyledTextInput,
} from "./StyledComponents/StyledInputs";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import toastr from "toastr";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Newsletter = () => {
  const [template, setTemplate] = useState<File | null>(null);
  const [excel, setExcel] = useState<File | null>(null);
  const [sendTo, setSendTo] = useState("current");
  const [fileName, setFileName] = useState("");
  const [subject, setSubject] = useState("");

  const handleTemplateUpload = () => {
    let formData = new FormData();
    formData.append("file", template!);
    makePOSTRequest(`/admin/news_letter_user/upload_template/`, formData)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Template Uploaded Successfully");
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleTest = () => {
    makePOSTRequest(`/admin/news_letter_user/testing_mail_check/`, {
      subject,
      file_name: fileName,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Check care@smest.in");
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handlePublish = () => {
    let formData = new FormData();
    formData.append("email_file", excel!);
    formData.append("subject", subject);
    formData.append("file_name", fileName);
    let payload = {
      current_subscriber: true,
      subject,
      file_name: fileName,
    };
    makePOSTRequest(
      `/admin/news_letter_user/publish_mail/`,
      sendTo === "current" ? payload : formData
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Newsletter Sent Successfully");
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <Box
      sx={{
        maxWidth: ["90%", "80%"],
        mx: "auto",
        my: 5,
        bgcolor: "#FFFFFF",
        p: 2,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          component="label"
          role={"upload"}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          color={template ? "success" : "primary"}
        >
          {template ? "Uploaded" : "Upload Template"}
          <VisuallyHiddenInput
            type="file"
            onChange={(event) => {
              if (event.target.files && event.target.files.length > 0) {
                setTemplate(event.target.files[0]);
              } else {
                setTemplate(null);
              }
            }}
          />
        </Button>
        {template && (
          <IconButton
            sx={{ ml: 1, color: "#7659EE", p: 0 }}
            onClick={handleTemplateUpload}
          >
            <ArrowCircleRightTwoToneIcon fontSize="large" />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          my: 5,
          borderRadius: 3,
          p: 2,
          width: ["100%", "50%"],
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        }}
      >
        <FormControl fullWidth variant="standard" sx={{ mt: 3 }} required>
          <InputLabel shrink htmlFor="fileName">
            File Name
          </InputLabel>
          <StyledTextInput
            id="fileName"
            value={fileName}
            onChange={(event) => {
              setFileName(event.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ mt: 3 }} required>
          <InputLabel shrink htmlFor="subject">
            Subject
          </InputLabel>
          <StyledTextInput
            id="subject"
            multiline
            value={subject}
            onChange={(event) => {
              setSubject(event.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ mt: 3 }}>
          <InputLabel sx={{ fontWeight: 800 }} id="send-to-label">
            Send To?
          </InputLabel>
          <Select
            labelId="send-to-label"
            id="send-to-select"
            value={sendTo}
            label="Send To?"
            onChange={(event) => {
              setSendTo(event.target.value as string);
            }}
            input={<StyledSelectInput />}
          >
            <MenuItem value={"current"}>Current Subscriber</MenuItem>
            <MenuItem value={"upload"}>Upload Subscribers list</MenuItem>
          </Select>
        </FormControl>
        {sendTo === "upload" && (
          <Button
            component="label"
            role={"upload"}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            color={excel ? "success" : "primary"}
            sx={{ mt: 1 }}
          >
            {excel ? "Uploaded" : "Upload Excel"}
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => {
                if (event.target.files && event.target.files.length > 0) {
                  setExcel(event.target.files[0]);
                } else {
                  setExcel(null);
                }
              }}
            />
          </Button>
        )}
        <Box sx={{ display: "flex", mt: 3, gap: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={subject && fileName ? false : true}
            onClick={handleTest}
          >
            Test
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={subject && fileName ? false : true}
            onClick={handlePublish}
          >
            Publish
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Newsletter;
