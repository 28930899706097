import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import DrawTwoToneIcon from "@mui/icons-material/DrawTwoTone";
import CopyAllTwoToneIcon from "@mui/icons-material/CopyAllTwoTone";
import Collapse from "@mui/material/Collapse";
import {
  StyledSelectInput,
  StyledTextInput,
} from "../StyledComponents/StyledInputs";
import { StyledChip } from "./NewRFQOrderDetails";

const IFA_FEES = [
  { label: "Source", icon: "none" },
  { label: "Fee", icon: "none" },
];

const InvoiceInfo = () => {
  const [expandInvoiceInfo, setExpandInvoiceInfo] = useState(false);
  const [invoiceCleared, setInvoiceCleared] = useState("NO");
  const handleInvoiceClearedSelect = (event: SelectChangeEvent) => {
    setInvoiceCleared(event.target.value as string);
  };

  return (
    <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5, mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Invoice Information</Typography>
        <StyledChip
          label={expandInvoiceInfo ? "Collapse" : "Expand"}
          onClick={() => {
            setExpandInvoiceInfo(!expandInvoiceInfo);
          }}
          expandOrCollapse={expandInvoiceInfo}
        />
      </Box>
      <Box
        component="hr"
        sx={{ borderColor: "#7659EE", opacity: 0.5, mb: 4, mt: 2 }}
      />
      <Collapse in={expandInvoiceInfo}>
        <Grid container spacing={2}>
          {IFA_FEES.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <StyledTextInput
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  disabled
                  endAdornment={
                    <InputAdornment position="end">
                      {item.icon === "edit" ? (
                        <DrawTwoToneIcon />
                      ) : item.icon === "copy" ? (
                        <CopyAllTwoToneIcon />
                      ) : null}
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          ))}
          <Grid item xs={12} sm={4} md={3}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink htmlFor="utr-number">
                UTR Number
              </InputLabel>
              <StyledTextInput
                id="utr-number"
                disabled
                endAdornment={
                  <InputAdornment position="end">
                    <DrawTwoToneIcon />
                    <IconButton sx={{ ml: 1, color: "#7659EE", p: 0 }}>
                      <ArrowCircleRightTwoToneIcon fontSize="large" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                width: 200,
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <FormControl fullWidth variant="standard" size="small">
                <InputLabel sx={{ fontWeight: 800 }} id="invoice-cleared-label">
                  Invoice Cleared
                </InputLabel>
                <Select
                  labelId="invoice-cleared-label"
                  id="invoice-cleared-select"
                  value={invoiceCleared}
                  label="Invoice Cleared"
                  onChange={handleInvoiceClearedSelect}
                  input={<StyledSelectInput />}
                >
                  <MenuItem value={"No"}>No</MenuItem>
                  <MenuItem value={"Yes"}>Yes</MenuItem>
                </Select>
              </FormControl>
              <IconButton sx={{ mb: 0.5, ml: 1, color: "#7659EE", p: 0 }}>
                <ArrowCircleRightTwoToneIcon fontSize="large" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default InvoiceInfo;
