import * as React from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SelectBox from "@mui/material/Autocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { sxRoot, sxText, sxTypo, sxBox } from "../../Helpers/helpers";
import Box from "@mui/material/Box";
import Listbox from "../Listbox";
import Typography from "@mui/material/Typography";

const GreenCheckbox = withStyles({
  root: {
    color: blue[800],
    "&$checked": {
      color: blue[800],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function UpdatePrivatePlacementState(props) {
  const [isPrivatePlacement, setIsPrivatePlacement] = React.useState(
    props.quote.isPrivatePlacement
  );
  const [valueDate, setValueDate] = React.useState(
    new Date(props.quote.valueDate)
  );

  const [festivalOpen, setFestivalOpen] = React.useState(false);
  const [festival, setFestival] = React.useState(
    props.quote.festival_name_for_quote || "None"
  );

  const [percentageCompleted, setPercentageCompleted] = React.useState(
    props.quote.percentageCompleted || 0
  );
  const handleCheckBox = (event) => {
    event.persist();
    setIsPrivatePlacement(event.target.checked);
  };

  const handleValueDateChange = (date) => {
    setValueDate(date);
  };

  const convertToDate = (date) => {
    if (date) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      return dd + "/" + mm + "/" + yyyy;
    } else return "";
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let state = {
      isPrivatePlacement: isPrivatePlacement,
      valueDate: convertToDate(valueDate),
      quoteUUID: props.quote.uuid,
      festival_name_for_quote: festival,
      percentageCompleted,
    };
    setIsPrivatePlacement(props.quote.isPrivatePlacement);
    setValueDate(props.quote.valueDate);
    props.handleUpdate(event, state);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>
          Edit Private Placement Parameters for {props.quote.isin}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Grid item xs={6} style={{ "margin-top": "10px" }}>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={isPrivatePlacement}
                  onChange={handleCheckBox}
                  name="isPrivatePlacement"
                />
              }
              label="Is Private Placement"
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item justify="space-around" xs={8}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Value Date"
                  format="dd/MM/yyyy"
                  value={valueDate}
                  onChange={handleValueDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          <SelectBox
            id={"festival-offers"}
            value={festival}
            onChange={(_, newValue) => {
              setFestival(newValue);
            }}
            open={festivalOpen}
            onOpen={() => {
              setFestivalOpen(true);
            }}
            onClose={() => {
              setFestivalOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option}
            options={[
              "None",
              "Special Offer",
              "Diwali",
              "Christmas",
              "Dhanteras",
              "Holi",
              "Ganpati",
              "Gudi Padwa",
              "Independance Day",
              "New Year",
              "Raksha Bandhan",
              "Republic Day",
              "Akshay Tritiya",
            ]}
            size="medium"
            sx={sxRoot}
            popupIcon={<ExpandMoreIcon />}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Festival Offers"
                sx={(theme) => sxText(theme)}
                id={"festival-offers"}
                fullWidth
                margin="dense"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <Typography {...props} sx={sxTypo(selected)} variant="h6">
                {option}
              </Typography>
            )}
            PaperComponent={({ children }) => {
              return <Box sx={sxBox}>{children}</Box>;
            }}
            ListboxComponent={Listbox}
          />

          <TextField
            value={percentageCompleted}
            onChange={(event) => {
              setPercentageCompleted(Number(event.target.value));
            }}
            label="Percentage Completed"
            type="number"
            fullWidth
            margin="dense"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <>{percentageCompleted}%</>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
