import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import jsPDF from "jspdf";
import autoTable, { UserOptions } from "jspdf-autotable";
import UserDataPDF from "./UserDataPDF";
import { imgData } from "../../Helpers/smestLogoBase64ImgData";
import { PlusJakartaSansFontBase64String } from "../../Helpers/PlusJakartaSans-base64String";
import { makePOSTRequestOnUserService } from "../../Api/api";
import toastr from "toastr";

const headProps: UserOptions = {
  theme: "plain",
  headStyles: {
    fillColor: "#7659EE",
    textColor: "#FFFFFF",
  },
  styles: {
    font: "PlusJakartaSans-VariableFont_wght",
    fontSize: 12,
    halign: "center",
  },
};
const bodyProps: UserOptions = {
  theme: "grid",
  columnStyles: { 1: { textColor: "#7659EE" } },
  styles: {
    font: "PlusJakartaSans-VariableFont_wght",
    fontSize: 10,
    cellWidth: "auto",
    halign: "left",
  },
};

export interface ITRData {
  data: Data;
  status_code: number;
  success: boolean;
  message: string;
  message_code: string;
}

export interface Data {
  client_id: string;
  pan_number: string;
  compliant: boolean;
  pan_allotment_date: Date;
  masked_name: string;
  pan_aadhaar_linked: string;
  specified_person_under_206: string;
  pan_status: string;
  valid_pan: boolean;
}

const OfficeUseActions = (props: any) => {
  const {
    currentUser,
    panCard,
    proofOfAddressDetails,
    bankDetails,
    dematDetails,
  } = props;

  const [itrData, setITRData] = useState<ITRData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makePOSTRequestOnUserService(`/order_management_v2/pan/itr_compliance/`, {
      pan_number: panCard?.uuid,
    })
      .then((resp) => {
        if (active && [201, 200].includes(resp.status)) {
          setITRData(resp.data);
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        active && setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  const handleDownloadButton = () => {
    const doc = new jsPDF({ filters: ["ASCIIHexEncode"] });
    doc.addFileToVFS(
      "PlusJakartaSans-VariableFont_wght-normal.ttf",
      PlusJakartaSansFontBase64String
    );
    doc.addFont(
      "PlusJakartaSans-VariableFont_wght-normal.ttf",
      "PlusJakartaSans-VariableFont_wght",
      "normal"
    );
    doc.setFont("PlusJakartaSans-VariableFont_wght", "normal");

    doc.addImage(
      `data:image/png;base64,${imgData}`,
      "PNG",
      doc.internal.pageSize.getWidth() / 2 - 45 / 2,
      10,
      45,
      16
    );

    autoTable(doc, {
      html: "#kyc-verification-heading",
      startY: 35,
      ...headProps,
    });
    autoTable(doc, {
      html: "#kyc-verification-info",
      ...bodyProps,
    });

    autoTable(doc, {
      html: "#cvl-kra-heading",
      ...headProps,
    });
    autoTable(doc, {
      html: "#cvl-kra-info",
      ...bodyProps,
    });

    autoTable(doc, {
      html: "#income-tax-database-verification-heading",
      ...headProps,
    });
    autoTable(doc, {
      html: "#income-tax-database-verification-info",
      ...bodyProps,
    });

    autoTable(doc, {
      html: "#bank-penny-drop-verification-heading",
      ...headProps,
    });
    autoTable(doc, {
      html: "#bank-penny-drop-verification-info",
      ...bodyProps,
    });

    doc.save(`${currentUser.firstName}_${currentUser.lastName}.pdf`);
  };

  return (
    <Box sx={{ m: "0 auto" }}>
      {loading ? (
        "Fetching ITR Compliance Information..."
      ) : (
        <>
          <Button
            variant="contained"
            onClick={() => handleDownloadButton()}
            sx={{ m: 1 }}
          >
            Download User Data
          </Button>
          <Box sx={{ display: "none" }}>
            <UserDataPDF
              user={currentUser}
              pan={panCard}
              address={proofOfAddressDetails}
              bank={bankDetails}
              demat={dematDetails}
              itr={itrData}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default OfficeUseActions;
