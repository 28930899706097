import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import { OrderDetailsInterface } from "./interfaces";
import { StyledChip } from "./NewRFQOrderDetails";

const DealApproveInfo = ({
  orderDetails,
}: {
  orderDetails: OrderDetailsInterface;
}) => {
  const [expandDealApprove, setExpandDealApprove] = useState(false);
  const dealApproveInfo = useMemo(
    () => [
      {
        label: "Buyer Client Code",
        value: orderDetails.buyer_client_name,
      },
      {
        label: "Bank IFSC",
        value: orderDetails.initiator_ifsc,
      },
      {
        label: "Bank Account Number",
        value: orderDetails.initiator_bank_account_number,
      },
      {
        label: "DP Type",
        value: orderDetails.initiator_dp_type,
      },
      {
        label: "DP ID",
        value: orderDetails.initiator_dpid,
      },
      {
        label: "DP Client ID",
        value: orderDetails.initiator_client_id,
      },
      {
        label: "Deal Accept Time",
        value: "",
      },
      {
        label: "Custodian Code",
        value: orderDetails.initiator_custodian,
      },
    ],
    [orderDetails]
  );

  return (
    <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5, mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">
          Deal Approve Information [Counter Party Information]
        </Typography>
        <StyledChip
          label={expandDealApprove ? "Collapse" : "Expand"}
          onClick={() => {
            setExpandDealApprove(!expandDealApprove);
          }}
          expandOrCollapse={expandDealApprove}
        />
      </Box>
      <Box
        component="hr"
        sx={{ borderColor: "#7659EE", opacity: 0.5, mb: 4, mt: 2 }}
      />
      <Collapse in={expandDealApprove}>
        <Grid container spacing={2}>
          {dealApproveInfo.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <StyledTextInput
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  disabled
                  value={item.value}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default DealApproveInfo;
