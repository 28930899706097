import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import { OrderDetailsInterface } from "./interfaces";
import { StyledChip } from "./NewRFQOrderDetails";

const DealProposeInfo = ({
  orderDetails,
}: {
  orderDetails: OrderDetailsInterface;
}) => {
  const [expandDealPropose, setExpandDealPropose] = useState(false);
  const dealProposeInfo = useMemo(
    () => [
      { label: "Seller Client Code", value: orderDetails.sellerclientcode },
      {
        label: "Custodian Code",
        value: "",
      },
      {
        label: "Bank IFSC",
        value: orderDetails.responder_ifsc,
      },
      {
        label: "Bank Account Number",
        value: orderDetails.responder_bank_account_number,
      },
      {
        label: "DP Type",
        value: orderDetails.responder_dp_type,
      },
      { label: "DP ID", value: orderDetails.responder_dpid },
      {
        label: "DP Client ID",
        value: orderDetails.responder_client_id,
      },
      {
        label: "Deal Propose Time",
        value: "",
      },
    ],
    [orderDetails]
  );

  return (
    <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5, mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Deal Propose Information </Typography>
        <StyledChip
          label={expandDealPropose ? "Collapse" : "Expand"}
          onClick={() => {
            setExpandDealPropose(!expandDealPropose);
          }}
          expandOrCollapse={expandDealPropose}
        />
      </Box>
      <Box
        component="hr"
        sx={{ borderColor: "#7659EE", opacity: 0.5, mb: 4, mt: 2 }}
      />
      <Collapse in={expandDealPropose}>
        <Grid container spacing={2}>
          {dealProposeInfo.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <StyledTextInput
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  disabled
                  value={item.value}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default DealProposeInfo;
