import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDialog(props) {
  const classes = useStyles();
  const [user, setUser] = React.useState([{}]);
  const { order } = props;

  const renderReadOnlyOrder = (row, label) => {
    return (
      <TextField
        id="outlined-read-only-input"
        style={{ width: "400px", margin: "20px", justifyContent: "center" }}
        label={label}
        value={row}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    );
  };
  const handleChange = (event) => {
    event.persist();
    setUser(event.target.value);
  };

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.closeOrderDialog}
        TransitionComponent={Transition}
      >
        <Paper
          style={{
            justifyContent: "space-between",
            backgroundColor: "#14213D",
          }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.closeOrderDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                className={classes.title}
                onClick={props.closeOrderDialog}
              >
                Close
              </Typography>
              <Button autoFocus color="inherit" onClick={props.updateOrder}>
                Confirm Changes
              </Button>
            </Toolbar>
          </AppBar>
        </Paper>
        <div style={{ fontSize: "40px", float: "left" }}>Order Details</div>
        <Paper>
          {renderReadOnlyOrder(order.smestID, "Smest ID")}
          {renderReadOnlyOrder(order.orderID, "Order ID")}
          {renderReadOnlyOrder(order.orderDate, "Order Date")}
          {renderReadOnlyOrder(order.orderTime, "Order Time")}
          {renderReadOnlyOrder(order.customerName, "Customer Name")}
          {renderReadOnlyOrder(order.orderType, "Order Type")}
          {renderReadOnlyOrder(order.orderStatus, "Order Status")}
          {order.orderStatus === "On Hold"
            ? renderReadOnlyOrder(order.reasonForHold, "Reason For Hold")
            : ""}
          {renderReadOnlyOrder(order.isin, "ISIN")}
          {renderReadOnlyOrder(order.securityName, "Security Name")}
          {renderReadOnlyOrder(order.noOfUnits, "No Of Units")}
          {renderReadOnlyOrder(order.faceValue, "Face Value")}
          {renderReadOnlyOrder(order.paymentDate, "Payment Date")}
          {renderReadOnlyOrder(order.settlementType, "Settlement Type")}
          {renderReadOnlyOrder(order.purchasePrice, "Purchase Price")}
          {order.orderType === "Sell"
            ? renderReadOnlyOrder(order.customerSellPrice, "Customer SellPrice")
            : ""}
          {renderReadOnlyOrder(order.spread, "Spread")}
          {renderReadOnlyOrder(order.sellPrice, "Sell Price")}
          {renderReadOnlyOrder(order.sellYield, "Sell Yield")}
          {renderReadOnlyOrder(order.principal, "Principal")}
          {renderReadOnlyOrder(order.accrued, "Accrued Interest")}
          {renderReadOnlyOrder(order.stampDuty, "Stamp Duty")}
          {renderReadOnlyOrder(order.tcs, "TCS")}
          {renderReadOnlyOrder(order.transactionCost, "Transaction Cost")}
          {renderReadOnlyOrder(order.totalInvAmount, "Total Inv Amount")}
          {renderReadOnlyOrder(order.amountAvailable, "Amount Available")}
          {renderReadOnlyOrder(order.totalInvAmount, "Amount Blocked")}
        </Paper>
      </Dialog>
    </div>
  );
}
