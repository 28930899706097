import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import CopyAllTwoToneIcon from "@mui/icons-material/CopyAllTwoTone";
import Collapse from "@mui/material/Collapse";
import toastr from "toastr";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import { OrderDetailsInterface } from "./interfaces";
import copy from "copy-to-clipboard";
import { StyledChip } from "./NewRFQOrderDetails";
import FormHelperText from "@mui/material/FormHelperText";

const RazorpayData = ({
  orderDetails,
}: {
  orderDetails: OrderDetailsInterface;
}) => {
  const [expandRazorpayInfo, setExpandRazorpayInfo] = useState(true);

  const razorpayData = useMemo(
    () => [
      {
        label: "Payment Gateway ID",
        value: orderDetails?.razorpay_fe_order_id,
        helperText: "When order placed from user portal",
      },
      {
        label: "Payment Link ID",
        value: orderDetails?.razorpay_order_id,
        helperText: "When order placed from admin panel",
      },
      {
        label: "Payment Status",
        value: orderDetails?.payment_status,
      },
      {
        label: "Payment Link",
        icon: "copy",
        value: orderDetails?.payment_link,
      },
      {
        label: "Payment Cancelled Manually",
        value: orderDetails?.payment_cancelled ? "Yes" : "No",
      },
    ],
    [orderDetails]
  );

  return (
    <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5, mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Razorpay Information</Typography>
        <StyledChip
          label={expandRazorpayInfo ? "Collapse" : "Expand"}
          onClick={() => {
            setExpandRazorpayInfo(!expandRazorpayInfo);
          }}
          expandOrCollapse={expandRazorpayInfo}
        />
      </Box>
      <Box
        component="hr"
        sx={{ borderColor: "#7659EE", opacity: 0.5, mb: 4, mt: 2 }}
      />
      <Collapse in={expandRazorpayInfo}>
        <Grid container spacing={2}>
          {razorpayData.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <StyledTextInput
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  disabled
                  value={item.value}
                  endAdornment={
                    <InputAdornment position="end">
                      {item.icon === "copy" ? (
                        <CopyAllTwoToneIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            copy(item.value as string);
                            toastr.success(
                              "Payment Link copied to clipboard successfully!"
                            );
                          }}
                        />
                      ) : null}
                    </InputAdornment>
                  }
                />
                {item.helperText && (
                  <FormHelperText
                    sx={{
                      color: "#45E142",
                      fontSize: 12,
                      ml: 1,
                    }}
                  >
                    {item.helperText}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default RazorpayData;
