import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import { makePUTRequestOnUserService } from "../../Api/api";
import toastr from "toastr";
import { StyledSelectInput } from "../StyledComponents/StyledInputs";

const DealStatus = ({
  orderUUID,
  setLoading,
  dealStatus,
  setDealStatus,
}: {
  orderUUID: string;
  dealStatus: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDealStatus: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const handleDealStatusSelect = (event: SelectChangeEvent) => {
    setDealStatus(event.target.value as string);
  };
  const handleOrderStatus = () => {
    makePUTRequestOnUserService(
      `/order_management_v2/smestorders/order/update/?status=${dealStatus}/`,
      {
        status: dealStatus,
        uuid: orderUUID,
        admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
      }
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Deal status changed Successfully");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            width: 200,
            m: "0 auto",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <FormControl fullWidth variant="standard" size="small">
            <InputLabel sx={{ fontWeight: 800 }} id="deal-status-label">
              Deal Status
            </InputLabel>
            <Select
              labelId="deal-status-label"
              id="deal-status-select"
              value={dealStatus}
              label="Deal Status"
              onChange={handleDealStatusSelect}
              input={<StyledSelectInput />}
            >
              <MenuItem value={"Pending"}>Pending</MenuItem>
              <MenuItem value={"Confirmed"}>Confirmed</MenuItem>
              <MenuItem value={"Settled"}>Settled</MenuItem>
              <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            sx={{ mb: 0.5, ml: 1, color: "#7659EE", p: 0 }}
            onClick={handleOrderStatus}
          >
            <ArrowCircleRightTwoToneIcon fontSize="large" />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DealStatus;
