import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DownloadForOfflineTwoToneIcon from "@mui/icons-material/DownloadForOfflineTwoTone";
import { OrderDetailsInterface } from "./interfaces";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const Webhooks = ({
  orderDetails,
}: {
  orderDetails: OrderDetailsInterface;
}) => {
  const webhooks = useMemo(
    () => [
      {
        label: "Pay In Funds",
        status: orderDetails?.fund_credited,
        icon: <DownloadForOfflineTwoToneIcon />,
        color:
          orderDetails?.fund_credited === "RECEIVED" ? "#4fec6e" : "#EC4F56",
      },
      {
        label: "Pay Out Funds",
        status:
          orderDetails?.fund_debited === "RECEIVED"
            ? "GIVEN"
            : orderDetails?.fund_debited,
        icon: <DownloadForOfflineTwoToneIcon sx={{ rotate: "180deg" }} />,
        color:
          orderDetails?.fund_debited === "RECEIVED" ? "#4fec6e" : "#EC4F56",
      },
      {
        label: "Pay In Security",
        status: orderDetails?.security_credited,
        icon: <DownloadForOfflineTwoToneIcon />,
        color:
          orderDetails?.security_credited === "RECEIVED"
            ? "#4fec6e"
            : "#EC4F56",
      },
      {
        label: "Pay Out Security",
        status:
          orderDetails?.security_debited === "RECEIVED"
            ? "GIVEN"
            : orderDetails?.security_debited,
        icon: <DownloadForOfflineTwoToneIcon sx={{ rotate: "180deg" }} />,
        color:
          orderDetails?.security_debited === "RECEIVED" ? "#4fec6e" : "#EC4F56",
      },
      {
        label: "Deal Status",
        status:
          orderDetails?.dealstatus_settled === "RECEIVED"
            ? "SETTLED"
            : orderDetails?.dealstatus_settled,
        icon: <TaskAltIcon />,
        color:
          orderDetails?.dealstatus_settled === "RECEIVED"
            ? "#4fec6e"
            : "#EC4F56",
      },
    ],
    [orderDetails]
  );

  return (
    <Box sx={{ bgcolor: "#7659EE", borderRadius: 2.5, p: 2, mt: 2 }}>
      <Stack
        direction="row"
        spacing={4}
        useFlexGap
        flexWrap="wrap"
        justifyContent="center"
      >
        {webhooks.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Chip
              icon={item.icon}
              label={item.status}
              sx={{
                opacity: 0.8,
                fontWeight: 600,
                color: "#FFFFFF",
                bgcolor: item.color,
                "& .MuiChip-icon": {
                  color: "#FFFFFF",
                },
              }}
            />
            <Typography
              sx={{
                fontSize: 10,
                fontWeight: 600,
                mt: 1,
                color: "#FFFFFF",
              }}
            >
              {item.label}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Webhooks;
