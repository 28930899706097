import React, { useEffect, useState } from "react";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridToolbar,
} from "@material-ui/data-grid";
import {
  makeGETRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";
import Transition from "../../components/Transition";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { StyledTextInput } from "../../components/StyledComponents/StyledInputs";
import FormHelperText from "@mui/material/FormHelperText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { userServiceAxiosInstance } from "../../Api/setUserServiceToken";

interface DebarredListInterface {
  uuid: string;
  enddate: string;
  errorcode: number;
  message: string;
  pannumber: string;
  name: string;
  lead_owner_name: string;
}

const cells: GridColumns = [
  { field: "pannumber", headerName: "Pan Number", width: 190, editable: false },
  { field: "name", headerName: "Name", width: 190, editable: false },
  {
    field: "lead_owner_name",
    headerName: "Lead Owner",
    width: 190,
    editable: false,
  },
  {
    field: "enddate",
    headerName: "End Date",
    renderCell: (params: any) => {
      const rowData = params.row;
      return moment(rowData.enddate, "DD-MM-YYYY").format("DD MMM, YYYY");
    },
    width: 190,
    editable: false,
  },
  { field: "message", headerName: "Message", width: 190, editable: false },
  {
    field: "errorcode",
    headerName: "Error Code",
    width: 190,
    editable: false,
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    width: 130,
    renderCell: () => {
      return (
        <EditIcon
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    width: 130,
    renderCell: () => {
      return (
        <DeleteIcon
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      );
    },
  },
];

const DebarredUsers = () => {
  const [loading, setLoading] = useState(true);
  const [debarredList, setDebarredList] = useState<
    DebarredListInterface[] | null
  >(null);
  const [open, setOpen] = useState(false);
  const [selectedDebarredRecord, setSelectedDebarredRecord] =
    useState<DebarredListInterface | null>(null);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequestOnUserService(`order_management_v2/debarred_pan_list/`)
      .then((resp) => {
        if (active && resp.status == 200) {
          setDebarredList(resp.data);
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        active && setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  const handleCellClick = (params: GridCellParams) => {
    if (params.field === "edit") {
      setSelectedDebarredRecord({
        ...(params.row as DebarredListInterface),
        enddate: params.row.enddate
          ? moment(params.row.enddate, "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
      });
      setOpen(true);
    } else if (params.field === "delete") {
      handleDelete(params.row as DebarredListInterface);
    }
  };

  const handleUpdate = () => {
    makePUTRequestOnUserService(
      `order_management_v2/debarred_pan_update/${selectedDebarredRecord?.uuid}`,
      {
        pan_number: selectedDebarredRecord?.pannumber,
        end_date: selectedDebarredRecord?.enddate,
        message: selectedDebarredRecord?.message,
        error_code: selectedDebarredRecord?.errorcode,
      }
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Debarred record updated successfully.");
          setOpen(false);
          setSelectedDebarredRecord(null);
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleDelete = (fields: DebarredListInterface) => {
    userServiceAxiosInstance
      .delete(`order_management_v2/debarred_pan_delete/${fields.uuid}`)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Debarred record deleted successfully.");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <>
      <Box sx={{ p: 3, m: "0 auto", height: "90vh" }}>
        <DataGrid
          getRowId={(row) => (row.pannumber ? row.pannumber : 1)}
          components={{ Toolbar: GridToolbar }}
          rows={debarredList?.length ? debarredList : []}
          columns={cells}
          onCellClick={handleCellClick}
          pagination
          disableSelectionOnClick
        />
      </Box>
      {selectedDebarredRecord && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setOpen(false);
            setSelectedDebarredRecord(null);
          }}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent>
            <Grid container spacing={1}>
              {Object.keys(selectedDebarredRecord).map((item, index) => (
                <Grid item xs={6} key={index}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink htmlFor={item}>
                      {item
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}
                    </InputLabel>
                    <StyledTextInput
                      id={`${item}_${index}`}
                      name={item}
                      type={
                        item === "enddate"
                          ? "date"
                          : item === "errorcode"
                          ? "number"
                          : "text"
                      }
                      disabled={["name", "lead_owner_name", "uuid"].includes(
                        item
                      )}
                      value={
                        selectedDebarredRecord[
                          item as keyof DebarredListInterface
                        ]
                      }
                      onChange={(event) => {
                        const value = event.target.value;
                        setSelectedDebarredRecord(
                          (prev) =>
                            ({
                              ...prev,
                              [item as keyof DebarredListInterface]: value,
                            } as DebarredListInterface)
                        );
                      }}
                    />
                    {["enddate", "name", "lead_owner_name", "uuid"].includes(
                      item
                    ) && (
                      <FormHelperText
                        sx={{
                          color: "#45E142",
                          fontSize: 12,
                          ml: 1,
                        }}
                      >
                        {item === "enddate"
                          ? "Pattern: DD-MM-YYYY"
                          : "Can Ignore"}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleUpdate()} variant="contained">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DebarredUsers;
