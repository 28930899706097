import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
} from "../../Api/api";
import Button from "@mui/material/Button";
import toastr from "toastr";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface IQNA {
  que: string;
  ans: string[];
  name:
    | "age"
    | "residentialStatus"
    | "investmentBackground"
    | "financialGoal"
    | "yourPreference"
    | "investmentHorizon";
}

const QNA: IQNA[] = [
  {
    que: "Please let us know your age.",
    ans: ["0-17 years", "18-30 years", "31-50 years", "51 and above years"],
    name: "age",
  },
  {
    que: "Residential Status",
    ans: ["Indian Resident", "Non Resident Indian"],
    name: "residentialStatus",
  },
  {
    que: "Investment Background",
    ans: ["Seasoned Investor", "New to Investing"],
    name: "investmentBackground",
  },
  {
    que: "Financial Goal?",
    ans: [
      "Keep Investment Safe",
      "Earn Higher Returns",
      "Earn Tax Free Returns",
      "Pledge for F&O",
    ],
    name: "financialGoal",
  },
  {
    que: "Your preference",
    ans: ["AAA rated Bonds", "AA rated Bonds", "A & below rated Bonds"],
    name: "yourPreference",
  },
  {
    que: "Investment Horizon",
    ans: ["Up to 3 years", "3 to 5 years", "5 years and above"],
    name: "investmentHorizon",
  },
];

const RiskProfiling = (props: any) => {
  const { userUUID, isRiskProfileCompleted } = props;
  const [riskProfile, setRiskProfile] = useState({
    age: "",
    residentialStatus: "",
    investmentBackground: "",
    financialGoal: "",
    yourPreference: "",
    investmentHorizon: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequestOnUserService(`user/get_risk_profile/${userUUID}/`)
      .then((resp) => {
        if (active && resp.status == 200) {
          const { data } = resp;
          setRiskProfile({
            age: data.age,
            residentialStatus: data.residential_status,
            investmentBackground: data.investment_background,
            financialGoal: data.financial_goal,
            yourPreference: data.preference,
            investmentHorizon: data.investment_horizon,
          });
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        active && setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  const handleChange =
    (name: keyof typeof riskProfile) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRiskProfile({
        ...riskProfile,
        [name]: event.target.value,
      });
    };

  const handleCreateRiskProfile = () => {
    makePOSTRequestOnUserService(`user/create_risk_profile/`, {
      user: userUUID,
      age: riskProfile.age,
      residential_status: riskProfile.residentialStatus,
      investment_background: riskProfile.investmentBackground,
      financial_goal: riskProfile.financialGoal,
      preference: riskProfile.yourPreference,
      investment_horizon: riskProfile.investmentHorizon,
    })
      .then((resp) => {
        if (resp.status === 201) {
          toastr.success("Risk Profile Submitted Successfully!");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <Box sx={{ maxWidth: ["90%", "80%"], mx: "auto", my: 5 }}>
      {loading ? (
        <CircularProgress color="error" />
      ) : (
        <Grid container spacing={2}>
          {QNA.map((question, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <TextField
                label={question.que}
                select
                fullWidth
                value={riskProfile[question.name]}
                onChange={handleChange(question.name)}
              >
                {question.ans.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth={false}
              onClick={() => handleCreateRiskProfile()}
            >
              {isRiskProfileCompleted ? "Update" : "Submit"}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default RiskProfiling;
