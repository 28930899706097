import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeGETRequestOnUserService } from "../../Api/api";
import toastr from "toastr";
import { RouteComponentProps, withRouter, useParams } from "react-router-dom";
import { OrderDetailsInterface } from "./interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import RFQActionButtons from "./RFQActionButtons";
import RazorpayActionButtons from "./RazorpayActionButtons";
import DealInfoActionButtons from "./DealInfoActionButtons";
import DealStatus from "./DealStatus";
import Webhooks from "./Webhooks";
import RazorpayData from "./RazorpayData";
import ClientInfo from "./ClientInfo";
import DebarredInfo from "./DebarredInfo";
import SettlementInformation from "./SettlementInformation";
import NewRFQInfo from "./NewRFQInfo";
import DealProposeInfo from "./DealProposeInfo";
import DealApproveInfo from "./DealApproveInfo";
import InvoiceInfo from "./InvoiceInfo";
import Typography from "@mui/material/Typography";

export const StyledButton = styled((props: ButtonProps) => (
  <Button fullWidth variant="outlined" {...props} />
))({
  borderRadius: 10,
});

export const StyledChip = ({
  expandOrCollapse,
  onClick,
  label,
}: {
  expandOrCollapse: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  label: string;
}) => (
  <Chip
    label={label}
    icon={<ExpandMoreIcon />}
    clickable
    variant="outlined"
    onClick={onClick}
    sx={{
      fontWeight: 600,
      color: "#7659EE",
      borderColor: "#7659EE",
      bgcolor: alpha("#7659EE", 0.1),
      ":hover": {
        bgcolor: alpha("#EC4F56", 0.1),
      },
      "& .MuiChip-icon": {
        rotate: expandOrCollapse ? "180deg" : "0deg",
        transition: "linear 0.3s",
        color: "#EC4F56",
      },
    }}
  />
);

const NewRFQOrderDetails: React.FC<RouteComponentProps> = (props) => {
  const [expandRFQButtons, setExpandRFQButtons] = useState(false);
  const [expandRazorpayButtons, setExpandRazorpayButtons] = useState(false);
  const [expandDealInfoButtons, setExpandDealInfoButtons] = useState(false);
  const [dealStatus, setDealStatus] = useState("Pending");
  const [orderDetails, setOrderDetails] =
    useState<OrderDetailsInterface | null>(null);
  const [loading, setLoading] = useState(true);

  const {
    orderUUID,
  }: {
    orderUUID: string;
  } = useParams();

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequestOnUserService(
      `/order_management_v2/orders/by-uuid/${orderUUID}/`
    )
      .then((resp) => {
        if (active && resp.status == 200) {
          setOrderDetails(resp.data);
          setDealStatus(resp.data.deal_status);
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        active && setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Box sx={{ maxWidth: ["90%", "80%"], mx: "auto", my: 5 }}>
      {loading ? (
        <CircularProgress color="error" />
      ) : orderDetails ? (
        <>
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5 }}>
            {(JSON.parse(localStorage.getItem("loginUser")!)?.isSuperuser ||
              JSON.parse(localStorage.getItem("loginUser")!)?.can_see_all) && (
              <>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <StyledChip
                    label="RFQ Actions"
                    onClick={() => {
                      setExpandRFQButtons(!expandRFQButtons);
                      setExpandRazorpayButtons(false);
                      setExpandDealInfoButtons(false);
                    }}
                    expandOrCollapse={expandRFQButtons}
                  />
                  <StyledChip
                    label="Razorpay Actions"
                    onClick={() => {
                      setExpandRazorpayButtons(!expandRazorpayButtons);
                      setExpandDealInfoButtons(false);
                      setExpandRFQButtons(false);
                    }}
                    expandOrCollapse={expandRazorpayButtons}
                  />
                  <StyledChip
                    label="Deal Information"
                    onClick={() => {
                      setExpandDealInfoButtons(!expandDealInfoButtons);
                      setExpandRazorpayButtons(false);
                      setExpandRFQButtons(false);
                    }}
                    expandOrCollapse={expandDealInfoButtons}
                  />
                </Box>
                <Collapse in={expandRFQButtons}>
                  <RFQActionButtons
                    orderDetails={orderDetails}
                    orderUUID={orderUUID}
                    setLoading={setLoading}
                  />
                </Collapse>
                <Collapse in={expandRazorpayButtons}>
                  <RazorpayActionButtons
                    orderDetails={orderDetails}
                    orderUUID={orderUUID}
                    setLoading={setLoading}
                  />
                </Collapse>
                <Collapse in={expandDealInfoButtons}>
                  <DealInfoActionButtons
                    orderDetails={orderDetails}
                    orderUUID={orderUUID}
                    props={props}
                  />
                </Collapse>
                <Box
                  component="hr"
                  sx={{ borderColor: "#7659EE", opacity: 0.5, my: 4 }}
                />
                <DealStatus
                  orderUUID={orderUUID}
                  setLoading={setLoading}
                  dealStatus={dealStatus}
                  setDealStatus={setDealStatus}
                />
              </>
            )}
            <Webhooks orderDetails={orderDetails} />
          </Box>

          <RazorpayData orderDetails={orderDetails} />

          <ClientInfo orderDetails={orderDetails} />

          <DebarredInfo orderDetails={orderDetails} />

          <SettlementInformation orderDetails={orderDetails} />

          <NewRFQInfo orderDetails={orderDetails} />

          <DealProposeInfo orderDetails={orderDetails} />

          <DealApproveInfo orderDetails={orderDetails} />

          <InvoiceInfo />
        </>
      ) : (
        <Typography textAlign="center">
          Error Fetching Order Details, Pls check with backend
        </Typography>
      )}
    </Box>
  );
};

export default withRouter(NewRFQOrderDetails);
