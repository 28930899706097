import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import { OrderDetailsInterface } from "./interfaces";
import { StyledChip } from "./NewRFQOrderDetails";

const DebarredInfo = ({
  orderDetails,
}: {
  orderDetails: OrderDetailsInterface;
}) => {
  const [expandDebarredInfo, setExpandDebarredInfo] = useState(true);
  const debarredInfo = useMemo(
    () => [
      {
        label: "Debarred Status",
        value: "",
      },
      {
        label: "End Date",
        value: "",
      },
      {
        label: "PAN Number",
        value: "",
      },
      {
        label: "Error Code",
        value: "",
      },
    ],
    [orderDetails]
  );
  return (
    <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5, mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Client Debarred Status Information</Typography>
        <StyledChip
          label={expandDebarredInfo ? "Collapse" : "Expand"}
          onClick={() => {
            setExpandDebarredInfo(!expandDebarredInfo);
          }}
          expandOrCollapse={expandDebarredInfo}
        />
      </Box>
      <Box
        component="hr"
        sx={{ borderColor: "#7659EE", opacity: 0.5, mb: 4, mt: 2 }}
      />
      <Collapse in={expandDebarredInfo}>
        <Grid container spacing={2}>
          {debarredInfo.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <StyledTextInput
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  disabled
                  value={item.value}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default DebarredInfo;
