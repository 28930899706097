import React, { ChangeEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { ICalculations, IUser } from "./Interfaces";
import toastr from "toastr";
import {
  makeGETRequest,
  makeGETRequestOnUserService,
  makePUTRequest,
} from "../../Api/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import { DataGrid, GridColDef, GridToolbar } from "@material-ui/data-grid";
import Listbox from "../Listbox";

export const DATA_GRID_CELLS: GridColDef[] = [
  { field: "isin", headerName: "ISIN", flex: 1, editable: false },
  {
    field: "security_name",
    headerName: "Security Name",
    flex: 1,
    editable: false,
  },
  {
    field: "noofbonds",
    headerName: "No. of Bonds",
    flex: 1,
    editable: false,
  },
  {
    field: "settlement_date",
    headerName: "Settlement Date",
    flex: 1,
    editable: false,
  },
  {
    field: "func",
    headerName: "Function",
    flex: 1,
    editable: false,
  },
  {
    field: "input",
    headerName: "User Input",
    flex: 1,
    editable: false,
  },
];

interface IUserNameID {
  name: string;
  id: string;
}

const UserCalculations = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly IUserNameID[]>([]);
  const [value, setValue] = useState<IUserNameID | null>(null);
  const [count, setCount] = useState(0);
  const [calculations, setCalculations] = useState<any>([]);
  const [calculationsDone, setCalculationsDone] = useState(0);
  const [calculationsAllowed, setCalculationsAllowed] = useState(0);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequestOnUserService("/user/user_list/")
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (active) {
            const optionsArray = (data as IUser[]).map((item) => ({
              name: `${item.firstName} ${item.lastName} - ${item.mobileNumber}`,
              id: item.uuid,
            }));
            setOptions([...optionsArray]);
          }
        }
      })
      .catch((error) => toastr.error(error));
    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    let active = true;
    if (!value) {
      return undefined;
    }
    makeGETRequest(`/admin/quotes/trade_floor/past_calculate_get/${value?.id}/`)
      .then(({ data, status }: { data: ICalculations; status: number }) => {
        if (status === 200) {
          if (active) {
            setCount(data.count_allowed);
            let calculations = data.calc_value_data
              .sort((a, b) => {
                let dateA = new Date(
                  a.settlement_date.split("/").reverse().join("/")
                ).getTime();
                let dateB = new Date(
                  b.settlement_date.split("/").reverse().join("/")
                ).getTime();
                return dateB - dateA;
              })
              .map((item, index) => ({
                id: index,
                isin: item.isin,
                security_name: item.security_name,
                noofbonds: item.noofbonds,
                settlement_date: item.settlement_date,
                func: item.func,
                input: item.clean_price
                  ? `₹${item.clean_price}`
                  : item.bond_yield
                  ? `${item.bond_yield}%`
                  : `${item.expected_yield}%`,
              }));
            setCalculations(calculations);
            setCalculationsDone(data.count_hit);
            setCalculationsAllowed(data.count_allowed);
          }
        }
      })
      .catch((error) => toastr.error(error));
    return () => {
      active = false;
    };
  }, [value]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleCount = () => {
    makePUTRequest(`/admin/past_calculate_get/allow_counthit/`, {
      userUUID: value?.id,
      allow_counthits: count,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Allowed Calculations Saved Successfully.");
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <Box sx={{ maxWidth: ["90%", "80%"], mx: "auto", my: 5 }}>
      <Box sx={{ width: ["100%", 400], mx: "auto" }}>
        <Autocomplete
          id={"user-select"}
          value={value}
          onChange={(_, newValue) => {
            setValue(newValue);
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option.name}
          options={options}
          loading={loading}
          size="medium"
          sx={{
            "& .MuiAutocomplete-endAdornment": {
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
            },
          }}
          popupIcon={<ExpandMoreIcon />}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select User"
              sx={(theme) => ({
                "& .MuiInputBase-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                    },
                },
                "& .MuiInput-root": {
                  fontWeight: 700,
                  border: "2px solid #c1c2c4",
                  borderRadius: "12px",
                  padding: "8px 12px 8px 12px !important",
                  marginTop: "20px !important",
                  backgroundColor: "#F2F3F4",
                  ":focus-within": {
                    border: "2px solid #7569ee",
                  },
                },
                "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                  borderBottom: "none !important",
                },
                "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after":
                  {
                    borderBottom: "none !important",
                  },
                "& .MuiInputLabel-root": {
                  marginLeft: theme.spacing(1),
                  fontWeight: 550,
                  fontSize: 16,
                },
                "& label.Mui-focused": {
                  fontWeight: 800,
                },
                "& .MuiFormHelperText-root": {
                  marginLeft: theme.spacing(1),
                  fontWeight: 800,
                },
              })}
              id={"user-select"}
              fullWidth
              margin="dense"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="error" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <Typography
              {...props}
              sx={{
                textAlign: "left",
                fontSize: 14,
                fontWeight: selected ? 600 : 500,
                py: 1,
                px: "12px !important",
                mr: 1,
                borderRadius: "12px !important",
                ":hover": {
                  bgcolor: "#F2F3F4",
                },
              }}
              variant="h6"
            >
              {option.name as string}
            </Typography>
          )}
          PaperComponent={({ children }) => {
            return (
              <Box
                sx={{
                  bgcolor: "#FFFFFF",
                  my: 1,
                  p: 1.5,
                  borderRadius: "12px !important",
                }}
              >
                {children}
              </Box>
            );
          }}
          ListboxComponent={Listbox}
        />
      </Box>
      {value && (
        <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5, mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={true} sx={{ fontWeight: 800, color: "#7659EE" }}>
              {value?.name}
              <br />
              {`Calculations Done - ${calculationsDone}`}
              <br />
              {`Calculations Allowed - ${calculationsAllowed}`}
            </Grid>
            <Grid item xs={"auto"}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="count">
                  Calculations Allowed
                </InputLabel>
                <StyledTextInput
                  id="count"
                  type="number"
                  value={count}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setCount(Number(event.target.value));
                  }}
                  endAdornment={
                    <InputAdornment position="end" onClick={handleCount}>
                      <IconButton sx={{ ml: 1, color: "#7659EE", p: 0 }}>
                        <ArrowCircleRightTwoToneIcon fontSize="large" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ height: "100%", width: "100%", mt: 2 }}>
            <DataGrid
              pagination
              className="securityMasterDataGrid"
              components={{ Toolbar: GridToolbar }}
              rows={calculations}
              columns={DATA_GRID_CELLS}
              getRowId={(rfqOrder) => rfqOrder.id}
              autoHeight
              disableSelectionOnClick
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserCalculations;
