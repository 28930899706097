import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import {
  makePUTRequestOnUserService,
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  downloadUserServiceFileUsingGET,
} from "../../Api/api";
import toastr from "toastr";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Base64 } from "js-base64";
import DrawRoundedIcon from "@mui/icons-material/DrawRounded";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function checkIfUserCanCompleteKYC(props) {
  let personalDetails = {
    maritalStatus: props?.currentUser?.maritalStatus,
    hasPoliticalExposure: props?.currentUser?.hasPoliticalExposure,
    maidenName: props?.currentUser?.maidenName,
    motherName: props?.currentUser?.motherName,
    annualIncome: props?.currentUser?.annualIncome,
    gender: props?.currentUser?.gender,
    occupation: props?.currentUser?.occupation,
  };
  const propertiesToCheck = [
    "bankDetails",
    "dematDetails",
    "panCard",
    "proofOfAddressDetails",
  ];

  for (const property of propertiesToCheck) {
    if (!props[property]) {
      return false;
    }
  }

  return (
    props?.bankDetails &&
    Object.keys(props.bankDetails).length > 0 &&
    props?.panCard &&
    Object.keys(props.panCard).length > 0 &&
    props?.dematDetails &&
    Object.keys(props.dematDetails).length > 0 &&
    props?.proofOfAddressDetails &&
    Object.keys(props.proofOfAddressDetails).length > 0 &&
    personalDetails &&
    Object.keys(personalDetails).length > 0
  );
}

export default function CompleteKYC(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const canCompleteKYC = checkIfUserCanCompleteKYC(props);
  const [openKYCSigning, setOpenKYCSigning] = useState(false);
  const [loading, setLoading] = useState(false);

  const options = {
    is_iframe: true,
    environment: "production",
    callback: async function (response) {
      if (response?.error_code?.toLowerCase() === "cancelled") {
        toastr.error("Please complete the E-Signing process.");
        setOpenKYCSigning(false);
      } else if (response.hasOwnProperty("error_code")) {
        toastr.error("Something went wrong. Please try again later.");
        setOpenKYCSigning(false);
      } else {
        makePUTRequestOnUserService(`/kyc/finish/${props.userUUID}/`, {})
          .then((response) => {
            if (response && response.status === 200) {
              toastr.success("Your KYC Under Review with SMEST Team");
              setOpenKYCSigning(false);
            }
          })
          .catch((error) => {
            toastr.error("Something went wrong. Please try again.");
            setOpenKYCSigning(false);
          });
      }
    },
    logo: "/src/images/logo.svg",
    theme: {
      primaryColor: "#AB3498",
      secondaryColor: "#000000",
    },
    generate_access_token: true,
  };

  const handleESignDocDownload = async () => {
    try {
      let response = await makeGETRequestOnUserService(
        `/kyc/sign_request/create/${props.userUUID}/`
      );
      if (response && response.status === 200) {
        const { id: docID } = response.data;
        let docDownloadResponse = await downloadUserServiceFileUsingGET(
          `kyc/downloadesigndoc/${docID}/`
        );
        if (docDownloadResponse && docDownloadResponse.status === 200) {
          toastr.success("E-Sign Document Downloaded Successfully");
          saveAs(docDownloadResponse.data, `E_Sign_Doc.pdf`);
        }
      }
      setLoading(false);
    } catch (error) {
      toastr.error(error);
      setLoading(false);
    }
  };

  const handleESign = async () => {
    try {
      let response = await makeGETRequestOnUserService(
        `/kyc/sign_request/create/${props.userUUID}/`
      );
      if (response && response.status === 200) {
        if (typeof window !== "undefined") {
          const digio = new window.Digio(options);
          digio.init();
          const {
            id: docID,
            access_token: { id: tokenID },
            signing_parties: [{ identifier }],
          } = response.data;
          digio.submit(docID, identifier, tokenID);
          setOpenKYCSigning(false);
        }
      }
    } catch (error) {
      toastr.error(error);
    }
  };

  const markUserKYCAsComplete = async () => {
    try {
      let response = await makePUTRequestOnUserService("/kyc/mark_complete/", {
        user_id: props.userUUID,
        admin_name: JSON.parse(localStorage.getItem("loginUser"))?.name,
      });
      if (response && response.status === 200) {
        toastr.success("User KYC has been marked complete.");
      }
    } catch (error) {
      toastr.error(error);
    }
  };

  const handleUCCRegister = async () => {
    try {
      let response = await makePOSTRequestOnUserService(
        "/order_management_v2/register_ucc/",
        {
          user_id: props.userUUID,
          admin_name: JSON.parse(localStorage.getItem("loginUser"))?.name,
        }
      );
      if (response && response.status === 200) {
        toastr.success(response.data.ICDMUCCResponseList[0].message);
      }
    } catch (error) {
      toastr.error(error);
    }
  };

  const completeUserKYC = (event) => {
    event.preventDefault();
    markUserKYCAsComplete();
  };

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  function generateKYCDoc(event) {
    event.preventDefault();
    if (canCompleteKYC) {
      setIsLoading(true);
      makeGETRequestOnUserService(
        `/digio/doc/generate/${props.currentUser.uuid}/`
      )
        .then((response) => {
          setIsLoading(false);
          if (response && response.status === 200) {
            let encoded = Base64.encode(response.data);
            const blob = dataURItoBlob(encoded);
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error(error);
        });
    } else {
      toastr.warning("One or more required documents missing!");
    }
  }

  if (isLoading) {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  } else {
    if (canCompleteKYC) {
      return (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={() => {
              setLoading(true);
              handleESignDocDownload();
            }}
          >
            {loading ? "Downloading, pls wait..." : "Download E-Sign Document"}
          </Button>
          <br />
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={props.currentUser.isESignComplete || openKYCSigning}
            className={classes.button}
            startIcon={<DrawRoundedIcon />}
            onClick={() => {
              setOpenKYCSigning(true);
              handleESign();
            }}
          >
            {openKYCSigning ? "Loading Doc, Wait..." : "E-Sign Document"}
          </Button>
          <br />
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={props.currentUser.isRFQRegistrationComplete}
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={() => handleUCCRegister()}
          >
            Register UCC
          </Button>
          <br />
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={props.currentUser.areKYCDetailsVerified}
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={completeUserKYC}
          >
            Complete KYC
          </Button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={generateKYCDoc}
          >
            Generate KYC Doc
          </Button>
        </div>
      );
    }
  }
}
