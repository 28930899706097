import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import Skeleton from "@mui/material/Skeleton";
import PanCardInformationGrid from "./PanCardInformationGrid";
import {
  PanDetails,
  PanDetailsUpdate,
  ProofOfAddress,
} from "../../pages/WhatsappCampaign/Interfaces";
import ProofOfAddressInformationGrid from "./ProofOfAddressInformationGrid";
import KYCBankDetailsTab from "./KYCBankDetailsTab";
import KYCDematDetailsTab from "./KYCDematDetailsTab";
import PersonalDetails from "./PersonalDetails";
import CompleteKYC from "./CompleteKYC";
import Badge from "@mui/material/Badge";
import { Select, Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OfficeUseActions from "./OfficeUseActions";
import NomineeDetails from "./NomineeDetails";
import RiskProfiling from "./RiskProfiling";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function getAccountTypeLabel(accountTypeKey: string): string | undefined {
  const accountTypeMapping: Record<string, string> = {
    individual_resident: "Individual Resident",
    individual_non_resident: "Individual Non-Resident",
    huf: "Hindu Undivided Family",
    sole_proprietor: "Sole Proprietor",
    llp: "Limited Liability Partnership",
    partnership: "Partnership",
    privated_limited: "Private Limited",
    trust: "Trust",
    public_limited: "Public Limited",
    None: "None",
  };

  return accountTypeMapping[accountTypeKey];
}

export default function NewKYCDetailsTab(props: any) {
  const [value, setValue] = React.useState(0);
  const { currentUser } = props;
  const toastr = require("toastr");
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [panCardDetails, setPanCardDetails] = React.useState<any>(null);
  const [proofOfAddressDetails, setProofOfAddressDetails] =
    React.useState<any>(null);
  const [bankDetails, setBankDetails] = React.useState<any>(null);
  const [dematDetails, setDematDetails] = React.useState<any>(null);
  const [originalDematDetails, setOriginalDematDetails] =
    React.useState<any>(null);
  const [originalPanNumber, setOriginalPanNumber] = React.useState("");
  const [userDetails, setUserDetails] = React.useState<any>(null);
  const formDataInitialState = {
    maritalStatus: "",
    politicallyExposed: "",
    maidenName: "",
    mothersName: "",
    annualIncome: "",
    gender: "",
    occupation: "",
    wetSignImage: null as File | null,
    passportPhoto: null as File | null,
  };
  const [formData, setFormData] = React.useState(formDataInitialState);
  const [accountType, setSelectedAccountType] = React.useState(
    getAccountTypeLabel(currentUser?.accountType) || ""
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function incrementTabBy1(event: any) {
    event.preventDefault();
    setValue(value + 1);
  }

  React.useEffect(() => {
    makeGETRequestOnUserService(`/kyc/kyc_docs/list/${currentUser.uuid}/`)
      .then((response) => {
        if (response && response.status === 200) {
          setPanCardDetails(response.data.pan_card);
          setUserDetails(response.data?.personal_details);
          setOriginalPanNumber(response.data?.pan_card?.uuid);
          setProofOfAddressDetails(response.data.poa);
          setBankDetails(response.data.bank_details);
          setDematDetails(response.data.demat_details);
          setOriginalDematDetails(response.data.demat_details);
          setFormData(response?.data.personal_details || formDataInitialState);
          setIsLoaded(!isLoaded);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  function nullifyPanCard(event: any): any {
    event.preventDefault();
    setPanCardDetails(null);
  }

  function nullifyProofOfAddress(event: any): any {
    event.preventDefault();
    setProofOfAddressDetails(null);
  }

  const savePanCardDetails = (event: any, panCardDetails: PanDetailsUpdate) => {
    event.preventDefault();
    panCardDetails.uuid = panCardDetails.panCardNumber;
    let updatedPayload: any = {
      user_id: "",
      fathers_name: "",
      dob: "",
      name: "",
      uuid: "",
    };

    updatedPayload.user_id = currentUser.uuid;
    updatedPayload.fathers_name = panCardDetails.fathersName;
    updatedPayload.dob = panCardDetails.dateOfBirth;
    updatedPayload.name = panCardDetails.name;
    updatedPayload.uuid = panCardDetails.uuid;

    if (originalPanNumber === updatedPayload.uuid) {
      delete updatedPayload.uuid;
    }

    makePUTRequestOnUserService("/kyc/pan_card/update/", updatedPayload)
      .then((response: any) => {
        if (response && response.status === 200) {
          toastr.success("Updated user details successfully!");
          setOriginalPanNumber(response.data.uuid);
        }
      })
      .catch((error: any) => toastr.error(error));
  };

  function addPanCardDetails(event: any, panDetails: PanDetails) {
    event.preventDefault();
    let formData = new FormData();
    let requestURL = "/kyc/pan_card/create/";
    formData.append("uuid", panDetails.panCardNumber);
    formData.append("name", panDetails.name);
    formData.append("fathers_name", panDetails.fathersName);
    formData.append("image", panDetails.image);
    formData.append("user_uuid", currentUser.uuid);
    formData.append("dob", panDetails.dateOfBirth);
    makePOSTRequestOnUserService(requestURL, formData)
      .then((response) => {
        if (response && response.status === 201) {
          toastr.success("Added Pan Card for User!");
          incrementTabBy1(event);
          setPanCardDetails(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  }

  const saveProofOfAddress = (event: any, details: any, docType: any) => {
    details.user_id = currentUser.uuid;
    let documentType = getDocumentType(docType);

    //@ts-ignore
    if (["passport", "driver_license"].includes(documentType)) {
      if (details.expiryDate instanceof Date) {
        details.expiry_date = formatDate(details.expiryDate);
      } else {
        details.expiry_date = details.expiryDate;
      }
    }

    details.district_or_city = details.districtOrCity;

    let requestURL = `/kyc/${documentType}/update/`;
    makePUTRequestOnUserService(requestURL, details)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Updated Proof of address details successfully !");
          setProofOfAddressDetails({
            image: proofOfAddressDetails.image,
            uuid: details.uuid,
            address: details.address,
          });
        }
      })
      .catch((error) => toastr.error(error));
  };

  function saveBankDetails(
    event: any,
    bankDetails: any,
    selectedFile: File | null
  ) {
    let formData = new FormData();

    formData.append("account_number", bankDetails.accountNumber);
    formData.append("user_id", currentUser.uuid);
    formData.append("bank_name", bankDetails.bankName);
    formData.append("beneficiary_name", bankDetails.beneficiaryName);
    formData.append("ifsc_code", bankDetails.ifscCode);

    if (selectedFile) {
      formData.append("image", selectedFile);
    }

    let requestURL = "/kyc/bank_detail/update/";
    makePUTRequestOnUserService(requestURL, formData)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Updated bank details details successfully !");
        }
      })
      .catch((error) => toastr.error(error));
  }

  function nullifyBankDetails(event: any, selectedForDelete: any) {
    event.preventDefault();
    setBankDetails(
      bankDetails.filter(
        (details: any) =>
          details.account_number !== selectedForDelete.toString()
      )
    );
  }

  function addBankDetails(event: any, bankDetails: any, file: any) {
    bankDetails.user_id = currentUser.uuid;

    let formData = new FormData();
    formData.append("account_number", bankDetails.accountNumber);
    formData.append("ifsc_code", bankDetails.ifscCode);
    formData.append("bank_name", bankDetails.bankName);
    formData.append("image", file);
    formData.append("user_id", currentUser.uuid);
    formData.append("beneficiary_name", bankDetails.beneficiaryName);
    formData.append("perform_penny_drop", "false");
    formData.append("branch_name", bankDetails.branchName);

    makePOSTRequestOnUserService("/kyc/bank_detail/create/", formData)
      .then((response) => {
        if (response && response.status === 201) {
          toastr.success("Added bank details details successfully !");
          setBankDetails(response.data);
          incrementTabBy1(event);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function getDocumentType(type: string) {
    if (type === "Aadhar") return "aadhar_card";
    else if (type === "Passport") return "passport";
    else if (type === "Driver License") return "driver_license";
  }

  function formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const createProofOfAddress = (
    event: any,
    details: ProofOfAddress,
    docType: string
  ) => {
    let formData = new FormData();
    let documentType = getDocumentType(docType);

    //@ts-ignore
    if (["passport", "driver_license"].includes(documentType)) {
      formData.append("expiry_date", formatDate(details.expiryDate));
    }
    formData.append("user_id", currentUser.uuid);
    formData.append("uuid", details.uuid);
    formData.append("address", details.address);
    formData.append("front_part", details.selectedFile);
    formData.append("back_part", details.selectedFile);

    formData.append("district_or_city", details.districtOrCity);
    formData.append("pincode", details.pincode);
    formData.append("state", details.state);
    formData.append("country", details?.country);

    makePOSTRequestOnUserService(`/kyc/${documentType}/create/`, formData)
      .then((response) => {
        if (response && response.status === 201) {
          toastr.success("Added Proof of address for User!");
          incrementTabBy1(event);
          setProofOfAddressDetails(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const addDematDetails = (event: any, details: any) => {
    let formData = new FormData();
    formData.append("user_id", currentUser.uuid);
    formData.append("client_id", details.clientId);
    formData.append("dp_type", details.dpType);
    formData.append("dp_name", details.dpName);
    formData.append("dp_id", details.dpID);
    formData.append("file", details.file);
    makePOSTRequestOnUserService("/kyc/demat_detail/create/", formData)
      .then((response) => {
        if (response && response.status === 201) {
          toastr.success("Added Demat details details successfully !");
          setDematDetails(response.data);
          incrementTabBy1(event);
        }
      })
      .catch((error) => toastr.error(error));
  };

  function getDifferenceBwObjects(newObj: any, old: any) {
    let diff = {
      client_id: "",
      dp_id: "",
      dp_name: "",
      dp_type: "",
    };

    if (newObj.clientId !== old.client_id) {
      diff.client_id = newObj.clientId;
    }
    if (newObj.dpID !== old.dp_id) {
      diff.dp_id = newObj.dpID;
    }
    if (newObj.dpName !== old.dp_name) {
      diff.dp_name = newObj.dpName;
    }
    if (newObj.dpType !== old.dp_type) {
      diff.dp_type = newObj.dpType;
    }

    return diff;
  }

  function checkIfCertainValuesHaveChanged(newObj: any, old: any) {
    return (
      newObj.clientId === old.client_id &&
      newObj.dpID === old.dp_id &&
      newObj.dpName === old.dp_name &&
      newObj.dpType === old.dp_type
    );
  }

  const saveDematDetails = (event: any, dematDetails: any) => {
    dematDetails.userUUID = currentUser.uuid;
    let requestURL = "/kyc/demat_detail/update/";
    if (!checkIfCertainValuesHaveChanged(dematDetails, originalDematDetails)) {
      let diff: any = getDifferenceBwObjects(
        dematDetails,
        originalDematDetails
      );
      let withRemovedEmptyValues = Object.keys(diff)
        .filter((k: any) => diff[k] != "")
        .reduce((a, k: any) => ({ ...a, [k]: diff[k] }), {});
      Object.assign(withRemovedEmptyValues, { user_id: dematDetails.userUUID });
      makePUTRequestOnUserService(requestURL, withRemovedEmptyValues)
        .then((response) => {
          if (response && response.status === 200) {
            toastr.success("Updated demat details details successfully !");
            setOriginalDematDetails(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  function addPersonalDetails(event: any) {
    const formDataToSave = new FormData();
    formDataToSave.append("maritalStatus", formData.maritalStatus);
    formDataToSave.append("politicallyExposed", formData.politicallyExposed);
    formDataToSave.append("maidenName", formData.maidenName);
    formDataToSave.append("motherName", formData.mothersName);
    formDataToSave.append("annualIncome", formData.annualIncome);
    formDataToSave.append("gender", formData.gender);
    formDataToSave.append("occupation", formData.occupation);
    formDataToSave.append("userUUID", currentUser.uuid);

    if (formData.wetSignImage instanceof File) {
      formDataToSave.append("signpart", formData.wetSignImage || "");
    }

    if (formData.passportPhoto instanceof File) {
      formDataToSave.append("photopart", formData.passportPhoto || "");
    }

    makePUTRequestOnUserService(
      "/kyc/additional_details/update/",
      formDataToSave
    )
      .then((resp) => {
        toastr.success("Saved Personal Details successfully!");
        if (!getDisabledConditionForCompleteKYC()) {
          incrementTabBy1(event);
        }
      })
      .catch((error) => {
        toastr.error(error);
      });
  }

  function AccountType() {
    const accountTypeOptions = [
      "Individual Resident",
      "Individual Non-Resident",
      "Hindu Undivided Family",
      "Sole Proprietor",
      "Limited Liability Partnership",
      "Partnership",
      "Private Limited",
      "Trust",
      "Public Limited",
    ];

    const handleSubmit = (event) => {
      console.log("Selected Account Type:", accountType);
      makePUTRequestOnUserService("/user/set_account_type/", {
        userUUID: currentUser.uuid,
        accountType: accountType,
      })
        .then((resp) => {
          if (resp.status === 200) {
            toastr.success("Updated Account Type for user");
            incrementTabBy1(event);
          }
        })
        .catch((error) => {
          toastr.error(error);
        });
    };

    return (
      <div>
        <FormControl variant="outlined" style={{ width: "200%" }}>
          <InputLabel id="demo-simple-select-outlined-label">
            Account Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="accountType"
            value={accountType}
            onChange={(event) => {
              setSelectedAccountType(event.target.value);
            }}
            label="Account Type"
          >
            {accountTypeOptions.map((accountTypeOption, index) => (
              <MenuItem value={accountTypeOption} key={index}>
                {accountTypeOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginTop: "20px" }} // Add some spacing
        >
          Submit
        </Button>
      </div>
    );
  }

  function getDisabledConditionForCompleteKYC(): boolean {
    return !(
      panCardDetails &&
      proofOfAddressDetails &&
      bankDetails &&
      dematDetails
    );
  }

  if (isLoaded) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "fit-content",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            label={<Badge color="error">Account Type</Badge>}
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Badge
                badgeContent={panCardDetails?.failed_count}
                color="error"
                invisible={
                  panCardDetails?.failed_count === 0 ||
                  panCardDetails?.failed_count
                }
              >
                Pan Card
              </Badge>
            }
            {...a11yProps(1)}
          />

          <Tab
            label={
              <Badge
                badgeContent={proofOfAddressDetails?.failed_count}
                color="error"
                invisible={
                  proofOfAddressDetails?.failed_count === 0 ||
                  proofOfAddressDetails?.failed_count
                }
              >
                Proof Of Address
              </Badge>
            }
            {...a11yProps(2)}
          />

          <Tab
            label={
              <Badge
                badgeContent={bankDetails?.failed_count}
                color="error"
                invisible={
                  bankDetails?.failed_count === 0 || bankDetails?.failed_count
                }
              >
                Bank Details
              </Badge>
            }
            {...a11yProps(3)}
          />

          <Tab
            label={
              <Badge
                badgeContent={dematDetails?.failed_count}
                color="error"
                invisible={
                  dematDetails?.failed_count === 0 || dematDetails?.failed_count
                }
              >
                Demat Details
              </Badge>
            }
            {...a11yProps(4)}
          />

          <Tab label="Nominee Details" {...a11yProps(5)} />
          <Tab label="Risk Profile" {...a11yProps(6)} />
          <Tab label="Personal Details" {...a11yProps(7)} />
          <Tab label="Office Use Actions" {...a11yProps(8)} />
          <Tab
            label="Complete KYC"
            {...a11yProps(9)}
            disabled={getDisabledConditionForCompleteKYC()}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <AccountType />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PanCardInformationGrid
            panCard={panCardDetails}
            currentUser={currentUser}
            savePanCardDetails={savePanCardDetails}
            addPanCardDetails={addPanCardDetails}
            nullifyPanCard={nullifyPanCard}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ProofOfAddressInformationGrid
            aadharCardDetails={proofOfAddressDetails}
            currentUser={currentUser}
            saveAadharCardDetails={saveProofOfAddress}
            createProofOfAddress={createProofOfAddress}
            nullifyProofOfAddress={nullifyProofOfAddress}
            userDetails={userDetails}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <KYCBankDetailsTab
            bankDetails={bankDetails}
            nullifyBankDetails={nullifyBankDetails}
            currentUser={currentUser}
            saveBankDetails={saveBankDetails}
            addBankDetails={addBankDetails}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <KYCDematDetailsTab
            dematDetails={dematDetails}
            currentUser={currentUser}
            saveDematDetails={saveDematDetails}
            addDematDetails={addDematDetails}
            nullifyDematDetails={(event: any) => {
              event.preventDefault();
              setDematDetails(null);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <NomineeDetails
            areNomineeDetailsSubmitted={currentUser.areNomineeDetailsSubmitted}
            userUUID={currentUser.uuid}
          />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <RiskProfiling
            isRiskProfileCompleted={currentUser.isRiskProfileCompleted}
            userUUID={currentUser.uuid}
          />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <PersonalDetails
            addPersonalDetails={addPersonalDetails}
            setFormData={setFormData}
            formData={formData}
          />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <OfficeUseActions
            bankDetails={bankDetails}
            panCard={panCardDetails}
            currentUser={currentUser}
            dematDetails={dematDetails}
            proofOfAddressDetails={proofOfAddressDetails}
          />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <CompleteKYC
            userUUID={currentUser.uuid}
            bankDetails={bankDetails}
            panCard={panCardDetails}
            currentUser={currentUser}
            dematDetails={dematDetails}
            proofOfAddressDetails={proofOfAddressDetails}
          />
        </TabPanel>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  }
}
