import React, { useMemo } from "react";
import Grid from "@mui/material/Grid";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import Typography from "@mui/material/Typography";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";
import { RouteComponentProps } from "react-router-dom";
import { ActionButtons, OrderDetailsInterface } from "./interfaces";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { StyledButton } from "./NewRFQOrderDetails";

const RazorpayActionButtons = ({
  orderDetails,
  orderUUID,
  setLoading,
}: {
  orderDetails: OrderDetailsInterface;
  orderUUID: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const cancelPayment = () => {
    makeDELETERequestOnUserServiceWithQueryParameter(
      `order_management_v2/razorpay/payment_link/cancel/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Payment Link Cancelled Successfully");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const checkOrderStatus = () => {
    makeGETRequestOnUserService(
      `order_management_v2/razorpay/order_link/get/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Order Status Fetched Successfully");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const checkPaymentStatus = () => {
    makeGETRequestOnUserService(
      `order_management_v2/razorpay/payment_link/get/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Payment Status Fetched Successfully");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const generatePaymentLink = () => {
    makePOSTRequestOnUserService(
      `order_management_v2/razorpay/payment_link/generate/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Payment Link Generated Successfully");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const razorpayActionButtons: ActionButtons[] = useMemo(
    () => [
      {
        name: "Cancel T1 Payment",
        color: "error",
        icon: <DeleteForeverRoundedIcon />,
        disabled:
          (!!orderDetails?.payment_link &&
            orderDetails?.payment_status === "paid") ||
          ["cancelled", "paid", ""].includes(orderDetails?.payment_status!),
        helperText:
          "Only works when payment link generated but payment not done",
        handleClick: () => cancelPayment(),
      },
      {
        name: "Check T1 Payment Status",
        color: "success",
        icon: <AutorenewOutlinedIcon />,
        disabled:
          !!!orderDetails?.payment_link ||
          ["cancelled", "paid"].includes(orderDetails?.payment_status!),
        handleClick: () => checkPaymentStatus(),
      },
      {
        name: "Check T1 Order Status",
        color: "success",
        icon: <AutorenewOutlinedIcon />,
        disabled:
          !!!orderDetails?.razorpay_fe_order_id ||
          !!orderDetails?.payment_link ||
          orderDetails?.payment_status === "paid",
        handleClick: () => checkOrderStatus(),
      },
      {
        name: "Generate T1 Payment Link",
        color: "secondary",
        icon: <AccountBalanceRoundedIcon />,
        disabled:
          !!orderDetails?.payment_link ||
          orderDetails?.payment_status === "paid",
        handleClick: () => generatePaymentLink(),
      },
    ],
    [orderDetails]
  );
  return (
    <Grid container spacing={2} mt={4}>
      {razorpayActionButtons.map((button, index) => (
        <Grid item key={index} xs={6} sm={4} md={"auto"}>
          <StyledButton
            startIcon={button.icon}
            color={button.color}
            onClick={button.handleClick}
            disabled={button.disabled}
          >
            {button.name}
          </StyledButton>
          <Typography
            sx={{
              color: "#45E142",
              fontSize: 12,
              textAlign: "center",
              mt: 1,
            }}
          >
            {button.helperText}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default RazorpayActionButtons;
