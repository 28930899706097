import React from "react";
import { Grid, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { IssuerMasterBasicStructure } from "../../pages/IPO/Interfaces";

export default function DropDownComponent(props: any): React.ReactElement {
  function renderOptions(): React.ReactElement {
    if (
      props.options &&
      props.options.length > 0 &&
      props.optionsType !== "JSON"
    ) {
      return props.options.map((option: string, index: number) => {
        return (
          <MenuItem value={option} key={index}>
            {option}
          </MenuItem>
        );
      });
    } else {
      return props.options.map((option: IssuerMasterBasicStructure) => {
        return (
          <MenuItem value={option?.uuid} key={option?.uuid}>
            {option?.issuerName}{" "}
          </MenuItem>
        );
      });
    }
  }

  return (
    <Grid item xs={12}>
      <FormControl variant="outlined" style={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-outlined-label">
          {props.params?.inputLabel}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name={props.params?.name}
          value={props.params.defaultValue}
          defaultValue={props.params.defaultValue}
          onChange={props.handleChange}
          label={props.params?.inputLabel}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </Grid>
  );
}
