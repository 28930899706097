import React from "react";
import { Grid, TextField, Select, Button } from "@material-ui/core";
import DatePickerComponent from "../DatePicker/DatePickerInput";
import DropDownComponent from "../Dropdown/DropDownComponent";
import {
  IPOSecurityMasterJSXRow,
  IssuerMaster,
  IPORatingOrganizationMaster,
} from "../../pages/IPO/Interfaces";
import RadioButtonComponent from "../RadioButton/RadioButtonComponent";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { makeGETRequest } from "../../Api/api";
import IPORatingOrganizationMasterTable from "../IPORatingOrganizationMaster/IPORatingOrganizationMasterTable";
import AllocationRatioTable from "../AllocationRatioTable/AllocationRatioTable";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const toastr = require("toastr");
export default function CreateIPOSecurityMaster(props: any) {
  const { initialState, handleChange, setInitialState, handleFileChange } =
    props;
  const [issuerMasters, setIssuerMasters] = React.useState<IssuerMaster[]>([]);

  React.useEffect(() => {
    makeGETRequest("/admin/issuer_master/all/")
      .then((response: any) => {
        setIssuerMasters(response.data);
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  const firstAttributes = [
    {
      title: "UIN",
      name: "uin",
      defaultValue: initialState?.uin || "",
    },

    {
      title: "Scrip ID",
      name: "scripId",
      defaultValue: initialState?.scripId || "",
    },
    {
      title: "Issue Close Date",
      name: "issueCloseDate",
      defaultValue: initialState?.issueCloseDate || new Date(),
      type: "DatePicker",
    },
    {
      title: "Face Value",
      name: "faceValue",
      defaultValue: initialState?.faceValue || "",
    },
    {
      title: "Minimum Application Amount",
      name: "pricePerBond",
      defaultValue: initialState?.pricePerBond || "",
    },
    {
      title: "Nri Eligibility",
      name: "nriEligibility",
      defaultValue: initialState?.nriEligibility || "",
      type: "RadioButton",
      options: ["Yes", "No"],
    },
    {
      title: "Guarantee Type",
      name: "guaranteeType",
      defaultValue: initialState?.guaranteeType || "",
      type: "Dropdown",
      options: ["Guaranteed", "Non-Guaranteed", "Partially Guaranteed"],
    },
    {
      title: "Secured or Unsecured",
      name: "securedOrUnsecured",
      defaultValue: initialState?.securedOrUnsecured || "",
      type: "Dropdown",
      options: [
        "Senior Secured",
        "Senior Unsecured",
        "Secured",
        "Unsecured",
        "Unsecured Subordinated Tier I",
        "Unsecured Subordinated Tier II",
        "Unsecured Subordinated Tier 3",
      ],
    },
    {
      title: "Max Interest Description",
      name: "maxInterestDescription",
      defaultValue: initialState?.maxInterestDescription || "",
    },
    {
      title: "Issuer of Security",
      name: "issuerOfSecurityUUID",
      defaultValue: initialState?.issuerName || "",
      value: initialState?.issuerName || "",
      type: "Dropdown",
      options: issuerMasters.map((issuerMaster: IssuerMaster) => ({
        uuid: issuerMaster.uuid,
        issuerName: issuerMaster.issuerName,
        issuerType: issuerMaster.issuerType,
      })),
    },
  ];

  const secondAttributes: any = [
    {
      title: "Issue Description",
      name: "issueDescription",
      defaultValue: initialState?.issueDescription || "",
    },
    {
      title: "Issue Open Date",
      name: "issueOpenDate",
      defaultValue: initialState?.issueOpenDate || new Date(),
      type: "DatePicker",
    },
    {
      title: "Nature of Instrument",
      name: "natureOfInstrument",
      defaultValue: initialState?.natureOfInstrument || "",
    },
    {
      title: "Face Value Unit",
      name: "faceValueUnit",
      defaultValue: initialState?.faceValueUnit || "",
      type: "Dropdown",
      options: ["RUPEES", "LACS", "CRORES"],
    },
    {
      title: "Price per Bond Unit",
      name: "pricePerBondUnit",
      defaultValue: initialState?.pricePerBondUnit || "",
      type: "Dropdown",
      options: ["RUPEES", "LACS", "CRORES"],
    },
    {
      title: "Tax Category",
      name: "taxCategory",
      defaultValue: initialState?.taxCategory || "",
      type: "Dropdown",
      options: ["Taxable", "Tax Free"],
    },
    {
      title: "Sector",
      name: "sector",
      defaultValue: initialState?.sector || "",
      type: "Dropdown",
      options: [
        "Bank",
        "NBFC",
        "Transport",
        "Power",
        "Infrastructure",
        "Insurance",
        "Manufacturing",
        "Petrochemicals",
        "Municipal Bond",
        "FMCG",
        "Entertainment",
        "Telecommunication",
        "Financial Services",
      ],
    },
    {
      title: "Issue Size",
      name: "issueSize",
      defaultValue: initialState?.issueSize || "",
    },
    {
      title: "BPS Over Bank FDS Description",
      name: "bpsOverBankFdsDescription",
      defaultValue: initialState?.bpsOverBankFdsDescription || "",
    },
    {
      title: "Product Note File",
      name: "productNoteFile",
      defaultValue: "",
      type: "File",
    },
  ];

  function renderOptions(params: any): React.ReactElement {
    return params.options.map((option: any) => {
      return (
        <MenuItem value={option?.uuid} key={option?.uuid}>
          {option?.issuerName}{" "}
        </MenuItem>
      );
    });
  }

  function handleChangeForIssuerMaster(
    event: React.ChangeEvent<{ value: unknown }>
  ) {
    const selectedUUID = event.target.value as string;
    const filteredIssuerMaster = issuerMasters.find(
      (issuerMaster: IssuerMaster) => issuerMaster.uuid === selectedUUID
    );

    if (filteredIssuerMaster) {
      setInitialState({
        ...initialState,
        issuerOfSecurityUUID: filteredIssuerMaster.uuid,
        issuerName: filteredIssuerMaster.issuerName,
        issuerType: filteredIssuerMaster.issuerType,
      });
    }
  }

  function handleRadioButtonChange(event: any, propertyName: string): void {
    if (event.target.checked) {
      setInitialState({
        ...initialState,
        [propertyName]: event.target.value,
      });
    }
  }

  const handleDownload = async () => {
    try {
      const response = await makeGETRequest(
        `/ipo/security_master/product_note/download/${initialState.uuid}/`
      );
      const { result, download } = response.data;
      if (result !== "Ok") {
        throw new Error("Failed to retrieve the file. Result: " + result);
      }
      const { mimetype, filename, data } = download;
      if (!data) {
        throw new Error("No Base64 data found.");
      }
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimetype });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename || "downloaded-file";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      console.error("Error during download:", error);
      toastr.error("Error downloading file:", error.message || error);
    }
  };

  function renderTypeBasedInput(
    params: IPOSecurityMasterJSXRow
  ): React.ReactElement {
    switch (params?.type) {
      case "DoNotRender": {
        return <> </>;
      }
      case "File": {
        if (initialState?.productNoteFileName?.length !== 0) {
          return (
            <>
              <input type="file" onChange={handleFileChange} />
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <IconButton style={{ color: "green" }}>
                    <CheckIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{ fontSize: "14px", color: "green" }}
                  >
                    Product Note File is saved for this IPO Security Master
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                  >
                    Download File
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        }
        return <input type="file" onChange={handleFileChange} />;
      }
      case "DatePicker": {
        return (
          <DatePickerComponent
            handleChange={(date: Date | string) => {
              setInitialState({ ...initialState, [params?.name]: date });
            }}
            params={params}
          />
        );
      }

      case "Textarea": {
        return (
          <TextareaAutosize
            style={{ width: "300px", height: "200px" }}
            minRows="20"
            onChange={handleChange}
            value={params?.defaultValue}
          />
        );
      }

      case "Dropdown": {
        if (params.name === "issuerOfSecurityUUID") {
          return (
            <Grid item xs={12}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  {params?.inputLabel}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="issuerOfSecurityUUID"
                  value={initialState.issuerOfSecurityUUID || ""}
                  onChange={handleChangeForIssuerMaster}
                  label="Issuer O Security"
                >
                  {renderOptions(params)}
                </Select>
              </FormControl>
            </Grid>
          );
        } else {
          return (
            <DropDownComponent
              params={params}
              handleChange={handleChange}
              options={params?.options}
            />
          );
        }
      }

      case "RadioButton": {
        return (
          <RadioButtonComponent
            options={params?.options}
            params={params}
            handleRadioButtonChange={handleRadioButtonChange}
          />
        );
      }

      default:
        return (
          <TextField
            key="staticKey"
            id={params?.name}
            variant="outlined"
            name={params?.name}
            placeholder={params?.placeholder || ""}
            label=""
            onChange={handleChange}
            defaultValue={params?.defaultValue}
          />
        );
    }
  }

  function removeRatingOrganizationMasterFromState(uuid: string): void {
    props.setSavedRatingOrganizationMasters(
      props.savedRatingOrganizationMasters.filter(
        (ratingOrganizationMaster: any) =>
          ratingOrganizationMaster.uuid !== uuid
      )
    );
  }

  function addRatingOrganizationMasterToState(
    ratingOrganizationMasters: IPORatingOrganizationMaster[]
  ) {
    props.setSavedRatingOrganizationMasters(ratingOrganizationMasters);
  }

  function RenderRow(
    firstColumnAttributes: any,
    secondColumnAttributes: any
  ): React.ReactElement {
    return (
      <Grid container spacing={3}>
        {firstColumnAttributes ? (
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">
                {firstColumnAttributes?.title}
              </Grid>
              {renderTypeBasedInput(firstColumnAttributes)}
            </Grid>
          </Grid>
        ) : (
          <> </>
        )}
        {secondColumnAttributes ? (
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">
                {secondColumnAttributes?.title}
              </Grid>
              {renderTypeBasedInput(secondColumnAttributes)}
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    );
  }

  function getUpdatedAllocationRatio(records: any) {
    if (typeof records === "string") {
      try {
        return JSON.parse(props.allocationRatio);
      } catch (err) {
        console.error(err);
        return [];
      }
    }

    if (typeof records === "object") {
      return records;
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid className="profileshowConntan">
        {firstAttributes.map((attribute: any, index: number) => {
          return RenderRow(attribute, secondAttributes[index]);
        })}
      </Grid>
      <Grid item xs={12}>
        <AllocationRatioTable
          allocationRatio={getUpdatedAllocationRatio(props.allocationRatio)}
          addAllocationRatioRecord={props.addAllocationRatioRecord}
          removeAllocationRatioRecord={props.removeAllocationRatioRecord}
          updateAllocationRatioRecord={props.updateAllocationRatioRecord}
        />
      </Grid>
      {props.ipoSecurityMasterId && (
        <Grid item xs={12}>
          <IPORatingOrganizationMasterTable
            addToState={addRatingOrganizationMasterToState}
            rows={props.savedRatingOrganizationMasters}
            removeFromState={removeRatingOrganizationMasterFromState}
            ipoSecurityMasterId={props.ipoSecurityMasterId}
          />
        </Grid>
      )}
    </Grid>
  );
}
