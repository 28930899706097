import React from "react";
import UserCalculations from "../components/YieldCalculator/UserCalculations";

const YieldCalculator = () => {
  return (
    <>
      <UserCalculations />
    </>
  );
};

export default YieldCalculator;
