import React, { useEffect, useContext } from "react";
import toastr from "toastr";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { userTableCells } from "../../components/Users/TableUtils";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
  makeGETRequestOnUserService,
  makeGETRequestOnUserServiceAndDownloadFileResponse,
  makeGETRequest,
  makePOSTRequest,
} from "../../Api/api";
import { TextField, Grid, Button } from "@material-ui/core";
import { appContext } from "../../App";
import UserDetailsDialog from "./UserDetailsDialog";
import AddUserDialog from "./AddUserDialog";
import DeleteModal from "../DeleteModal";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import GenericDialog from "../Dialog/GenericDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditUserDialog from "./EditUserDialog";
import FullScreenDialog from "../Dialog/FullScreenDialog";
import UCCDetailsDialog from "../UserUCCDetails/UCCDetailsDialog";
import { addSerialNumber } from "../../Helpers/SecurityMasterHelpers";
import { withRouter } from "react-router-dom";

const styles = {
  container: {
    position: "absolute",
    top: 70,
    right: 0,
    height: "100%",
    width: "60%",
    padding: "10px",
  },
};

const dataGridCells = [
  { field: "serial", headerName: "Serial", width: 150, editable: false },
  {
    field: "client_ucc",
    headerName: "Client UCC",
    width: 150,
    editable: false,
  },
  { field: "errorcode", headerName: "Error Code", width: 150, editable: false },
  { field: "message", headerName: "Message", width: 150, editable: false },
  {
    field: "created_at",
    headerName: "Created At",
    width: 150,
    editable: false,
  },
  {
    field: "updated_at",
    headerName: "Updated At",
    width: 150,
    editable: false,
  },
];
function addSourceInformationForMarketer(users, marketerUserRelationships) {
  let formatted = [];
  users.map((user) => {
    let filtered = marketerUserRelationships.filter(
      (marketerUserRelationship) =>
        marketerUserRelationship.userId === user.uuid
    );
    if (filtered.length === 0) {
      formatted.push(user);
    } else {
      let updatedObject = { ...user };
      updatedObject.source = `${filtered[0].marketer.firstName} ${filtered[0].marketer.lastName}`;
      formatted.push(updatedObject);
    }
  });
  return formatted;
}

function getUpdatedUsers(users, updated) {
  let formatted = [];
  users.map((user) => {
    if (user.uuid === updated.uuid) {
      updated.name = `${updated.firstName} ${updated.lastName}`;
      formatted.push(updated);
    } else formatted.push(user);
  });
  return formatted;
}

function getFilteredUsers(users, uuid) {
  return users.filter((user) => user.uuid !== uuid);
}

function addSerialNumberToUsers(users) {
  let formatted = [];
  for (let iterator = 0; iterator < users.length; iterator++) {
    let userWithSerial = users[iterator];
    userWithSerial.serial = iterator + 1;
    userWithSerial.name = `${userWithSerial.firstName} ${userWithSerial.lastName}`;
    formatted.push(userWithSerial);
  }
  return formatted;
}

function removeDeletedUser(users, selectedMobileNumber) {
  return users.filter((user) => user.mobileNumber !== selectedMobileNumber);
}

const UserTable = (props) => {
  const { authUser, setAuthUser } = useContext(appContext);
  const [users, setUsers] = React.useState([]);
  const [currentlyViewable, setCurrentlyViewable] = React.useState("");
  const [showUserViewDetailsDialog, setShowUserViewDetailsDialog] =
    React.useState(false);
  const [showAddUserModal, setShowAddUserModal] = React.useState(false);
  const [toBeEdited, setToBeEdited] = React.useState(null);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = React.useState(false);
  const [selectedMobileNumber, setSelectedMobileNumber] = React.useState("");
  const [marketerUserRelationships, setMarketerUserRelationships] =
    React.useState([]);
  const [showEditUserModal, setShowEditUserModal] = React.useState(false);
  const [showConfirmAddUCCModal, setShowConfirmAddUCCModal] =
    React.useState(false);
  const [currentlySelectedUserIDForUCC, setCurrentlySelectedUserIdForUCC] =
    React.useState("");
  const [showUCCDDetailsDialog, setShowUCCDDetailsDialog] =
    React.useState(false);
  const [jsonData, setJsonData] = React.useState({});
  const [uccAPICallLogs, setUccAPICallLogs] = React.useState([]);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      let requestURL = "/user/user_list/";
      makeGETRequestOnUserService(requestURL)
        .then((response) => {
          if (response && response.status === 200) {
            response.data
              ? setUsers(
                  addSerialNumberToUsers(
                    response.data
                      .filter(
                        (item) =>
                          JSON.parse(localStorage.getItem("loginUser"))
                            ?.isSuperuser ||
                          JSON.parse(localStorage.getItem("loginUser"))
                            ?.can_see_all ||
                          item.leadowner ===
                            JSON.parse(localStorage.getItem("loginUser"))?.name
                      )
                      .sort((a, b) => {
                        if (
                          a.leadstofollow_up === null &&
                          b.leadstofollow_up !== null
                        ) {
                          return 1;
                        } else if (
                          a.leadstofollow_up !== null &&
                          b.leadstofollow_up === null
                        ) {
                          return -1;
                        } else if (
                          a.leadstofollow_up === null &&
                          b.leadstofollow_up === null
                        ) {
                          return 0;
                        } else if (
                          a.leadstofollow_up === "" &&
                          b.leadstofollow_up !== ""
                        ) {
                          return 1;
                        } else if (
                          a.leadstofollow_up !== "" &&
                          b.leadstofollow_up === ""
                        ) {
                          return -1;
                        } else {
                          return (
                            new Date(a.leadstofollow_up) -
                            new Date(b.leadstofollow_up)
                          );
                        }
                      })
                  )
                )
              : setUsers([]);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      makeGETRequest("/marketer/marketer_user_relationship/list/")
        .then((response) => {
          if (response.status === 200) {
            setMarketerUserRelationships(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  function handleMigrateNewsletterUsersClick(event) {
    event.preventDefault();
    if (users.length > 0) {
      let emailIds = users.map((user) => user.emailId);
      makePOSTRequest("/admin/news_letter_user/bulk_create/", {
        emailIds: emailIds,
      })
        .then((response) =>
          toastr.success("Created Newsletter Users Successfully!")
        )
        .catch((error) => toastr.error(error));
    }
  }

  const handleAddUserIconOnClick = (event) => {
    event.preventDefault();
    setShowAddUserModal(true);
  };

  function updateUCCDetailsForSingleUser(event) {
    event.preventDefault();
    makePUTRequestOnUserService(
      `/order_management_v2/ucc_api_response/update/${currentlySelectedUserIDForUCC}/`,
      { json_data: jsonData }
    )
      .then((resp) => {
        if (resp.status === 200 && resp.data.success) {
          toastr.success("UCC Details Updated Successfully!");
        } else {
          toastr.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => toastr.error(error));
  }
  function handleDownloadKYCDocs(row) {
    makeGETRequestOnUserServiceAndDownloadFileResponse(
      `/kyc/downloadzip/${row.uuid}/`
    )
      .then(({ blob, filename }) => {
        const downloadLink = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error);
        toastr.error(
          error.message || "An error occurred while downloading the ZIP file"
        );
      });
  }

  function handleUpdateUCCClick(row) {
    makeGETRequestOnUserService(
      `/order_management_v2/ucc_api_response/get/${row.uuid}/`
    )
      .then((response) => {
        if (response.data !== "None") {
          setJsonData(response.data);
          setCurrentlySelectedUserIdForUCC(row.uuid);
          setShowUCCDDetailsDialog(true);
        }
      })
      .catch((error) => toastr.error(error));

    makeGETRequestOnUserService(
      `/order_management_v2/ucc_api_call_log/list/${row.uuid}/`
    )
      .then((resp) => {
        if (resp.status === 200) {
          setUccAPICallLogs(resp.data);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function handleUpdateUCCNewClick(row) {
    props.history.push(`/update_ucc/${row.uuid}`);
  }

  function handleAddUCCIconClick(row) {
    setShowConfirmAddUCCModal(true);
    setCurrentlySelectedUserIdForUCC(row.uuid);
  }

  const handleViewDetailsIconOnClick = (row) => {
    setCurrentlyViewable(row);
    setShowUserViewDetailsDialog(true);
  };

  const closeViewUserDetailsDialog = (event) => {
    event.preventDefault();
    setShowUserViewDetailsDialog(false);
  };

  const closeAddUserModal = (event) => {
    event.preventDefault();
    setShowAddUserModal(false);
    setToBeEdited(null);
  };

  function callRegisterUCCAPI(event) {
    event.preventDefault();
    makePOSTRequestOnUserService("/order_management_v2/register_ucc/", {
      user_id: currentlySelectedUserIDForUCC,
    })
      .then((response) => {
        if (response.data.success) {
          toastr.success("User details registered to ICDM UCC successfully!");
        } else {
          toastr.error(response.data.ICDMUCCResponceList[0].MESSAGE);
        }
        setCurrentlySelectedUserIdForUCC("");
        setShowConfirmAddUCCModal(false);
      })
      .catch((error) => {
        toastr.error(error);
        setCurrentlySelectedUserIdForUCC("");
        setShowConfirmAddUCCModal(false);
      });
  }

  const editUserDetails = (event, requestBody) => {
    event.preventDefault();
    let requestURL = "/user/update/admin_panel/";
    requestBody.uuid = toBeEdited.uuid;
    makePUTRequestOnUserService(requestURL, requestBody)
      .then((response) => {
        if (response && response.status === 200) {
          setUsers(getUpdatedUsers(users, response.data));
          toastr.success("Updated User details successfully !");
          setToBeEdited(null);
          setShowEditUserModal(!showEditUserModal);
          setShowAddUserModal(false);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const addNewUser = (event, requestBody) => {
    event.preventDefault();
    let requestURL = "/user/create/";
    makePOSTRequestOnUserService(requestURL, requestBody)
      .then((response) => {
        if (response && response.status === 201) {
          users.push(response.data);
          setUsers(users);
          toastr.success("Added New User successfully !");
          setShowAddUserModal(false);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleEditIconOnClick = (row) => {
    makeGETRequestOnUserService(`/user/get/${row.uuid}`)
      .then((response) => {
        if (response && response.status === 200) {
          setToBeEdited(response.data);
          setShowEditUserModal(!showEditUserModal);
        }
      })
      .catch((error) => {
        toastr.error(error);
      });
  };

  const closeDeleteModal = (row) => {
    setShowDeleteModal(false);
    setToBeDeleted(null);
  };

  const deleteUser = async (row) => {
    let requestURL = `/user/delete/${toBeDeleted.uuid}/`;
    try {
      let response = await makeDELETERequestOnUserServiceWithQueryParameter(
        requestURL
      );
      if (response && response.status === 200) {
        toastr.success("Successfully deleted user!");
        setUsers(getFilteredUsers(users, toBeDeleted.uuid));
        setShowDeleteModal(false);
        setToBeDeleted(null);
      }
    } catch (error) {
      toastr.error(error);
    }
  };

  const currentlySelected = (params) => {
    if (params.field === "edit" && handleEditIconOnClick(params.row));
    else if (params.field === "delete" && closeDeleteModal(params.row));
    else if (
      params.field === "view" &&
      handleViewDetailsIconOnClick(params.row)
    );
    else if (params.field === "addUCC" && handleAddUCCIconClick(params.row));
    else if (params.field === "updateUCC" && handleUpdateUCCClick(params.row));
    else if (
      params.field === "updateUCCNew" &&
      handleUpdateUCCNewClick(params.row)
    );
    else if (params.field === "download" && handleDownloadKYCDocs(params.row));
  };

  function closeDeleteUserInformationModal(event) {
    event.preventDefault();
    setShowDeleteUserModal(!showDeleteUserModal);
  }

  function getMobileNumbersFromUsers() {
    return users.map((user) => user.mobileNumber);
  }

  function generateInputProps() {
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          options={getMobileNumbersFromUsers()}
          onChange={(event, enteredValue) =>
            setSelectedMobileNumber(enteredValue)
          }
          autoHighlight={true}
          value={selectedMobileNumber}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Mobile Number"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  }

  function deleteUserPermanently(event) {
    event.preventDefault();
    let filteredUser = users.filter(
      (user) => user.mobileNumber === selectedMobileNumber
    )[0];
    let requestURL = `/user/destroy_all_information/${filteredUser.uuid}/`;
    makeDELETERequestOnUserServiceWithQueryParameter(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          setUsers(removeDeletedUser(users, selectedMobileNumber));
          setShowDeleteUserModal(false);
          setSelectedMobileNumber("");
          toastr.success("Deleted Current User's information permanently");
        }
      })
      .catch((error) => toastr.error(error));
  }

  return (
    <Grid className="ordersDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>Users</div>
            </Grid>
            {authUser?.isSuperuser ? (
              <Grid item xs="auto">
                <Tooltip title="This will delete all user information permanently. Please be careful">
                  <DeleteForeverIcon
                    color="primary"
                    fontSize="large"
                    onClick={(event) =>
                      setShowDeleteUserModal(!showDeleteUserModal)
                    }
                    style={{
                      float: "right",
                      "margin-top": "29px",
                      "margin-right": "30px",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs="auto">
              <AddCircleIcon
                color="primary"
                fontSize="large"
                onClick={handleAddUserIconOnClick}
                style={{
                  float: "right",
                  "margin-top": "29px",
                  "margin-right": "30px",
                  cursor: "pointer",
                }}
              />
            </Grid>

            <Grid item xs="auto">
              <Tooltip title="This will create newsletter users based on already registered users. This is a one time job.">
                <Button
                  color="primary"
                  fontSize="large"
                  onClick={handleMigrateNewsletterUsersClick}
                  style={{
                    float: "right",
                    "margin-top": "29px",
                    "margin-right": "30px",
                    cursor: "pointer",
                  }}
                >
                  Migrate Newsletter Users{" "}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <div
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          {users.length > 0 && marketerUserRelationships.length > 0 && (
            <DataGrid
              className="userTable bcg"
              getRowId={(row) => row.uuid}
              components={{ Toolbar: GridToolbar }}
              rows={addSourceInformationForMarketer(
                users,
                marketerUserRelationships
              )}
              columns={userTableCells}
              onCellClick={currentlySelected}
              pagination
            />
          )}
        </div>
        <UserDetailsDialog
          open={showUserViewDetailsDialog}
          handleClose={closeViewUserDetailsDialog}
          currentUser={currentlyViewable}
        />
        <AddUserDialog
          show={showAddUserModal}
          userDetails={toBeEdited}
          closeAddUserModal={closeAddUserModal}
          addNewUser={addNewUser}
          editUserDetails={editUserDetails}
        />

        <DeleteModal
          primaryText="Delete User"
          secondaryText="Are you sure you want to delete this User ?"
          isOpen={showDeleteModal}
          handleClose={closeDeleteModal}
          confirmDelete={deleteUser}
        />
        {showDeleteUserModal ? (
          <GenericDialog
            show={showDeleteUserModal}
            heading="Delete User Information Permanently ? "
            inputProps={generateInputProps()}
            onClose={closeDeleteUserInformationModal}
            handleSubmit={deleteUserPermanently}
          />
        ) : (
          ""
        )}
        {showEditUserModal ? (
          <EditUserDialog
            open={showEditUserModal}
            handleClose={(event) => {
              setToBeEdited(null);
              setShowEditUserModal(!showEditUserModal);
            }}
            user={toBeEdited}
            handleSubmit={editUserDetails}
          />
        ) : (
          ""
        )}

        {showConfirmAddUCCModal ? (
          <GenericDialog
            show={showConfirmAddUCCModal}
            heading="Reguster UCC Details?"
            inputProps="Are you sure you want to register user details to ICDM UCC ? "
            onClose={() => {
              setShowConfirmAddUCCModal(false);
              setCurrentlySelectedUserIdForUCC("");
            }}
            handleSubmit={callRegisterUCCAPI}
          />
        ) : (
          ""
        )}

        {showUCCDDetailsDialog && (
          <FullScreenDialog
            heading="UCC API Details"
            showSecondarySaveButton={false}
            show={showUCCDDetailsDialog}
            handleClose={() => {
              setShowUCCDDetailsDialog(false);
              setJsonData({});
              setCurrentlySelectedUserIdForUCC("");
            }}
            showSaveButton={true}
            onSumitSaveButton={updateUCCDetailsForSingleUser}
            saveButtonText="Update UCC Details"
            inputProps={
              <div
                style={{
                  height: "100vh",
                  width: "100%",
                }}
              >
                <UCCDetailsDialog
                  userId={currentlySelectedUserIDForUCC}
                  jsonData={jsonData}
                  updateJsonData={(data) => {
                    setJsonData(data);
                  }}
                />
                <div style={styles.container}>
                  <DataGrid
                    pagination
                    className="UCC API Call Logs"
                    components={{ Toolbar: GridToolbar }}
                    rows={addSerialNumber(uccAPICallLogs)}
                    columns={dataGridCells}
                    getRowId={(row) => row.serial}
                  />
                </div>
              </div>
            }
          />
        )}
      </Paper>
    </Grid>
  );
};

export default withRouter(UserTable);
