import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  makeGETRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import Button from "@mui/material/Button";
import toastr from "toastr";

export interface UserUCCDetails {
  _id: string;
  user_id: string;
  UCCKYCDETAILS: Ucckycdetail[];
}

export interface Ucckycdetail {
  RecordAUDFlag: number;
  ClientUCC: string;
  Category: string;
  CustodianCode: null;
  DOR: Date;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Gender: string;
  MobileNo: string;
  EmailId: string;
  DOB: Date;
  PANNo: string;
  AadharNo: string;
  SEBIRegNo: null;
  CommunicationAddr: string;
  PAddr1: string;
  PAddr2: string;
  PAddr3: null;
  PCity: string;
  PState: string;
  PCountry: string;
  PPinCode: string;
  IncomeRange: string;
  ContactPersonName: null;
  DPClientId1: string;
  DPType1: string;
  ContactPersonDesignation: null;
  ContactPersonAddr: null;
  AutoDealConfirm: string;
  AutoDealSettle: string;
  IFSCCode1: string;
  BankAccNo1: string;
  BankAccType1: string;
  DefaultBank1: string;
  BankAccStatus1: string;
  IFSCCode2: null;
  BankAccNo2: null;
  BankAccType2: null;
  DefaultBank2: null;
  BankAccStatus2: null;
  IFSCCode3: null;
  BankAccNo3: null;
  BankAccType3: null;
  DefaultBank3: null;
  BankAccStatus3: null;
  IFSCCode4: null;
  BankAccNo4: null;
  BankAccType4: null;
  DefaultBank4: null;
  BankAccStatus4: null;
  IFSCCode5: null;
  BankAccNo5: null;
  BankAccType5: null;
  DefaultBank5: string;
  BankAccStatus5: null;
  DefaultDP1: string;
  DPStatus1: string;
  DPClientId2: null;
  DPType2: null;
  DefaultDP2: null;
  DPStatus2: null;
  DPClientId3: null;
  DPType3: null;
  DefaultDP3: null;
  DPStatus3: null;
  DPClientId4: null;
  DPType4: null;
  DefaultDP4: null;
  DPStatus4: null;
  DPClientId5: null;
  DPType5: null;
  DefaultDP5: null;
  DPStatus5: null;
  SessionId: null;
  UserID: string;
  LegalEntityID: null;
  Filler1: string;
  Filler2: string;
  Filler3: string;
  Filler4: string;
  ifsccode1: string;
  bankaccno1: string;
  dpclientid1: string;
  dptype1: string;
  recordaudflag: number;
  clientucc: string;
}

const UpdateUCC = () => {
  const [userUCCDetails, setUserUCCDetails] = useState<UserUCCDetails | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const {
    userUUID,
  }: {
    userUUID: string;
  } = useParams();

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequestOnUserService(
      `/order_management_v2/ucc_api_response/get/${userUUID}/`
    )
      .then((resp) => {
        if (active && resp.status == 200) {
          setUserUCCDetails(resp.data);
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        active && setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  const handleUpdateUCC = () => {
    makePUTRequestOnUserService(
      `/order_management_v2/ucc_api_response/update/${userUUID}/`,
      { json_data: userUCCDetails }
    )
      .then((resp) => {
        if (resp.status === 200 && resp.data.success) {
          toastr.success("UCC Details Updated Successfully!");
        } else {
          toastr.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <Box sx={{ maxWidth: ["90%", "80%"], mx: "auto", my: 5 }}>
      {loading ? (
        <CircularProgress color="error" />
      ) : userUCCDetails ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5 }}>
              <Grid container spacing={1}>
                {Object.keys(userUCCDetails.UCCKYCDETAILS[0]).map(
                  (item, index) => (
                    <Grid item xs={6} key={index}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel shrink htmlFor={item}>
                          {item
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </InputLabel>
                        <StyledTextInput
                          id={`${item}_${index}`}
                          name={item}
                          value={
                            userUCCDetails.UCCKYCDETAILS[0][
                              item as keyof Ucckycdetail
                            ]
                          }
                          onChange={(event) => {
                            const value = event.target.value;
                            setUserUCCDetails((prev) => ({
                              _id: prev?._id as string,
                              user_id: prev?.user_id as string,
                              UCCKYCDETAILS: [
                                {
                                  ...prev!.UCCKYCDETAILS[0],
                                  [item as keyof Ucckycdetail]: value,
                                },
                              ],
                            }));
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
            <Button
              variant="contained"
              size="large"
              fullWidth={false}
              onClick={() => handleUpdateUCC()}
            >
              Update UCC
            </Button>
          </Box>
        </>
      ) : (
        <Typography textAlign="center">
          Error Fetching UCC Details, Pls check with backend
        </Typography>
      )}
    </Box>
  );
};

export default UpdateUCC;
