import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GenericDialog from "../Dialog/GenericDialog";
import { makeDELETERequestOnUserServiceWithQueryParameter } from "../../Api/api";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DematDetailsInformationGrid from "./DematDetailsInformationGrid";
import documentPlaceHolderImage from "../../images/documentPlaceHolder.jpg";
import GetAppIcon from "@mui/icons-material/GetApp";
import { downloadBase64 } from "../../Helpers/helpers";

const toastr = require("toastr");

export default function KYCDematDetailsTab(props: any) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedForDelete, setSelectedForDelete] = useState<string>("");
  const [currentlySelectedForEdit, setCurrentlySelectedForEdit] =
    useState<any>(null);
  const [showEditDematDetailsDialog, setShowEditDematDetailsDialog] =
    useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const initialState = {
    dpType: "",
    dpName: "",
    clientId: "",
    dpID: "",
    oldClientId: "",
  };
  const [newDematDetails, setNewDematDetails] = React.useState(initialState);

  function deleteDematDetails(event: any) {
    event.preventDefault();

    makeDELETERequestOnUserServiceWithQueryParameter(
      `/kyc/demat_detail/delete/${props.currentUser.uuid}/${selectedForDelete}/`
    )
      .then((response) => {
        if (response?.status === 204) {
          toastr.success("Deleted demat details record !");
          setShowDeleteModal(!showDeleteModal);
          props.nullifyDematDetails(event, selectedForDelete);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function AddDematDetailsIcon() {
    return (
      <Grid item xs="auto">
        <AddCircleIcon
          color="primary"
          fontSize="large"
          id="add-circle-id"
          onClick={(event) => {
            setShowEditDematDetailsDialog(true);
          }}
          style={{ position: "fixed", top: 120, right: 50, zIndex: 1000 }}
        />
      </Grid>
    );
  }

  function DematDetailsList({ dematDetails }: any) {
    var details = null;
    if (
      typeof dematDetails === "object" &&
      !Array.isArray(dematDetails) &&
      dematDetails !== null
    ) {
      details = [dematDetails];
    } else {
      details = dematDetails;
    }
    return (
      <Grid container spacing={2}>
        {details.map((detail: any) => (
          <Grid item xs={12} sm={6} md={4} key={detail.id} sx={{ margin: 4 }}>
            <Card sx={{ minWidth: 275, height: "100%" }}>
              <img
                src={
                  detail.image
                    ? `data:image/png;base64, ${detail.image}`
                    : documentPlaceHolderImage
                }
                alt="Demat Logo"
                style={{
                  width: "100%",
                  maxHeight: "150px",
                  objectFit: "cover",
                }}
              />
              <Typography
                sx={{ fontSize: 14, ml: 2 }}
                color="text.primary"
                gutterBottom
              >
                Password - {detail.is_password_protected || "NA"}
              </Typography>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {detail.dp_type || "-"}
                </Typography>
                <Typography variant="h5" component="div">
                  {detail.dp_name || "-"}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {detail.client_id || "-"}
                </Typography>
                <Typography variant="body2">{detail.dp_id || "-"}</Typography>
              </CardContent>
              <CardActions>
                <IconButton
                  aria-label="edit"
                  onClick={(event) => {
                    setCurrentlySelectedForEdit(detail);
                    setEditMode(true);

                    setShowEditDematDetailsDialog(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="download"
                  onClick={() =>
                    downloadBase64(detail.image, detail?.image_filename)
                  }
                >
                  <GetAppIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={(event) => {
                    setShowDeleteModal(!showDeleteModal);
                    setSelectedForDelete(detail.client_id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                {detail.is_default && <Chip label="Default" color="success" />}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  if (props?.dematDetails) {
    return (
      <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs="auto">
            <AddDematDetailsIcon />
          </Grid>

          <DematDetailsList dematDetails={props?.dematDetails} />
        </Grid>
        {showDeleteModal ? (
          <GenericDialog
            show={showDeleteModal}
            heading="Delete Demat Details"
            inputProps="Are you sure you want to delete this demat details record?"
            onClose={(event: any) => {
              event.preventDefault();
              setShowDeleteModal(!showDeleteModal);
            }}
            handleSubmit={(event: any) => {
              event.preventDefault();
              deleteDematDetails(event);
            }}
          />
        ) : (
          ""
        )}

        {showEditDematDetailsDialog && (
          <GenericDialog
            fullWidth={true}
            show={showEditDematDetailsDialog}
            heading={editMode ? "Update Demat Details" : "Create Demat Details"}
            inputProps={
              <DematDetailsInformationGrid
                dematDetails={
                  editMode ? currentlySelectedForEdit : newDematDetails
                }
                saveDematDetails={props.saveDematDetails}
                addDematDetails={props.addDematDetails}
              />
            }
            showSubmitButton={false}
            onClose={(event: any) => {
              event.preventDefault();
              setShowEditDematDetailsDialog(!showEditDematDetailsDialog);
              if (editMode) {
                setEditMode(false);
              }
            }}
            handleSubmit={(event: any) => {
              event.preventDefault();
              setShowEditDematDetailsDialog(!showEditDematDetailsDialog);
              if (editMode) {
                setEditMode(false);
              }
            }}
          />
        )}
      </Grid>
    );
  } else {
    return (
      <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
        <Grid container alignItems="center" spacing={3}>
          <AddDematDetailsIcon />
        </Grid>
        {showEditDematDetailsDialog && (
          <GenericDialog
            fullWidth={true}
            show={showEditDematDetailsDialog}
            heading={editMode ? "Update Demat Details" : "Create Demat Details"}
            inputProps={
              <DematDetailsInformationGrid
                dematDetails={
                  editMode ? currentlySelectedForEdit : newDematDetails
                }
                saveDematDetails={props.saveDematDetails}
                addDematDetails={props.addDematDetails}
              />
            }
            showSubmitButton={false}
            onClose={(event: any) => {
              event.preventDefault();
              setShowEditDematDetailsDialog(!showEditDematDetailsDialog);
              if (editMode) {
                setEditMode(false);
              }
            }}
            handleSubmit={(event: any) => {
              event.preventDefault();
              setShowEditDematDetailsDialog(!showEditDematDetailsDialog);
              if (editMode) {
                setEditMode(false);
              }
            }}
          />
        )}
      </Grid>
    );
  }
}
