import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import { OrderDetailsInterface } from "./interfaces";
import { StyledChip } from "./NewRFQOrderDetails";
import moment from "moment";

const ClientInfo = ({
  orderDetails,
}: {
  orderDetails: OrderDetailsInterface;
}) => {
  const [expandClientInfo, setExpandClientInfo] = useState(true);

  const clientInfo = useMemo(
    () => [
      {
        label: "Order Date",
        value: moment(orderDetails?.insertedAt).format(
          "(HH[H]:mm[M]) MMM DD, YYYY"
        ),
      },
      {
        label: "Buyer Full Name",
        value: orderDetails?.buyer_full_name,
      },
      {
        label: "Buyer UCC",
        value: orderDetails?.buyer_client_name,
      },
      {
        label: "Lead Owner",
        value: orderDetails?.leadowner,
      },
    ],
    [orderDetails]
  );

  return (
    <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5, mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Client Information</Typography>
        <StyledChip
          label={expandClientInfo ? "Collapse" : "Expand"}
          onClick={() => {
            setExpandClientInfo(!expandClientInfo);
          }}
          expandOrCollapse={expandClientInfo}
        />
      </Box>
      <Box
        component="hr"
        sx={{ borderColor: "#7659EE", opacity: 0.5, mb: 4, mt: 2 }}
      />
      <Collapse in={expandClientInfo}>
        <Grid container spacing={2}>
          {clientInfo.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <StyledTextInput
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  disabled
                  value={item.value}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default ClientInfo;
