import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import toastr from "toastr";
import { TextField, Button, Grid, Typography, Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { appContext } from "../App";
import {
  getAllIssuerMasters,
  getAllSecurityTypes,
  getAllRatingsAndOrganizationNames,
  makePOSTRequest,
} from "../Api/api";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DataTable from "./DataTable";
import CallOptionDataTable from "./CallOptionDataTable";
import PutOptionDataTable from "./PutOptionDataTable";
import MaturityOptionDataTable from "./MaturityOptionDataTable";
import RatingOrganizationMasterTable from "./RatingOrganizationMasterTable";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DebentureDetailsDialog from "./DebentureDetailsDialog";
import RegistrarDetailsDialog from "./RegistrarDetailsDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  dataTable: {
    flexGrow: 1,
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    width: "400px",
  },
  textFieldClass: {
    width: "200px",
  },
  remarks: {
    width: "300px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddSecurityDialog = (props) => {
  const classes = useStyles();

  const getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + "/" + mm + "/" + yyyy;
    return today;
  };
  const initialState = {
    isin: "",
    nameOfInstrument: "Non Perpetual",
    securityType: "",
    securityTypeCode: "",
    securityCode: "",
    issuerOfSecurity: "",
    securityName: "",
    faceValue: "",
    faceValueUnit: "RUPEES",
    pricePerBond: "",
    pricePerBondUnit: "RUPEES",
    issueDate: "",
    frequencyOfInterest: "",
    frequencyOfInterestOption: "",
    lastIPDate: getCurrentDate(),
    lastIPDateOption: "Actual",
    interestDays: "Actual/Actual",
    couponOn: "",
    exchange: "",
    remarks: "",
    organizationName: "",
    rating: "",
    ratingDate: "",
    recordDate: "",
    ipPayment: "",
    taxFree: "",
    couponBasis: "",
    stepUpDown: "",
    taxCategory: "",
    sector: "",
    guaranteeType: "",
    bankTier: "",
    securedOrUnsecured: "",
    issueSize: "",
    modeOfIssue: "",
    guaranteeDetails: "",
    activeOn: "",
  };
  const debentureTrusteeDetailsInitialState = {
    nameOfTrustee: "",
    address: "",
    phone: "",
    email: "",
    website: "",
    file: null,
  };
  const registrarDetailsInitialState = {
    nameOfRegistrar: "",
    address: "",
    phone: "",
    email: "",
    website: "",
    file: null,
  };
  const [debentureTrusteeDetails, setDebentureTrusteeDetails] = React.useState(
    debentureTrusteeDetailsInitialState
  );
  const [registrarDetails, setRegistrarDetails] = React.useState(
    registrarDetailsInitialState
  );
  const amountOptions = ["LACS", "RUPEES", "CRORES"];
  const [currentState, setCurrentState] = React.useState(initialState);
  const { authUser, setAuthUser } = useContext(appContext);
  const [securityTypes, setSecurityTypes] = React.useState();
  const [issuerOfSecurities, setIssuerOfSecurities] = React.useState([]);
  const [organizationNames, setOrganizationNames] = React.useState([]);
  const [ratingOrganizationMappings, setRatingOrganizationMappings] =
    React.useState([]);
  const [nextIPDate, setNextIPDate] = React.useState(null);
  const [calculateInterestOnHolidays, setCalculateInterestOnHolidays] =
    React.useState(true);
  const [calculateInterestOnMaturity, setCalculateInterestOnMaturity] =
    React.useState(true);
  const [issueDateInclusive, setIssueDateInclusive] = React.useState(false);
  const [maturityDateInclusive, setMaturityDateInclusive] =
    React.useState(false);
  const [frequencyOfInterestOption, setFrequencyOfInterestOption] =
    React.useState("Normal");
  const [nriEligibility, setNriEligibility] = React.useState(false);
  const [lastIPDateOption, setLastIPDateOption] = React.useState("Max");
  const [addedCoupons, setAddedCoupons] = React.useState([
    { date: "", rate: "", rate1: "" },
  ]);
  const [addedCallOptions, setAddedCallOptions] = React.useState([
    { date: "", amount: "" },
  ]);
  const [addedPutOptions, setAddedPutOptions] = React.useState([
    { date: "", amount: "", uuid: "" },
  ]);
  const [maturityOptions, setMaturityOptions] = React.useState([
    { date: "", amount: "", uuid: "" },
  ]);
  const [addedRatingOrgMasters, setAddedRatingOrgMasters] = React.useState([
    {
      organizationName: "",
      rating: "",
    },
  ]);
  const [lastIpDate, setLastIpDate] = React.useState(new Date());
  const [issueDate, setIssueDate] = React.useState(new Date());
  const [showDebentureDetailsDialog, setShowDebentureDetailsDialog] =
    React.useState(false);
  const [showRegistrarDetailsDialog, setShowRegistrarDetailsDialog] =
    React.useState(false);
  const [debentureLogo, setDebentureLogo] = React.useState(null);
  const [registrarLogo, setRegistrarLogo] = React.useState(null);

  const [issuerType, setIssuerType] = React.useState("");
  const [retainCallDates, setRetainCallDates] = React.useState(false);
  const [retainMaturityDates, setRetainMaturityDates] = React.useState(false);
  const ratingOrganizationTableHeaders = [
    {
      id: "organizationName",
      numeric: true,
      disablePadding: false,
      label: "Organization Name",
    },
    { id: "rating", numeric: true, disablePadding: false, label: "Rating" },
    {
      id: "ratingDate",
      numeric: true,
      disablePadding: false,
      label: "Rating Date",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
    },
  ];
  const couponHeaders = [
    { id: "date", numeric: true, disablePadding: false, label: "Date" },
    { id: "rate", numeric: true, disablePadding: false, label: "Rate" },
    { id: "rate1", numeric: true, disablePadding: false, label: "Rate1" },
    { id: "action", numeric: false, disablePadding: true, label: "Action" },
  ];
  const callOptionHeaders = [
    { id: "date", numeric: true, disablePadding: false, label: "Date" },
    { id: "amount", numeric: true, disablePadding: false, label: "Amount" },
    { id: "action", numeric: true, disablePadding: false, label: "Action" },
  ];

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllSecurityTypes()
        .then((response) => {
          if (response && response.status === 200) {
            setSecurityTypes(response.data);
          }
        })
        .catch((error) => toastr.error("Security Types => " + error));
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllIssuerMasters()
        .then((response) => {
          if (response && response.status === 200) {
            setIssuerOfSecurities(response.data);
          }
        })
        .catch((error) => toastr.error("Issuer Masters => " + error));
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllRatingsAndOrganizationNames()
        .then((response) => {
          if (response && response.status === 200) {
            let organizationNames = getAllOrganizationNames(response.data);
            setOrganizationNames(organizationNames);
            setRatingOrganizationMappings(response.data);
          }
        })
        .catch((error) =>
          toastr.error("Ratings and Organization Names  => " + error)
        );
    }
  }, []);

  const isValuePresentInAccumulator = (accumulator, organizationUUID) => {
    return accumulator.some(
      (currentValue) => currentValue.uuid === organizationUUID
    );
  };

  const getAllOrganizationNames = (ratingOrganizationNames) => {
    let duplicatesRemoved = [];
    ratingOrganizationNames
      .map((ratingOrganizationName) => ratingOrganizationName.organization)
      .map((organization) => {
        if (isValuePresentInAccumulator(duplicatesRemoved, organization.uuid)) {
        } else {
          duplicatesRemoved.push(organization);
        }
      });
    return duplicatesRemoved;
  };

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const renderPricePerBond = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="pricePerBond"
          variant="outlined"
          fullWidth
          required
          label="Minimum Application Amount"
          defaultValue={currentState.pricePerBond}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderFaceValue = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="faceValue"
          variant="outlined"
          fullWidth
          required
          label="Face Value"
          defaultValue={currentState.faceValue}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderNameOfSecurity = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="securityName"
          variant="outlined"
          fullWidth
          required
          label="Name of Security"
          defaultValue={currentState.securityName}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderSecurityCode = () => {
    return (
      <Grid item xs={12}>
        <TextField
          required
          type="text"
          name="securityCode"
          variant="outlined"
          fullWidth
          label="Security Code"
          defaultValue={currentState.securityCode}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderSecurityTypeCodeInput = () => {
    return (
      <Grid item xs={12}>
        <TextField
          required
          type="text"
          name="securityTypeCode"
          variant="outlined"
          fullWidth
          label="Security Type Code"
          defaultValue={currentState.securityTypeCode}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderSecurityTypeDropdown = () => {
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Security Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="securityType"
            value={currentState.securityType}
            onChange={(event) => handleChange(event)}
            label="Security Type"
          >
            {securityTypes &&
              securityTypes.length > 0 &&
              securityTypes.map((securityType, index) => {
                return (
                  <MenuItem value={securityType.uuid} key={index}>
                    {securityType.type}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const getFilteredIssuerMasterByIssuerName = (enteredValue) => {
    return issuerOfSecurities.filter(
      (issuerOfSecrity) => issuerOfSecrity.issuerName === enteredValue
    )[0];
  };

  const handleIssuerNameChange = (event, enteredValue) => {
    event.preventDefault();
    let filteredIssuerMaster =
      getFilteredIssuerMasterByIssuerName(enteredValue);
    let state = currentState;
    state.issuerOfSecurity = filteredIssuerMaster.issuerName;
    setIssuerType(filteredIssuerMaster.issuerType);
  };

  const renderReadOnlyIssuerType = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="issuerType"
          variant="outlined"
          fullWidth
          disabled
          label="Issuer Type"
          value={issuerType}
        />
      </Grid>
    );
  };

  const renderIssuerOfSecurity = () => {
    let issuerNames = issuerOfSecurities.map(
      (issuerOfSecurity) => issuerOfSecurity.issuerName
    );
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          options={issuerNames}
          onChange={(event, enteredValue) =>
            handleIssuerNameChange(event, enteredValue)
          }
          autoHighlight={true}
          value={currentState.issuerOfSecurity}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label="Issuer Of Security"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  };

  const renderTaxFreeDropdown = () => {
    let taxFreeOptions = ["Retail", "Corporate"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Tax Free (Step Up/ Step Down) *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="taxFree"
            value={currentState.taxFree}
            onChange={(event) => handleChange(event)}
            label="Tax Free"
          >
            {taxFreeOptions &&
              taxFreeOptions.length > 0 &&
              taxFreeOptions.map((taxFreeOption, index) => {
                return (
                  <MenuItem value={taxFreeOption} key={index}>
                    {taxFreeOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderIpPaymentOptions = () => {
    let ipPaymentOptions = ["Next Working Day", "Previous Working Day", "None"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Ip Payment *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="ipPayment"
            value={currentState.ipPayment}
            onChange={(event) => handleChange(event)}
            label="IP Payment *"
          >
            {ipPaymentOptions &&
              ipPaymentOptions.length > 0 &&
              ipPaymentOptions.map((ipPayment, index) => {
                return (
                  <MenuItem value={ipPayment} key={index}>
                    {ipPayment}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderInterestDays = () => {
    let interestDays = ["Actual/Actual", "Actual/365", "Equal Days"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Interest Days
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="interestDays"
            value={currentState.interestDays}
            onChange={(event) => handleChange(event)}
            label="Interest Days"
          >
            {interestDays &&
              interestDays.length > 0 &&
              interestDays.map((interestDay, index) => {
                return (
                  <MenuItem value={interestDay} key={index}>
                    {interestDay}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderPricePerBondOptionsDropdown = () => {
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="pricePerBondUnit"
            value={currentState.pricePerBondUnit}
            onChange={(event) => handleChange(event)}
            label="Unit"
          >
            {amountOptions &&
              amountOptions.length > 0 &&
              amountOptions.map((amountOption, index) => {
                return (
                  <MenuItem value={amountOption} key={index}>
                    {amountOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderActiveOnDropdown = () => {
    let activeOnOptions = ["CDSL", "NSDL", "Both"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Active On *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="activeOn"
            value={currentState.activeOn}
            onChange={(event) => handleChange(event)}
            label="Active On *"
          >
            {activeOnOptions &&
              activeOnOptions.length > 0 &&
              activeOnOptions.map((activeOnOption, index) => {
                return (
                  <MenuItem value={activeOnOption} key={index}>
                    {activeOnOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderExchangeDropdown = () => {
    let exchangeOptions = ["NSE", "BSE", "Both", "Unlisted"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Exchange *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="exchange"
            value={currentState.exchange}
            onChange={(event) => handleChange(event)}
            label="Exchange *"
          >
            {exchangeOptions &&
              exchangeOptions.length > 0 &&
              exchangeOptions.map((exchangeOption, index) => {
                return (
                  <MenuItem value={exchangeOption} key={index}>
                    {exchangeOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderModeOfIssueDropdown = () => {
    let modeOfIssueOptions = ["IPO", "Private Placement"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Mode Of Issue
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="modeOfIssue"
            value={currentState.modeOfIssue}
            onChange={(event) => handleChange(event)}
            label="Mode Of Issue"
          >
            {modeOfIssueOptions &&
              modeOfIssueOptions.length > 0 &&
              modeOfIssueOptions.map((modeOfIssueOption, index) => {
                return (
                  <MenuItem value={modeOfIssueOption} key={index}>
                    {modeOfIssueOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderSecuredOrUnSecuredDropdown = () => {
    let options = [
      "Senior Secured",
      "Secured",
      "Unsecured",
      "Senior Unsecured",
      "Unsecured Subordinated Tier 1",
      "Unsecured Subordinated Tier 2",
      "Unsecured Subordinated Tier 3",
    ];
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Secured Or Unsecured
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="securedOrUnsecured"
            value={currentState.securedOrUnsecured}
            onChange={(event) => handleChange(event)}
            label="Secured Or Unsecured"
          >
            {options &&
              options.length > 0 &&
              options.map((option, index) => {
                return (
                  <MenuItem value={option} key={index}>
                    {option}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderBankTierDropdown = () => {
    if (currentState.sector === "Bank") {
      let bankTierOptions = ["Tier 1", "Tier 2", "Tier 3"];
      return (
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%" }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Bank Tier
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="bankTier"
              value={currentState.bankTier}
              onChange={(event) => handleChange(event)}
              label="Bank Tier"
            >
              {bankTierOptions &&
                bankTierOptions.length > 0 &&
                bankTierOptions.map((bankTierOption, index) => {
                  return (
                    <MenuItem value={bankTierOption} key={index}>
                      {bankTierOption}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      );
    } else return "";
  };

  const renderGuaranteeDetails = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="guaranteeDetails"
          variant="outlined"
          className={classes.textField}
          label="Guarantee Details"
          defaultValue={currentState.guaranteeDetails}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderTypeOfGuaranteeDropdown = () => {
    let typeOfGuaranteeOptions = [
      "Guaranteed",
      "Non-Guaranteed",
      "Partially Guaranteed",
    ];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Type Of Guarantee
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="guaranteeType"
            value={currentState.guaranteeType}
            onChange={(event) => handleChange(event)}
            label="Type of Guarantee"
          >
            {typeOfGuaranteeOptions &&
              typeOfGuaranteeOptions.length > 0 &&
              typeOfGuaranteeOptions.map((typeOfGuaranteeOption, index) => {
                return (
                  <MenuItem value={typeOfGuaranteeOption} key={index}>
                    {typeOfGuaranteeOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderSectorDropdown = () => {
    let sectorDropdownOptions = [
      "Bank",
      "NBFC",
      "Transport",
      "Power",
      "Infrastructure",
      "Insurance",
      "Manufacturing",
      "Petrochemicals",
      "Municipal Bond",
      "FMCG",
      "Entertainment",
      "Telecommunication",
      "Financial Services",
    ];
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Sector</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="sector"
            value={currentState.sector}
            onChange={(event) => handleChange(event)}
            label="Sector"
          >
            {sectorDropdownOptions &&
              sectorDropdownOptions.length > 0 &&
              sectorDropdownOptions.map((sectorDropdownOption, index) => {
                return (
                  <MenuItem value={sectorDropdownOption} key={index}>
                    {sectorDropdownOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };
  const renderTaxCategoryDropdown = () => {
    let taxCategoryDropDownOptions = ["Taxable", "Tax Free"];
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Tax Category
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="taxCategory"
            value={currentState.taxCategory}
            onChange={(event) => handleChange(event)}
            label="Tax Category"
          >
            {taxCategoryDropDownOptions &&
              taxCategoryDropDownOptions.length > 0 &&
              taxCategoryDropDownOptions.map(
                (taxCategoryDropDownOption, index) => {
                  return (
                    <MenuItem value={taxCategoryDropDownOption} key={index}>
                      {taxCategoryDropDownOption}
                    </MenuItem>
                  );
                }
              )}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderCouponOnDropdown = () => {
    let couponOnOptions = ["Rate", "Amount"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Coupon On *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="couponOn"
            value={currentState.couponOn}
            onChange={(event) => handleChange(event)}
            label="Coupon On *"
          >
            {couponOnOptions &&
              couponOnOptions.length > 0 &&
              couponOnOptions.map((couponOnOption, index) => {
                return (
                  <MenuItem value={couponOnOption} key={index}>
                    {couponOnOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderFaceValueOptionsDropdown = () => {
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="faceValueUnit"
            value={currentState.faceValueUnit}
            onChange={(event) => handleChange(event)}
            label="Unit"
          >
            {amountOptions &&
              amountOptions.length > 0 &&
              amountOptions.map((amountOption, index) => {
                return (
                  <MenuItem value={amountOption} key={index}>
                    {amountOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderNameOfInstrumentDropdown = () => {
    let nameOfInstrumentOptions = ["Perpetual", "Non Perpetual"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Name of Instrument *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="nameOfInstrument"
            value={currentState.nameOfInstrument}
            onChange={(event) => handleChange(event)}
            label="Name of Instrument *"
          >
            {nameOfInstrumentOptions &&
              nameOfInstrumentOptions.length > 0 &&
              nameOfInstrumentOptions.map((nameOfInstrument, index) => {
                return (
                  <MenuItem value={nameOfInstrument} key={index}>
                    {nameOfInstrument}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderPricePerBondOptions = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={6}>
            {renderPricePerBond()}
          </Grid>
          <Grid item xs={6}>
            {renderPricePerBondOptionsDropdown()}
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const handleRadioButtonChange = (event) => {
    if (event.target.checked) {
      let newState = currentState;
      newState.frequencyOfInterestOption = event.target.value;
      setCurrentState(newState);
    }
  };

  const getConvertedToBool = (val) => {
    if (val === "No") return false;
    else return true;
  };

  const handleNRIEligibilityChange = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setNriEligibility(convertedToBool);
  };

  const renderMaturityDateInclusive = () => {
    return (
      <>
        <FormLabel component="legend">Maturity Date Inclusive</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={maturityDateInclusive}
              onChange={handleMaturityDateInclusive}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        />

        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!maturityDateInclusive}
              onChange={handleMaturityDateInclusive}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        />
      </>
    );
  };

  const renderIssueDateInclusive = () => {
    return (
      <>
        <FormLabel component="legend">Issue Date Inclusive</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={issueDateInclusive}
              onChange={handleIssueDateInclusive}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        />

        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!issueDateInclusive}
              onChange={handleIssueDateInclusive}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        />
      </>
    );
  };

  const renderCalculateInterestOnMaturity = (event) => {
    return (
      <>
        <FormLabel component="legend">Calculate Interest On Maturity</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={calculateInterestOnMaturity}
              onChange={handleInterestOnMaturity}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!calculateInterestOnMaturity}
              onChange={handleInterestOnMaturity}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
      </>
    );
  };

  const handleMaturityDateInclusive = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setMaturityDateInclusive(convertedToBool);
  };

  const handleIssueDateInclusive = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setIssueDateInclusive(convertedToBool);
  };

  const handleInterestOnMaturity = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setCalculateInterestOnMaturity(convertedToBool);
  };

  const handleFrequencyOfInterestChange = (event) => {
    setFrequencyOfInterestOption(event.target.value);
  };

  const handleCalculateInterestOnHolidays = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setCalculateInterestOnHolidays(convertedToBool);
  };

  const renderCalculateInterestOnHolidays = () => {
    return (
      <>
        <FormLabel component="legend">Calculate Interest On Holidays</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={calculateInterestOnHolidays}
              onChange={handleCalculateInterestOnHolidays}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!calculateInterestOnHolidays}
              onChange={handleCalculateInterestOnHolidays}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
      </>
    );
  };

  const renderNRIEligibility = () => {
    return (
      <>
        <FormLabel component="legend">NRI Eligibility</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={nriEligibility}
              onChange={handleNRIEligibilityChange}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!nriEligibility}
              onChange={handleNRIEligibilityChange}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
      </>
    );
  };

  const handleLastIPDateOptionChange = (event) => {
    setLastIPDateOption(event.target.value);
  };

  const renderLastIPDateOption = () => {
    return (
      <>
        <FormLabel component="legend">Options</FormLabel>
        <FormControlLabel
          value="Max"
          label="Max"
          control={
            <Radio
              checked={lastIPDateOption === "Max"}
              onChange={handleLastIPDateOptionChange}
              value="Max"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
        <FormControlLabel
          value="Actual"
          label="Actual"
          control={
            <Radio
              checked={lastIPDateOption === "Actual"}
              onChange={handleLastIPDateOptionChange}
              value="Actual"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
      </>
    );
  };

  const renderFrequencyOptionsRadioButtons = () => {
    return (
      <>
        <FormLabel component="legend">Options</FormLabel>
        <FormControlLabel
          value="Normal"
          label="Normal"
          control={
            <Radio
              checked={frequencyOfInterestOption === "Normal"}
              onChange={handleFrequencyOfInterestChange}
              value="Normal"
              color="primary"
              name="radio-button-demo"
            />
          }
        />

        <FormControlLabel
          value="Compound"
          label="Compound"
          control={
            <Radio
              checked={frequencyOfInterestOption === "Compound"}
              onChange={handleFrequencyOfInterestChange}
              value="Compound"
              color="primary"
              name="radio-button-demo"
            />
          }
        />
      </>
    );
  };

  const handleLastIPDateChange = (date) => {
    setLastIpDate(date);
  };

  function handleNextIPDateChange(date) {
    setNextIPDate(date);
  }

  const renderLastIPDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Last IP Date"
            format="dd/MM/yyyy"
            value={lastIpDate}
            onChange={handleLastIPDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  };

  const renderRecordDate = () => {
    return (
      <Grid item xs={5}>
        <TextField
          type="text"
          name="recordDate"
          variant="outlined"
          fullWidth
          required
          label="Record Date"
          defaultValue={currentState.recordDate}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderlastIPDateOptions = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={5}>
            {renderLastIPDate()}
          </Grid>
          <Grid item xs={6}>
            {renderLastIPDateOption()}
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const renderFrequencyOfInterestOptions = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={8}>
            {renderFrequencyOfInterest()}
          </Grid>
          <Grid item xs={4}>
            {renderFrequencyOptionsRadioButtons()}
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const renderFaceValueAndOptions = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={6}>
            {renderFaceValue()}
          </Grid>
          <Grid item xs={6}>
            {renderFaceValueOptionsDropdown()}
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const handleIssueDateChange = (date) => {
    setIssueDate(date);
  };

  const removeEmptyValuesCoupon = (records) => {
    return records.filter(
      (record) =>
        record.date !== "" && record.rate !== "" && record.rate1 !== ""
    );
  };

  const removeEmptyValuesRatingOrgMaster = (addedRatingOrgMasters) => {
    return addedRatingOrgMasters.filter(
      (addedRatingOrgMaster) =>
        addedRatingOrgMaster.organizationName !== "" &&
        addedRatingOrgMaster.rating !== "" &&
        addedRatingOrgMaster.ratingDate !== ""
    );
  };

  const convertToDate = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  };

  const getRenamedProperties = (ratingOrganizationMasters) => {
    let withRenamedProperties = [];
    ratingOrganizationMasters.map((ratingOrganizationMaster) => {
      withRenamedProperties.push({
        organizationNameUUID: ratingOrganizationMaster.organizationName,
        ratingUUID: ratingOrganizationMaster.rating,
        ratingDate: convertToDate(ratingOrganizationMaster.ratingDate),
      });
    });
    return withRenamedProperties;
  };

  const removeEmptyValueDateAndAmount = (records) => {
    return records.filter(
      (record) => record.date !== "" && record.amount !== ""
    );
  };

  const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  };

  const pushImages = () => {
    let formData = new FormData();
    formData.append("isin", currentState.isin);
    formData.append("debenture_trustee_image", debentureLogo);
    formData.append("registrar_details_image", registrarLogo);
    makePOSTRequest("/admin/debt_registar_logo/", formData)
      .then(() => {
        toastr.success("Successfully Uploaded Logos");
        setCurrentState(initialState);
      })
      .catch((error) => toastr.error(error));
  };

  useEffect(() => {
    props.pushImages && pushImages();
  }, [props.pushImages]);

  const handleSecuritySubmit = (event) => {
    event.preventDefault();
    if (authUser && authUser.uuid) {
      currentState.faceValue =
        currentState.faceValue + " " + currentState.faceValueUnit;
      currentState.pricePerBond =
        currentState.pricePerBond + " " + currentState.pricePerBondUnit;
      currentState.calculateInterestOnHolidays = calculateInterestOnHolidays;
      currentState.calculateInterestOnMaturity = calculateInterestOnMaturity;
      currentState.issueDateInclusive = issueDateInclusive;
      currentState.maturityDateInclusive = maturityDateInclusive;
      currentState.frequencyOfInterestOption = frequencyOfInterestOption;
      currentState.nriEligibility = nriEligibility;
      currentState.lastIPDateOption = lastIPDateOption;
      currentState.lastIPDate = convertToDate(lastIpDate);
      if (nextIPDate) {
        currentState.nextIPDate = convertToDate(nextIPDate);
      }
      currentState.coupons = removeEmptyValuesCoupon(addedCoupons);
      currentState.callOptions =
        removeEmptyValueDateAndAmount(addedCallOptions);
      currentState.putOptions = removeEmptyValueDateAndAmount(addedPutOptions);
      currentState.maturityOptions =
        removeEmptyValueDateAndAmount(maturityOptions);
      currentState.ratingOrganizationMasters = removeEmptyValuesRatingOrgMaster(
        addedRatingOrgMasters
      );
      currentState.ratingOrganizationMasters = getRenamedProperties(
        currentState.ratingOrganizationMasters
      );
      currentState.retainCallDates = retainCallDates;
      currentState.retainMaturityDates = retainMaturityDates;
      currentState.issueDate = convertToDate(issueDate);
      if (
        !shallowEqual(
          debentureTrusteeDetails,
          debentureTrusteeDetailsInitialState
        )
      ) {
        currentState.debentureTrusteeDetails = debentureTrusteeDetails;
      }
      if (!shallowEqual(registrarDetails, registrarDetailsInitialState)) {
        currentState.registrarDetails = registrarDetails;
      }
      setDebentureTrusteeDetails(debentureTrusteeDetailsInitialState);
      if (currentState.remarks === "") {
        delete currentState.remarks;
      }
      currentState.issuerOfSecurity = currentState.issuerOfSecurity
        ? getFilteredIssuerMasterByIssuerName(currentState.issuerOfSecurity)
            .uuid
        : "";
      currentState.changedBy = JSON.parse(
        localStorage.getItem("loginUser")
      )?.name;
      setAddedRatingOrgMasters([
        {
          organizationName: "",
          rating: "",
        },
      ]);
      let date = new Date().toISOString();
      setAddedPutOptions([{ date: date, amount: "", uuid: "" }]);
      setAddedCallOptions([{ date: date, amount: "", uuid: "" }]);
      setAddedCoupons([{ date: date, rate: "", rate1: "" }]);
      setMaturityOptions([{ date: "", amount: "", uuid: "" }]);
      props.onSubmit(event, currentState);
    }
  };

  const renderIssueDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item justify="space-around" xs={12}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Issue Date *"
            format="dd/MM/yyyy"
            value={issueDate}
            onChange={handleIssueDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  };

  const renderFrequencyOfInterest = () => {
    let frequencyOfInterestOptions = [
      "Yearly",
      "Quarterly",
      "Monthly",
      "Half Yearly",
      "None",
    ];
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Frequency of Interest *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="frequencyOfInterest"
            value={currentState.frequencyOfInterest}
            onChange={(event) => handleChange(event)}
            label="Frequency Of Interest *"
          >
            {frequencyOfInterestOptions &&
              frequencyOfInterestOptions.length > 0 &&
              frequencyOfInterestOptions.map(
                (frequencyOfInterestOption, index) => {
                  return (
                    <MenuItem value={frequencyOfInterestOption} key={index}>
                      {frequencyOfInterestOption}
                    </MenuItem>
                  );
                }
              )}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderISIN = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="isin"
          required
          variant="outlined"
          fullWidth
          label="ISIN"
          defaultValue={currentState.isin}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderFifthRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderInterestDays()}
        </Grid>
        <Grid item xs={4}>
          {renderRecordDate()}
        </Grid>
        <Grid item xs={4}>
          {renderIpPaymentOptions()}
        </Grid>
        <Grid item xs={4}>
          {renderISIN()}
        </Grid>

        <Grid item xs={4}>
          {renderNRIEligibility()}
        </Grid>

        <Grid item xs={4}>
          {renderTaxFreeDropdown()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderRemarks = () => {
    return (
      <Grid item xs={3}>
        <TextField
          id="outlined-multiline-flexible"
          label="Remarks"
          multiline
          name="remarks"
          rowsMax={10}
          className={classes.remarks}
          value={currentState.remarks}
          onChange={(event) => handleChange(event)}
          variant="outlined"
        />
      </Grid>
    );
  };

  const renderStepUpStepDown = () => {
    return (
      <Grid item xs={3}>
        <TextField
          id="outlined-multiline-flexible"
          name="stepUpDown"
          fullWidth
          label="Step Up/Step Down"
          className={classes.textFieldClass}
          value={currentState.stepUpDown}
          onChange={(event) => handleChange(event)}
          variant="outlined"
        />
      </Grid>
    );
  };

  const renderIssueSizeInput = () => {
    return (
      <Grid item xs={3}>
        <TextField
          id="outlined-multiline-flexible"
          label="Issue Size"
          fullWidth
          name="issueSize"
          className={classes.textFieldClass}
          value={currentState.issueSize}
          onChange={(event) => handleChange(event)}
          variant="outlined"
        />
      </Grid>
    );
  };

  const renderCouponBasis = () => {
    return (
      <Grid item xs={3}>
        <TextField
          id="outlined-multiline-flexible"
          label="Coupon Basis"
          fullWidth
          name="couponBasis"
          className={classes.textFieldClass}
          value={currentState.couponBasis}
          onChange={(event) => handleChange(event)}
          variant="outlined"
        />
      </Grid>
    );
  };

  const addNewRatingOrgTable = (event, addedRatingOrganizationMapping) => {
    let ratingOrgMasters = addedRatingOrgMasters;
    ratingOrgMasters.unshift(addedRatingOrganizationMapping);
    setAddedRatingOrgMasters(ratingOrgMasters);
  };

  const renderAdditionalInformation = () => {
    let filteredSecurityType = securityTypes?.filter(
      (securityType) => securityType.uuid === currentState.securityType
    );
    if (filteredSecurityType && filteredSecurityType.length > 0) {
      if (
        filteredSecurityType[0]?.type === "State Development Loans" ||
        filteredSecurityType[0]?.type === "Government Securities"
      ) {
        return "";
      } else {
        return (
          <RatingOrganizationMasterTable
            headCells={ratingOrganizationTableHeaders}
            ratingOrganizationMappings={ratingOrganizationMappings}
            records={addedRatingOrgMasters}
            onAddNewRow={addNewRatingOrgTable}
            organizationNames={organizationNames}
          />
        );
      }
    } else {
      return (
        <RatingOrganizationMasterTable
          headCells={ratingOrganizationTableHeaders}
          ratingOrganizationMappings={ratingOrganizationMappings}
          records={addedRatingOrgMasters}
          onAddNewRow={addNewRatingOrgTable}
          organizationNames={organizationNames}
        />
      );
    }
  };

  const renderSeventhRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          {renderCalculateInterestOnHolidays()}
        </Grid>
        <Grid item xs={3}>
          {renderCalculateInterestOnMaturity()}
        </Grid>

        <Grid item xs={3}>
          {renderIssueDateInclusive()}
        </Grid>

        <Grid item xs={3}>
          {renderMaturityDateInclusive()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderOptionalInformation = () => {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          {renderCouponBasis()}
        </Grid>
        <Grid item xs={3}>
          {renderStepUpStepDown()}
        </Grid>

        <Grid item xs={3}>
          {renderTaxCategoryDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderSectorDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderTypeOfGuaranteeDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderGuaranteeDetails()}
        </Grid>

        <Grid item xs={3}>
          {renderBankTierDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderSecuredOrUnSecuredDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderIssueSizeInput()}
        </Grid>

        <Grid item xs={3}>
          {renderModeOfIssueDropdown()}
        </Grid>

        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Next IP Date *"
                format="dd/MM/yyyy"
                value={nextIPDate}
                onChange={handleNextIPDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </React.Fragment>
    );
  };

  const renderSixthRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          {renderCouponOnDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderExchangeDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderActiveOnDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderRemarks()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderFourthRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderIssueDate()}
        </Grid>
        <Grid item xs={4}>
          {renderFrequencyOfInterestOptions()}
        </Grid>
        <Grid item xs={4}>
          {renderlastIPDateOptions()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderThirdRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderNameOfSecurity()}
        </Grid>
        <Grid item xs={4}>
          {renderFaceValueAndOptions()}
        </Grid>
        <Grid item xs={4}>
          {renderPricePerBondOptions()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderSecondRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderSecurityCode()}
        </Grid>
        <Grid item xs={4}>
          {renderIssuerOfSecurity()}
        </Grid>
        <Grid item xs={4}>
          {renderReadOnlyIssuerType()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderFirstRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderNameOfInstrumentDropdown()}
        </Grid>
        <Grid item xs={4}>
          {renderSecurityTypeDropdown()}
        </Grid>
        <Grid item xs={4}>
          {renderSecurityTypeCodeInput()}
        </Grid>
      </React.Fragment>
    );
  };

  const addNewCoupon = (event, addedCoupon) => {
    let coupons = addedCoupons;
    coupons.unshift(addedCoupon);
    setAddedCoupons(coupons);
  };

  const addNewCallOption = (event, addedCallOption) => {
    let callOptions = addedCallOptions;
    callOptions.unshift(addedCallOption);
    setAddedCallOptions(callOptions);
  };

  const handleOnDeletePutOption = (event, toBeDeleted) => {
    event.preventDefault();
    let [head, ...tail] = addedPutOptions;
    setAddedPutOptions(tail);
  };

  const addNewPutOption = (event, addedPutOption) => {
    let putOptions = addedPutOptions;
    putOptions.unshift(addedPutOption);
    setAddedPutOptions(putOptions);
  };

  const addNewMaturityOption = (event, addedMaturityOption) => {
    let maturities = maturityOptions;
    maturities.unshift(addedMaturityOption);
    setMaturityOptions(maturities);
  };

  const renderCoupon = () => {
    return (
      <DataTable
        headCells={couponHeaders}
        records={addedCoupons}
        OnAddNewRow={addNewCoupon}
        removeUnsavedCoupon={removeUnsavedCoupon}
      />
    );
  };

  const removeRowFromCallOptions = (event, selectedCallOption) => {
    let filteredCallOptions = addedCallOptions.filter(
      (callOption) => callOption.date !== selectedCallOption.date
    );
    setAddedCallOptions(filteredCallOptions);
  };

  const renderCallOption = () => {
    return (
      <CallOptionDataTable
        headCells={callOptionHeaders}
        records={addedCallOptions}
        onAddNewRow={addNewCallOption}
        onDeleteOption={removeRowFromCallOptions}
        onToggleSwitch={(event) => setRetainCallDates(!retainCallDates)}
        retainCallDates={retainCallDates}
      />
    );
  };

  const removeUnsavedCoupon = (event, toBeDeleted) => {
    event.preventDefault();
    let filteredCoupons = addedCoupons.filter(
      (coupon) => coupon.date !== toBeDeleted.date
    );
    setAddedCoupons(filteredCoupons);
  };

  const handleUnsavedPutOptionDelete = (event, toBeDeleted) => {
    event.preventDefault();
    let filteredPutOptions = addedPutOptions.filter(
      (putOption) => putOption.date !== toBeDeleted.date
    );
    setAddedPutOptions(filteredPutOptions);
  };

  const renderPutOption = () => {
    return (
      <PutOptionDataTable
        headCells={callOptionHeaders}
        records={addedPutOptions}
        onAddNewRow={addNewPutOption}
        onDeleteClick={handleOnDeletePutOption}
        removeUnsavedPutOption={handleUnsavedPutOptionDelete}
      />
    );
  };

  const handleUnsavedMaturityDelete = (event, toBeDeleted) => {
    event.preventDefault();
    let filteredMaturities = maturityOptions.filter(
      (maturityOption) => maturityOption.date !== toBeDeleted.date
    );
    setMaturityOptions(filteredMaturities);
  };

  const renderMaturityOption = () => {
    return (
      <MaturityOptionDataTable
        headCells={callOptionHeaders}
        records={maturityOptions}
        removeUnsavedMaturity={handleUnsavedMaturityDelete}
        onAddNewRow={addNewMaturityOption}
        onToggleSwitch={(event) => setRetainMaturityDates(!retainMaturityDates)}
        retainMaturityDates={retainMaturityDates}
      />
    );
  };

  const handleCloseDebentureDetailsDialog = (event) => {
    event.preventDefault();
    setShowDebentureDetailsDialog(false);
  };

  const handleDebentureDetailsSubmit = (event) => {
    event.preventDefault();
    setShowDebentureDetailsDialog(false);
  };

  const handleDebentureDetailsChange = (event) => {
    event.persist();
    setDebentureTrusteeDetails((previousValues) => {
      if (event.target.name === "file") {
        return {
          ...previousValues,
          file: event.target.files[0],
        };
      } else {
        return {
          ...previousValues,
          [event.target.name]: event.target.value,
        };
      }
    });
  };

  const renderDebentureDetailsDialog = () => {
    return (
      <DebentureDetailsDialog
        show={showDebentureDetailsDialog}
        onCloseDialog={handleCloseDebentureDetailsDialog}
        onSubmit={handleDebentureDetailsSubmit}
        onChange={handleDebentureDetailsChange}
        currentState={debentureTrusteeDetails}
      />
    );
  };

  const handleCloseRegistrarDetailsDialog = (event) => {
    event.preventDefault();
    setShowRegistrarDetailsDialog(false);
  };

  const handleRegistrarDetailsSubmit = (event) => {
    event.preventDefault();
    setShowRegistrarDetailsDialog(false);
  };

  const handleRegistrarDetailsChange = (event) => {
    event.persist();
    setRegistrarDetails((previousValues) => {
      if (event.target.name === "file") {
        return {
          ...previousValues,
          file: event.target.files[0],
        };
      } else {
        return {
          ...previousValues,
          [event.target.name]: event.target.value,
        };
      }
    });
  };

  const renderRegistrarDetailsDialog = () => {
    return (
      <RegistrarDetailsDialog
        show={showRegistrarDetailsDialog}
        onCloseDialog={handleCloseRegistrarDetailsDialog}
        onSubmit={handleRegistrarDetailsSubmit}
        onChange={handleRegistrarDetailsChange}
        currentState={registrarDetails}
      />
    );
  };

  const renderAdditionalOptions = () => {
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                {renderCoupon()}
              </Grid>
              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                {renderCallOption()}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                {renderPutOption()}
              </Grid>

              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                {currentState.nameOfInstrument !== "Perpetual" &&
                  renderMaturityOption()}
              </Grid>

              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                <Button
                  className="btnClass"
                  variant="outlined"
                  onClick={(event) => handleAddDebentureTrusteeDetails(event)}
                  color="primary"
                >
                  Add Debenture Trustee Details
                </Button>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                <Button
                  className="btnClass"
                  variant="outlined"
                  onClick={(event) => handleAddRegistrarDetails(event)}
                  color="primary"
                >
                  Add Registrar Details
                </Button>
              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  marginBottom: "2.5rem",
                  background: "#7659EE1A",
                  borderRadius: 12,
                  padding: 10,
                }}
                justifyContent="center"
              >
                <Grid item xs={12} sm={"auto"}>
                  <label>Select Debenture</label>
                  <Avatar
                    style={{ height: "100px", width: "100px" }}
                    variant="circle"
                    src={debentureLogo}
                  />
                  <input
                    type="file"
                    name="file"
                    onChange={(event) =>
                      setDebentureLogo(event.target.files[0])
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={"auto"}>
                  <label>Select Registrar</label>
                  <Avatar
                    style={{ height: "100px", width: "100px" }}
                    variant="circle"
                    src={registrarLogo}
                  />
                  <input
                    type="file"
                    name="file"
                    onChange={(event) =>
                      setRegistrarLogo(event.target.files[0])
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  };

  const handleAddRegistrarDetails = (event) => {
    event.preventDefault();
    setShowRegistrarDetailsDialog(true);
  };

  const handleAddDebentureTrusteeDetails = (event) => {
    event.preventDefault();
    setShowDebentureDetailsDialog(true);
  };

  const renderDialogBody = () => {
    return (
      <div className="mainFormClass">
        <Grid container spacing={5}>
          <Grid container item xs={12} spacing={3}>
            {renderFirstRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderSecondRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderThirdRowOfInputs()}
          </Grid>
          <Grid container item xs={12} spacing={3}>
            {renderFourthRowOfInputs()}
          </Grid>
          <Grid container item xs={12} spacing={3}>
            {renderFifthRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderSixthRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderOptionalInformation()}
          </Grid>
        </Grid>
        <br />
        <br />
        <Divider />
        <div style={{ fontSize: "30px", float: "left" }}>Interest Details</div>
        <br />

        <div className={classes.root}>
          <Grid container spacing={5}>
            <Grid container item xs={12} spacing={3}>
              {renderSeventhRowOfInputs()}
            </Grid>
          </Grid>
        </div>

        <br />
        <br />
        <Divider />
        <div style={{ fontSize: "30px", float: "left" }}>
          Additional Information
        </div>
        <br />
        <div className={classes.root}>
          <Grid container spacing={5}>
            <Grid container item xs={12} spacing={3}>
              {renderAdditionalInformation()}
            </Grid>
          </Grid>
        </div>

        <br />
        <br />
        <Divider />
        {renderAdditionalOptions()}

        {renderDebentureDetailsDialog()}
        {renderRegistrarDetailsDialog()}
      </div>
    );
  };

  const closeDialog = (event) => {
    event.preventDefault();
    setCurrentState(initialState);
    props.onCloseDialog(event);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.show}
        onClose={(event) => closeDialog(event)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(event) => closeDialog(event)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Close
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSecuritySubmit}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        {renderDialogBody()}
      </Dialog>
    </div>
  );
};

export default AddSecurityDialog;
